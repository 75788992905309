import { AxiosInstance } from "axios";
import i18n from "../../../client/src/i18n/i18n";
import { NotificationType } from "../notification/notification.types";
import { generateNotification } from "../notification/notification.util";
import { CustomerArticle } from "./CustomerArticle.types";

/**
 * API method to get a customerArticle by id
 * @param axios instance of axios
 * @param id of the customerArticle to load
 * @returns loaded customerArticle
 */
export const getCustomerArticleById = async (
  axios: AxiosInstance,
  id: string
): Promise<CustomerArticle | undefined> => {
  return axios
    .get("/customer/article", { params: { id } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading customerArticle", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getCustomerArticleById"),
      });
      return undefined;
    });
};

/**
 * Api method to load all customerArticles
 * @param axios instance of axios
 * @returns Array of all customerArticles
 */
export const getAllCustomerArticles = async (
  axios: AxiosInstance
): Promise<CustomerArticle[]> => {
  return axios
    .get("/customer/article/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all customerArticles", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllCustomerArticles"),
      });
      return [];
    });
};

/**
 * API method to create a new CustomerArticle
 * @param axios instance of axios
 * @param customerArticle customerArticle to create
 * @returns created CustomerArticle
 */
export const createNewCustomerArticle = async (
  axios: AxiosInstance,
  customerArticle: CustomerArticle
): Promise<CustomerArticle> => {
  return axios
    .post("/customer/article/", customerArticle)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating CustomerArticle", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewCustomerArticle"),
      });
      return undefined;
    });
};

/**
 * API method to update a customerArticle
 * @param axios instance of axios
 * @param customerArticle updated CustomerArticle object
 * @returns updated customerArticle
 */
export const updateCustomerArticle = async (
  axios: AxiosInstance,
  customerArticle: CustomerArticle
): Promise<CustomerArticle | undefined> => {
  return axios
    .post("/customer/article/update", customerArticle)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateCustomerArticle"),
      });
      console.error("Error while updating customerArticle", exc);
      return undefined;
    });
};

/**
 * API Method to get all customerArticles for a specific customer
 * @param axios instance of axios
 * @param customerId id of the customer to get the articles for
 * @returns Promise containing an array of customerArticles
 */
export const getCustomerArticlesForCustomer = async (
  axios: AxiosInstance,
  customerId: string
): Promise<CustomerArticle[]> => {
  return axios
    .get("/customer/article/customer", { params: { customerId } })
    .then((res) => res.data)
    .catch((exc) => {
      console.log("Error while loading customerArticles", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t(
          "general.notification.error.getCustomerArticleForCustomer"
        ),
      });
      return [];
    });
};
