import { Box, Input, TextArea } from "@sam/components";
import { useTranslation } from "react-i18next";
import { DunningLevel, DunningMessage } from "shared";
import { Language } from "shared/src/Language/Language.types";

interface DunningMessageBoxProps {
  language: Language;
  dunningLevel: DunningLevel;
  updateField: (
    language: Language,
    updateFields: Partial<DunningMessage>
  ) => void;
}
export const DunningMessageEditBox: React.FC<DunningMessageBoxProps> = ({
  language,
  updateField,
  dunningLevel,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      title={`${t("pages.dunning.edit.messageBoxes.boxTitle")} (${t(
        `general.languages.${language}`
      )})`}
    >
      <Input
        label={t("pages.dunning.edit.messageBoxes.mailTitle")}
        type="text"
        onChange={(mailTitle) => updateField(language, { mailTitle })}
        value={dunningLevel.dunningMessages.get(language)?.mailTitle}
      />
      <TextArea
        label={t("pages.dunning.edit.messageBoxes.mailContent")}
        resizable
        onChange={(mailContent) => updateField(language, { mailContent })}
        value={dunningLevel.dunningMessages.get(language)?.mailContent || ""}
      />
      <TextArea
        label={t("pages.dunning.edit.messageBoxes.reportTitle")}
        resizable
        onChange={(reportTitle) => updateField(language, { reportTitle })}
        value={dunningLevel.dunningMessages.get(language)?.reportTitle || ""}
      />
      <TextArea
        label={t("pages.dunning.edit.messageBoxes.reportContent")}
        resizable
        onChange={(reportContent) => updateField(language, { reportContent })}
        value={dunningLevel.dunningMessages.get(language)?.reportContent || ""}
      />
      <TextArea
        label={t("pages.dunning.edit.messageBoxes.reportFooter")}
        resizable
        onChange={(reportFooter) => updateField(language, { reportFooter })}
        value={dunningLevel.dunningMessages.get(language)?.reportFooter || ""}
      />
    </Box>
  );
};
