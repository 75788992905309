import { TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import { Branche } from "shared";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";

/**
 * Util method to convert branches into TableRows
 * @param branches to convert
 * @returns array of tableRows
 */
export const convertBranchesIntoTableEntries = (
  branches: Branche[],
  navigate: NavigateFunction,
  toggleDelete: (brancheId: Branche) => void
): TableRow[] => {
  return branches.map((branche) => ({
    id: branche.id,
    onClick: () => navigate("/branche/edit", { state: { branche } }),
    content: [
      branche.name,
      <div>
        <EditIcon
          width={30}
          onClick={(evt) => {
            evt.stopPropagation();
            navigate("/branche/edit", { state: { branche } });
          }}
        />
        <DeleteIcon
          width={30}
          onClick={(evt) => {
            evt.stopPropagation();
            toggleDelete(branche);
          }}
        />
      </div>,
    ],
  }));
};

/**
 * Util method to create an empty branche
 * @param override partial branche to adjust created object
 * @returns generated branche
 */
export const generateEmptyBranche = (override?: Partial<Branche>): Branche => ({
  id: undefined!,
  name: "",
  ...override,
});
