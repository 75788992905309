import { AxiosInstance } from "axios";
import { CustomerFeedbackEntry } from "./CustomerFeedbackEntry.types";
import { generateNotification, mapReplacer } from "..";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";

/**
 * API Method to get all customerFeedbackEntries
 * @param axios instance of axios
 * @returns  array of all found entries
 */
export const getAllCustomerFeedbackEntries = async (
  axios: AxiosInstance
): Promise<CustomerFeedbackEntry[]> => {
  return axios
    .get("/customer/feedback/all")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllCustomerFeedback"),
      });
      console.error("Error while loading customerFeedback", exc);
      return [];
    });
};

/**
 * API Method to create a new custoemrFeedbackEntry
 * @param axios instance of axios
 * @param entry  to persist on the database
 * @returns  created object or undefined on error
 */
export const createNewCustomerFeedbackEntry = async (
  axios: AxiosInstance,
  entry: CustomerFeedbackEntry
): Promise<CustomerFeedbackEntry | undefined> => {
  return axios
    .post("/customer/feedback/", JSON.stringify(entry, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewCustomerFeedback"),
      });
      console.error("Error while saving customerFeedbackEntry", exc);
      return undefined;
    });
};

/**
 * API Method to update a customerFeedbackEntry
 * @param axios instance of axios
 * @param entry updated entry to save
 * @returns updated object or undefined on error
 */
export const updateCustomerFeedbackEntry = async (
  axios: AxiosInstance,
  entry: CustomerFeedbackEntry
): Promise<CustomerFeedbackEntry | undefined> => {
  return axios
    .post("/customer/feedback/update", JSON.stringify(entry, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateCustomerFeedback"),
      });
      console.error("Error while updating entry", exc);
      return undefined;
    });
};
