import { AxiosInstance } from "axios";
import { WorkQualification } from "./workQualification.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import { mapReplacer } from "..";

/**
 * API method to get all workQualifications
 * @param axios instance of axios
 * @returns  array of all found workQaulifications
 */
export const getAllWorkQualifications = async (
  axios: AxiosInstance
): Promise<WorkQualification[]> => {
  return axios
    .get("/qualification/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading qualifications", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllWorkQualifications"),
      });
      return [];
    });
};

/**
 * API method to create a new workQualification
 * @param axios instance of axios
 * @param qualification qualification to save
 * @returns  created qualification or undefined on error
 */
export const createNewWorkQualification = async (
  axios: AxiosInstance,
  qualification: WorkQualification
): Promise<WorkQualification | undefined> => {
  return axios
    .post("/qualification/", JSON.stringify(qualification, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating qualification", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewQualification"),
      });
    });
};

/**
 * API method to update a workqualification
 * @param axios instance of axios
 * @param qualification updated qualification
 * @returns  updated qualification or undefined
 */
export const updateWorkQualification = async (
  axios: AxiosInstance,
  qualification: WorkQualification
): Promise<WorkQualification | undefined> => {
  return axios
    .post("/qualification/update", JSON.stringify(qualification, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while updating qualification", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateQualification"),
      });
    });
};
