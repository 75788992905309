import { TextAreaProps } from "./TextArea.types";
import "./TextArea.scss";
import { useEffect, useState } from "react";
export const TextArea: React.FC<TextAreaProps> = ({
  label,
  value,
  onChange,
  disabled = false,
  resizable = false,
  required
}) => {
  const [localValue, setLocalValue] = useState<string | undefined>(value);

  // set the initial value 
  useEffect(() => {
    if(value !== localValue) setLocalValue(value); 
  }, [localValue, value])

  return (
    <div className="textarea-wrapper">
      {label && <p className="textarea-wrapper__label">{label}</p>}
      <textarea
        className="textarea-wrapper__textarea"
        style={{ resize: resizable ? undefined : "none" }}
        onChange={(evt) => {
          onChange(evt.target.value);
          setLocalValue(evt.target.value);
        }}
        value={localValue}
        required={required}
        disabled={disabled}
      />
    </div>
  );
};
