import { AxiosInstance } from "axios";
import i18n from "../../../client/src/i18n/i18n";
import { NotificationType } from "../notification/notification.types";
import { generateNotification } from "../notification/notification.util";
import { Project } from "../project/Project.types";
import {
  Invoice,
  InvoicePayment,
  InvoiceState,
  PaymentCondition,
} from "./Invoice.types";
import dayjs from "dayjs";

/**
 * API Method to load all paymentConditions
 * @param axios instance of axios
 * @returns Array with all found paymentConditions
 */
export const getAllPaymentConditions = async (
  axios: AxiosInstance
): Promise<PaymentCondition[]> => {
  return axios
    .get("/payment/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading paymentConditions", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadAllPaymentConditions"),
      });
      return [];
    });
};

/**
 * API Method to update paymentConditions
 * @param axios instance of Axios
 * @param condition updatet conditions to save
 * @returns saved condition or undeifned on error
 */
export const updatePaymentConditions = async (
  axios: AxiosInstance,
  condition: PaymentCondition
): Promise<PaymentCondition | undefined> => {
  return axios
    .post("/payment/update", condition)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updatePaymentCondition"),
      });
      console.error("Error while updating paymentCondition", exc);
    });
};

/**
 * API method to create a new paymentCondition
 * @param axios  instance of axios
 * @param condition to create
 * @returns  saved condition or undefined on error
 */
export const createNewPaymentCondition = async (
  axios: AxiosInstance,
  condition: PaymentCondition
): Promise<PaymentCondition | undefined> => {
  return axios
    .post("/payment/", condition)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createPaymentCondition"),
      });
      console.error("Error while creating new paymentCondition", exc);
    });
};

/**
 * API Method to deactivate a paymentCondition
 * @param axios  instance of axios
 * @param id  of the condition to deactivate
 * @returns Promise with deactivated condition or undefined on error
 */
export const disablePaymentCondition = async (
  axios: AxiosInstance,
  id: string
): Promise<PaymentCondition | undefined> => {
  return axios
    .post("/payment/deactivate", id, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while deactivating paymentCondition", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.disablePaymentCondition"),
      });
    });
};

/**
 * API Method to get all invoices for given states
 * @param axios instance of axios
 * @param states list of states to load the invoices for
 * @returns List of all found invoices
 */
export const getAllInvoicesByStates = async (
  axios: AxiosInstance,
  states: InvoiceState[]
): Promise<Invoice[]> => {
  return axios
    .get("/invoice/all/state", { params: { states: states.toString() } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading paymentConditions", exc);
      return [];
    });
};

/**
 * API method to create a new invoice
 * @param axios insance of axios
 * @param invoice to create
 * @returns created Invoice or undefined on error
 */
export const createNewInvoice = async (
  axios: AxiosInstance,
  invoice: Invoice
): Promise<Invoice | undefined> => {
  return axios
    .post("/invoice", invoice)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while saving invoice", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewInvoice"),
      });
      return undefined;
    });
};

/**
 * API Method to update an invoice
 * @param axios instance of axios
 * @param invoice updated invoice
 * @returns Updated invoice or undefined on error
 */
export const updateInvoice = async (
  axios: AxiosInstance,
  invoice: Invoice
): Promise<Invoice | undefined> => {
  return axios
    .post("/invoice/update", invoice)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while updating invoice", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateInvoice"),
      });
      return undefined;
    });
};

/**
 * API Method to get all Projects with unbilled protocols
 * @param axios instance of axios
 * @param endDate  end of the range to search (included)
 * @returns Array of found projects
 */
export const getAllProjectsToCreateInvoice = async (
  axios: AxiosInstance,
  endDate: Date
): Promise<Project[]> => {
  return axios
    .get("/project/invoice", {
      params: { endDate: dayjs(endDate).format("YYYY-MM-DD") },
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading projects to create invoce", exc);
      return [];
    });
};

/**
 * API Method to create multiple invoices
 * @param axios instance of axios
 * @param config configuration of the invoices to create with a list of projects and an endDate
 * @returns Blob containing a zip file with  the created invoices
 */
export const createMultipleInvoicesForProjects = async (
  axios: AxiosInstance,
  config: { projectIds: string[]; endDate: Date; userId: string }
): Promise<Blob | undefined> => {
  return axios
    .post("/invoice/create", config, { responseType: "blob" })
    .then((res) => new Blob([res.data], { type: "application/zip" }))
    .catch((exc) => {
      console.error("Error while creating invoices", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createMultipleInvoices"),
      });
      return undefined;
    });
};

/**
 * API Method to create and get an invoice document
 * @param axios instance of axios
 * @param invoiceId id of the invocie to create the document
 * @returns Blob with the created document
 */
export const getInvoicePDFDocument = async (
  axios: AxiosInstance,
  invoiceId: string,
  userId: string
): Promise<Blob | undefined> => {
  return axios
    .get("/export/pdf/invoice", {
      params: { invoiceId, userId },
      responseType: "blob",
    })
    .then((res) => new Blob([res.data], { type: "application/pdf" }))
    .catch((exc) => {
      console.error("Error while creating pdf document", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createInvoiceDocument"),
      });
      return undefined;
    });
};

/**
 * API Method to create a new InoicePayment on the database
 * @param axios instance of axios
 * @param payment to save onto the database
 * @returns Promise with created object or undefined on error
 */
export const createNewInvoicePayment = async (
  axios: AxiosInstance,
  payment: InvoicePayment
): Promise<InvoicePayment | undefined> => {
  return axios
    .post("invoice/payment/", payment)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while saving the payment", exc);
      return undefined;
    });
};

/**
 * API Method to load a timetable document for a given invoice
 * @param axios instance of axios
 * @param invoiceId if of the invoice to load
 * @returns Promise containing Blob of the document or undefined on error
 */
export const getTimeTablesForInvoice = async (
  axios: AxiosInstance,
  invoiceId: string
): Promise<Blob | undefined> => {
  return axios
    .get("/export/pdf/protocol/times", {
      params: { invoiceId },
      responseType: "blob",
    })
    .then((res) => new Blob([res.data], { type: "application/pdf" }))
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createTimeSheet"),
      });
      console.error("Error while loading timesheet", exc);
      return undefined;
    });
};

/**
 * API Method to load a timetable document for a given invoice
 * @param axios instance of axios
 * @param invoiceId if of the invoice to load
 * @returns Promise containing Blob of the document or undefined on error
 */
export const getErrorPatternTablesForInvoice = async (
  axios: AxiosInstance,
  invoiceId: string
): Promise<Blob | undefined> => {
  return axios
    .get("/export/pdf/protocol/pieces", {
      params: { invoiceId },
      responseType: "blob",
    })
    .then((res) => new Blob([res.data], { type: "application/pdf" }))
    .catch((exc) => {
      console.error("Error while loading document", exc);
      return undefined;
    });
};
