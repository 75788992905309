import { AxiosInstance } from "axios";
import {
  InvoiceMailConfiguration,
  MailConfiguration,
  MailTemplate,
  MailType,
  OrderConfirmationMailConfiguration,
} from "./Mail.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import { Invoice, mapReplacer } from "..";

/**
 * API Method to send a testMail with a new smtpConfiguration
 * @param axios instance of axios
 * @param config containing the sender configuration and the receiver
 * @returns boolean if the test was succesfull
 */
export const sendTestMail = async (
  axios: AxiosInstance,
  config: MailConfiguration
): Promise<boolean> => {
  return axios
    .post("/mail/test", config)
    .then((res) => {
      res.status === 200 &&
        generateNotification({
          type: NotificationType.SUCCESS,
          value: i18n.t("general.notification.success.testMail"),
        });
      return res.status === 200;
    })
    .catch((exc) => {
      console.error("Error while sending testmail", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.testMail"),
      });
      return false;
    });
};

/**
 * API method to send an orderConfirmation
 * @param axios instance of axios
 * @param config neccessary mailConfig
 * @returns
 */
export const sendOrderConfirmation = async (
  axios: AxiosInstance,
  config: OrderConfirmationMailConfiguration
): Promise<boolean> => {
  return axios
    .post("/mail/confirmation", config)
    .then((res) => {
      res.status === 200 &&
        generateNotification({
          type: NotificationType.SUCCESS,
          value: i18n.t("general.notification.success.orderConfirmation"),
        });
      return res.status === 200;
    })
    .catch((exc) => {
      console.error("Error while sending orderConfirmation", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.orderConfirmation"),
      });
      return false;
    });
};

/**
 * API Method to send a message per Mail andset the invoiceState
 * @param axios  instance of Axios
 * @param config InvoiceMailConfiguration containing all neccessary information
 * @returns
 */
export const sendInvoiceMail = async (
  axios: AxiosInstance,
  config: InvoiceMailConfiguration
): Promise<Invoice> => {
  return axios
    .post("/mail/invoice", config)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.sendInvoiceMail"),
      });
      console.error("Error while sending invoice", exc);
      return undefined;
    });
};

/**
 * API method to get a mailTemplate by type
 * @param axios instance of axios
 * @param type MailType to load
 * @returns  found or created mailTemplate or undefined on error
 */
export const getMailTemplateForMailType = async (
  axios: AxiosInstance,
  type: MailType
): Promise<MailTemplate | undefined> => {
  return axios
    .get("/mail/template/type", { params: { type } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getMailTemplateForMailType"),
      });
      console.error("Error while loading mailTemplate", exc);
      return undefined;
    });
};

/**
 * API method to update a MailTemplate
 * @param axios instance of axios
 * @param template to update
 * @returns updated mailTemplate or undefined on error
 */
export const updateMailTemplate = async (
  axios: AxiosInstance,
  template: MailTemplate
): Promise<MailTemplate | undefined> => {
  return axios
    .post("/mail/template/update", JSON.stringify(template, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateMailTemplate"),
      });
      console.error("Error while updating mailTemplate", exc);
      return undefined;
    });
};

/**
 * API method to create a new mailTemplate
 * @param axios  instance of axios
 * @param template  to create
 * @returns  Created template or undefined on error
 */
export const createNewMailTemplate = async (
  axios: AxiosInstance,
  template: MailTemplate
): Promise<MailTemplate | undefined> => {
  return axios
    .post("/mail/template/", JSON.stringify(template, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewMailTemplate"),
      });
      console.error("Error while updating mailTemplate", exc);
      return undefined;
    });
};
