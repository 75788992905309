import { Box, Table, TopBar } from "@sam/components";
import { TableHeader, TableRow } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Right, useData } from "shared";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { convertBikesIntoTableEntries } from "../../utils/bike/Bike.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

const BikeOverview: React.FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<string>("");
  const { data: bikes } = useData("BIKES_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Holds tableRows for bikes
   */
  const rows: TableRow[] = useMemo(
    () => convertBikesIntoTableEntries(user.right, bikes, navigate),
    [bikes, navigate, user.right]
  );

  return (
    <>
      <TopBar
        title={t("pages.bikes.overview.headline")}
        onSearch={setFilterValue}
      >
        {isUserAllowedToDo(user.right, Right.BIKE_CREATE) && (
          <AddIcon width={20} onClick={() => navigate("/bikes/create")} />
        )}
      </TopBar>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.bikes.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};

export default BikeOverview;
