import { Absence } from "shared";

/**
 * Helper method to create an empty Absence
 * @param userIdCreate User that created the absence
 * @param absenceUser user that is absent
 * @returns empty Absence object
 */
export const generateEmptyAbsence = (override?: Partial<Absence>): Absence => ({
  createDate: new Date(),
  createdBy: "",
  endDate: new Date(),
  id: undefined!,
  lastUpdate: new Date(),
  startDate: new Date(),
  updatedBy: "",
  userId: "",
  reason: undefined!,
  ...override,
});
