import { TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import { Gender, Office, Pdl, PdlUser, Right, UserRight } from "shared";
import {
  generateEmptyContactInformation,
  isUserAllowedToDo,
} from "../user/User.utils";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";

/**
 * Helper to transform the given pdls into table row entries to display
 *
 * @returns List of table rows to display
 */
export const convertPdlIntoTableEntries = (
  userRights: UserRight,
  pdls: Pdl[],
  navigate: NavigateFunction
): TableRow[] => {
  return pdls
    .sort((pdlA, pdlB) => pdlA.name.localeCompare(pdlB.name))
    .map((pdl) => ({
      id: pdl.id!,
      onClick: () => navigate(`edit`, { state: { pdl } }),
      content: [
        pdl.name,
        pdl.company || "-",
        <div className="table-actions">
          {isUserAllowedToDo(userRights, Right.PDL_EDIT) && (
            <EditIcon
              height={40}
              width={40}
              onClick={(evt) => {
                evt.stopPropagation();
                navigate(`edit`, { state: { pdl } });
              }}
            />
          )}
        </div>,
      ],
    }));
};

/**
 * Helper to generate an empty PDL instance
 *
 * @param override Optional parameter to override
 * @returns A new PDL instance
 */
export const generateEmptyPdl = (override?: Partial<Pdl>): Pdl => ({
  id: undefined!,
  createDate: undefined!,
  createdBy: undefined!,
  contact: generateEmptyContactInformation(),
  name: "",
  numberRangeNumber: 0,
  vatId: "",
  ...override,
});

/**
 * Helper to generate an empty PDL user instance
 *
 * @param override Optional parameter to override
 * @returns A new PDL user instance
 */
export const generateEmptyPdlUser = (override?: Partial<PdlUser>): PdlUser => ({
  id: undefined!,
  createDate: undefined!,
  createdBy: undefined!,
  contact: generateEmptyContactInformation(),
  firstName: "",
  lastName: "",
  gender: Gender.MALE,
  title: "",
  pdlContact: false,
  pdlId: "",
  ...override,
});

/**
 * Helper method to convert pdlUser into TableRows
 * @param pdlUser array of customerUsers
 * @param navigate NavigateFunction used at the icons to navigate to edit
 * @returns array of tableRows
 */
export const convertPdlUserIntoTableEntries = (
  pdlUser: PdlUser[],
  navigate: NavigateFunction,
  allOffices: Office[],
  isContact: boolean
): TableRow[] => {
  return pdlUser
    .filter((user) => user.pdlContact === isContact)
    .sort((userA, userB) => userA.lastName.localeCompare(userB.lastName))
    .map((user) => ({
      id: user.id,
      content: [
        `${user.lastName}, ${user.firstName}`,
        allOffices.find((office) => user.mainOffice === office.id)?.name || "-",
        <EditIcon
          width={30}
          onClick={(evt) => {
            evt.stopPropagation();
            navigate("/pdl/user/edit", {
              state: { user },
            });
          }}
        />,
      ],
    }));
};
