/**
 * Interface for UserRight which combines the role and the custom rights of the user
 */
export interface UserRight {
  role: UserRole;
  customRights: Map<Right, boolean>;
}

/**
 * Interface for different userRoles and their default rights
 */
export interface UserRole {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdate: Date;
  name: string;
  rights: Right[];
  disabled: boolean;
}

/**
 * Enum of single rights that are used to validate, if a user is allowed to do a action
 */
export enum Right {
  TIME_EDIT = "TIME_EDIT",
  TIME_EXPORT = "TIME_EXPORT",
  TIME_CREATE = "TIME_CREATE",
  USER_CREATE = "USER_CREATE",
  USER_EDIT = "USER_EDIT",
  USER_DELETE = "USER_DELETE",
  USER_VIEW_ALL = "USER_VIEW_ALL",
  NONE = "NONE",
  ABSENCE_CREATE = "ABSENCE_CREATE",
  ALL = "ALL",
  ABSENCE_REASON_CREATE = "ABSENCE_REASON_CREATE",
  ABSENCE_REASON_EDIT = "ABSENCE_REASON_EDIT",
  ABSENCE_REASON_OVERVIEW = "ABSENCE_REASON_OVERVIEW",
  HOLIDAY_CREATE = "HOLIDAY_CREATE",
  HOLIDAY_EDIT = "HOLIDAY_EDIT",
  HOLIDAY_OVERVIEW = "HOLIDAY_OVERVIEW",
  HOLIDAY_READ = "HOLIDAY_READ",
  OFFICE_CREATE = "OFFICE_CREATE",
  OFFICE_OVERVIEW = "OFFICE_OVERVIEW",
  OFFICE_READ = "OFFICE_READ",
  OFFICE_DELETE = "OFFICE_DELETE",
  OFFICIAL_HOLIDAY_CREATE = "OFFICIAL_HOLIDAY_CREATE",
  OFFICIAL_HOLIDAY_OVERVIEW = "OFFICIAL_HOLIDAY_OVERVIEW",
  USERROLE_OVERVIEW = "USERROLE_OVERVIEW",
  USERROLE_CREATE = "USERROLE_CREATE",
  USERROLE_EDIT = "USERROLE_EDIT",
  CAR_EDIT = "CAR_EDIT",
  CAR_CREATE = "CAR_CREATE",
  CAR_OVERVIEW = "CAR_OVERVIEW",
  BIKE_OVERVIEW = "BIKE_OVERVIEW",
  BIKE_CREATE = "BIKE_CREATE",
  BIKE_EDIT = "BIKE_EDIT",
  SUMMARY = "SUMMARY",
  CUSTOMER_OVERVIEW = "CUSTOMER_OVERVIEW",
  CUSTOMER_CREATE = "CUSTOMER_CREATE",
  OFFER_OVERVIEW = "OFFER_OVERVIEW",
  OFFER_CREATE = "OFFER_CREATE",
  PLANNING_BOARD = "PLANNING_BOARD",
  NUMBER_RANGES_OVERVIEW = "NUMBER_RANGES_OVERVIEW",
  PROJECT_OVERVIEW = "PROJECT_OVERVIEW",
  PROJECT_EDIT = "PROJECT_EDIT",
  PROJECT_PLANNING_VIEW = "PROJECT_PLANNING_VIEW",
  PROJECT_PLANNING_EDIT = "PROJECT_PLANNING_EDIT",
  QUALIFICATION_OVERVIEW = "QUALIFICATION_OVERVIEW",
  COMPANY_OVERVIEW = "COMPANY_OVERVIEW",
  COMPANY_EDIT = "COMPANY_EDIT",
  PAYMENT_CONDITIONS_EDIT = "PAYMENT_CONDITIONS_EDIT",
  BRANCHE_OVERVIEW = "BRANCHE_OVERVIEW",
  INVOICE_OVERVIEW = "INVOICE_OVERVIEW",
  INVOICE_CREATE = "INVOICE_CREATE",
  CHANGES_OVERVIEW = "CHANGES_OVERVIEW",
  CURRENCY_OVERVIEW = "CURRENCY_OVERVIEW",
  INVENTORY_OVERVIEW = "INVENTORY_OVERVIEW",
  FEEDBACK_OVERVIEW = "FEEDBACK_OVERVIEW",
  ACCOUNTING_OVERVIEW = "ACCOUNTING_OVERVIEW",
  BUSSINESSAREA_OVERVIEW = "BUSSINESSAREA_OVERVIEW",
  USER_LANGUAGES_OVERVIEW = "USER_LANGUAGES_OVERVIEW",
  PROTOCOL_OVERVIEW = "PROTOCOL_OVERVIEW",
  WORK_EQUIPMENT_OVERVIEW = "WORK_EQUIPMENT_OVERVIEW",
  USER_FUNCTION_OVERVIEW = "USER_FUNCTION_OVERVIEW",
  DEPARTMENTS_OVERVIEW = "DEPARTMENTS_OVERVIEW",
  MAILCONFIG_OVERVIEW = "MAILCONFIG_OVERVIEW",
  PDL_OVERVIEW = "PDL_OVERVIEW",
  PDL_CREATE = "PDL_CREATE",
  PDL_EDIT = "PDL_EDIT",
  CRM_MANAGE_OVERVIEW = "CRM_MANAGE_OVERVIEW",
  CRM_OVERVIEW = "CRM_OVERVIEW",
  PROOF_OF_ACTIVITY = "PROOF_OF_ACTIVITY",
  ARTICLE_OVERVIEW = "ARTICLE_OVERVIEW",
  CUSTOMER_ARTICLE_OVERVIEW = "CUSTOMER_ARTICLE_OVERVIEW",
  CUSTOMER_ARTICLE_CREATE = "CUSTOMER_ARTICLE_CREATE",
  CUSTOMER_ARTICLE_EDIT = "CUSTOMER_ARTICLE_EDIT",
  LOCKDATE = "LOCKDATE",
  CUSTOMER_PROJECTS = "CUSTOMER_PROJECTS",
}
