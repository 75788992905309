import { TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import { Currency, Customer, CustomerArticle, Office } from "shared";

/**
 * Helper to generate the customer article table rows with onclick
 *
 * @param allArticles All loaded articles
 * @param allCustomer All customer
 * @param allOffices All available offices
 * @param allCurrencies All currencies
 * @param navigate The navigate function
 * @returns The table entry rows to display
 */
export const generateCustomerArticleTableEntries = (
  allArticles: CustomerArticle[],
  allCustomer: Customer[],
  allOffices: Office[],
  allCurrencies: Currency[],
  navigate: NavigateFunction
): TableRow[] => {
  return allArticles.map((article) => ({
    id: article.id,
    onClick: () => navigate("edit", { state: { article } }),
    content: [
      allCustomer.find((customer) => customer.id === article.customerId)
        ?.name || "-",
      allOffices
        .filter((office) => article.officeIds?.includes(office.id))
        .map((office) => office.name)
        .join(",") || "-",
      article.partnumber || "-",
      article.description || "-",
      `${article.price}${
        allCurrencies.find((curr) => curr.id === article.currencyId)?.symbol ||
        "-"
      }`,
      article.piecePerHour || "-",
    ],
  }));
};
/**
 * Helper to generate a new instance of a customer article
 *
 * @param override Optional parameters to override
 * @returns The newly created customer article
 */
export const generateEmptyCustomerArticle = (
  override?: Partial<CustomerArticle>
): CustomerArticle => ({
  id: undefined!,
  createDate: new Date(),
  customerId: "",
  group: "",
  ...override,
});
