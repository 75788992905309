import { Box, Button, Input, TopBar } from "@sam/components";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createNewLockDate,
  generateNotification,
  getActiveLockDate,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { useUser } from "../../components/UserContext";

export const LockDatePage: React.FC = () => {
  const { axios } = useUser();
  const [newLockDate, setNewLockDate] = useState<Date>();
  const [activeLockDate, setActiveLockDate] = useState<Date>();
  const { t } = useTranslation();

  //Hook to get the active lockDate
  useEffect(() => {
    getActiveLockDate(axios).then((loadedDate) =>
      setActiveLockDate(loadedDate?.date)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Submit handler to create a new lockDate
   * @param evt formEvent to prevent default behaviour
   */
  const handleSubmit = (evt: FormEvent): void => {
    evt.preventDefault();
    newLockDate &&
      createNewLockDate(axios, newLockDate).then((createdLockDate) => {
        setActiveLockDate(createdLockDate);
        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.createNewLockDate"),
        });
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <TopBar title={t("pages.lockDate.topBarHeadline")}></TopBar>
      <Box title={"Sperrdatum anpassen"}>
        <div className="lockdate-wrapper">
          <div className="lockdate-wrapper__active-date">
            <p>{t("pages.lockDate.activeDate")}</p>
            <p>
              {activeLockDate && activeLockDate.toLocaleDateString("DE-de")}
            </p>
          </div>
          <Input
            maxWidth={300}
            label={t("pages.lockDate.newDate")}
            type="date"
            value={newLockDate}
            onChangeDate={setNewLockDate}
          />
          <Button type="submit" value={t("general.buttons.save")} />
        </div>
      </Box>
    </form>
  );
};
