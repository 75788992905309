import {
  Box,
  Button,
  Input,
  Popup,
  Table,
  TableRow,
  TextArea,
  TopBar,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BusinessArea,
  Company,
  Currency,
  DeliveryNote,
  DeliveryNoteMailConfiguration,
  Language,
  MailType,
  OfferedTask,
  Office,
  Order,
  Project,
  createAndSaveDeliveryNotePdf,
  createAndSendDeliveryNote,
  createTransientDeliveryNotePdf,
  generateNotification,
  getBusinessAreaById,
  getCompanyById,
  getCurrencyById,
  getCustomerById,
  getOfficeById,
  getOrderById,
  saveNewDeliveryNote,
  useData,
} from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { convertTasksIntoDeliveryNoteTableEntries } from "../../utils/deliveryNotes/DeliveryNotes.utils";
import {
  generateEmptyDeliveryNote,
  generateEmptyOfferedTask,
} from "../../utils/order/Order.utils";
import { MailPopup } from "../../components/mailPopup/MailPopup";
import { generateEmptyMailConfiguration } from "../../utils/mail/Mail.utils";
import { NotificationType } from "shared/src/notification/notification.types";

export const DeliveryNoteCreate: React.FC = () => {
  const { axios, user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation<{ project: Project }>();
  const [order, setOrder] = useState<Order>();
  const [project] = useState<Project | undefined>(location.state?.project);
  const [currency, setCurrency] = useState<Currency>();
  const [businessArea, setBusinessArea] = useState<BusinessArea>();
  const [company, setCompany] = useState<Company>();
  const [tasks, setTasks] = useState<OfferedTask[]>([]);
  const [deliveryNote, setDeliveryNote] = useState<DeliveryNote>(
    generateEmptyDeliveryNote({ orderId: location.state?.project?.orderId })
  );
  const [showMailPopup, toggleMailPopup] = useState<boolean>(false);

  const [taskToDelete, setTaskToDelete] = useState<string>();
  const { data: loadedTasks } = useData("TASKS_ALL", {
    config: { fallbackData: [] },
  });
  const [office, setOffice] = useState<Office>();
  const [documentToShow, setDocumentToShow] = useState<Blob>();

  const [mailConfig, setMailConfig] = useState<DeliveryNoteMailConfiguration>({
    ...generateEmptyMailConfiguration(),
    type: MailType.DELIVERY_NOTE,
    activeUser: user.id,
    deliveryNote,
  });

  //Loads all neccessary data to be displayed
  useEffect(() => {
    if (!order) return;
    Promise.all([
      getOfficeById(axios, order.officeId),
      getCurrencyById(axios, order.currencyId),
      getBusinessAreaById(axios, order.businessAreaId),
      getCompanyById(axios, order.companyId),
      getCustomerById(axios, order.customerId),
    ]).then(
      ([
        loadedOffice,
        loadedCurrency,
        loadedBusinessArea,
        loadedCompany,
        loadedCustomer,
      ]) => {
        setOffice(loadedOffice);
        setCurrency(loadedCurrency);
        setBusinessArea(loadedBusinessArea);
        setCompany(loadedCompany);
        loadedCustomer &&
          setMailConfig({
            ...mailConfig,
            receiver: loadedCustomer.contact.mail,
          });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  //Hook to load the order for the selected project
  useEffect(() => {
    if (!project?.id) return;
    getOrderById(axios, project.orderId).then(setOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.id]);

  /**
   * Submit handler to create the new deliveryNote and show the preview
   * @param evt FormEvent to prevent default behaviour
   */
  const showPreview = (): void => {
    saveNewDeliveryNote(axios, { ...deliveryNote, offeredTasks: tasks }).then(
      (generatedNote) => {
        if (!generatedNote) return;
        setDeliveryNote(generatedNote);
        createTransientDeliveryNotePdf(axios, {
          deliveryNote: generatedNote,
          language: Language.DE,
          user,
        }).then(
          (generatedDocument) =>
            generatedDocument && setDocumentToShow(generatedDocument)
        );
      }
    );
  };

  /**
   * Offered tasks in an editable table
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertTasksIntoDeliveryNoteTableEntries(
        tasks,
        loadedTasks,
        setTasks,
        setTaskToDelete
      ),
    [loadedTasks, tasks]
  );

  /**
   * Helper method to create a deliveryNotePdf and upload it to the projects documents
   * @param sendMail decides if the mail should be sent or not
   */
  const handleCreateAndSavePdf = (sendMail: boolean = false): void => {
    if (!sendMail)
      createAndSaveDeliveryNotePdf(axios, {
        deliveryNote: { ...deliveryNote, offeredTasks: tasks },
        language: Language.DE,
        user,
      }).then((success) => success && navigate(-1));
    else
      createAndSendDeliveryNote(axios, mailConfig).then((success) => {
        if (success) {
          toggleMailPopup(false);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.sendDeliveryNote"),
          });
        }
      });
  };

  return (
    <form>
      <MailPopup
        isOpen={showMailPopup}
        mailConfig={mailConfig}
        sendMail={() => handleCreateAndSavePdf(true)}
        toggleOpen={toggleMailPopup}
        updateConfig={(updatedConfig) =>
          setMailConfig((old) => ({ ...old, ...updatedConfig }))
        }
      />
      <Popup
        isOpen={!!taskToDelete}
        onClose={() => setTaskToDelete(undefined)}
        buttons={[
          <Button
            value={t("general.buttons.abort")}
            onClick={() => setTaskToDelete(undefined)}
          />,
          <Button
            value={t("general.buttons.delete")}
            onClick={() =>
              setTasks(tasks.filter((task) => task.id !== taskToDelete))
            }
          />,
        ]}
      >
        <p>{t("pages.deliveryNote.create.deleteTask")}</p>
      </Popup>
      <TopBar
        onBackClick={() => navigate(-1)}
        title={t("pages.deliveryNote.create.topBarHeadline")}
      >
        <Button
          type="button"
          value={t("general.buttons.save")}
          onClick={handleCreateAndSavePdf}
        />
        <Button
          type="button"
          onClick={() => toggleMailPopup(true)}
          value={t("general.buttons.send")}
        />
        <Button
          type="button"
          value={t("general.buttons.preview")}
          onClick={showPreview}
        />
      </TopBar>

      {documentToShow && (
        <Popup
          isOpen={!!documentToShow}
          onClose={() => setDocumentToShow(undefined)}
        >
          <object
            data={URL.createObjectURL(documentToShow)}
            type={documentToShow.type}
            width="1000px"
            height="700px"
          />
        </Popup>
      )}

      <Box title={t("pages.deliveryNote.create.basicInformation")}>
        <Input
          type="text"
          disabled
          value={project?.numberRangeNumber}
          label={t("pages.deliveryNote.create.projectNumber")}
        />
        <div className="three-columns">
          <Input
            type="text"
            disabled
            value={office?.name}
            label={t("pages.deliveryNote.create.office")}
          />
          <Input
            type="text"
            disabled
            value={currency?.title}
            label={t("pages.deliveryNote.create.currency")}
          />
          <Input
            type="text"
            disabled
            value={businessArea?.name}
            label={t("pages.deliveryNote.create.businessArea")}
          />
          <Input
            type="text"
            disabled
            value={company?.name}
            label={t("pages.deliveryNote.create.company")}
          />
        </div>
      </Box>
      <Box title={t("pages.deliveryNote.create.deliveryNote")}>
        <div className="three-columns">
          <Input
            type="date"
            onChangeDate={(deliveryDate) =>
              deliveryDate && setDeliveryNote({ ...deliveryNote, deliveryDate })
            }
            value={deliveryNote.deliveryDate}
            label={t("pages.deliveryNote.create.deliveryDate")}
          />
          <Input
            type="date"
            onChangeDate={(startDate) =>
              startDate &&
              setDeliveryNote({
                ...deliveryNote,
                performancePeriod: {
                  ...deliveryNote.performancePeriod,
                  startDate,
                },
              })
            }
            value={deliveryNote.performancePeriod.startDate}
            label={t("pages.deliveryNote.create.periodStart")}
          />
          <Input
            type="date"
            onChangeDate={(endDate) =>
              endDate &&
              setDeliveryNote({
                ...deliveryNote,
                performancePeriod: {
                  ...deliveryNote.performancePeriod,
                  endDate,
                },
              })
            }
            value={deliveryNote.performancePeriod.endDate}
            label={t("pages.deliveryNote.create.periodEnd")}
          />
        </div>
        <TextArea
          value={deliveryNote.annotation}
          onChange={(annotation) =>
            setDeliveryNote({ ...deliveryNote, annotation })
          }
          label={t("pages.deliveryNote.create.annotation")}
        />
        <PlusIcon
          className="delivery-note__plus-icon"
          width={30}
          onClick={() =>
            setTasks([
              ...tasks,
              generateEmptyOfferedTask({ index: tasks.length + 1 }),
            ])
          }
        />
        <Table
          rows={rows}
          header={
            t("pages.deliveryNote.create.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </form>
  );
};
