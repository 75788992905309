import { Box, Dropdown, Input, TopBar } from "@sam/components";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CustomerArticle,
  createNewCustomerArticle,
  generateDropdownOptions,
  generateNotification,
  updateCustomerArticle,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import { generateEmptyCustomerArticle } from "../../utils/CustomerArticle.utils";

const CustomerArticleCreateOrEdit: React.FC = () => {
  const { user, axios } = useUser();
  const location = useLocation<{ article: CustomerArticle }>();
  const [articleToEdit, setArticleToEdit] = useState<CustomerArticle>(
    location.state?.article
      ? location.state.article
      : generateEmptyCustomerArticle()
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const { data: allCustomer } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allOffices } = useData("OFFICES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCurrencies } = useData("CURRENCY_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Submit handler to update or create a new customerArticle
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;
    if (articleToEdit.id) {
      updateCustomerArticle(axios, articleToEdit).then((updatedArticle) => {
        if (updatedArticle && redirect) navigate(-1);
        else if (updatedArticle) {
          setArticleToEdit(updatedArticle);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
        }
      });
    } else
      createNewCustomerArticle(axios, {
        ...articleToEdit,
        lastUpdated: new Date(),
        updatedBy: user.id,
      }).then((savedArticle) => {
        if (savedArticle && redirect) navigate(-1);
        else if (savedArticle) {
          setArticleToEdit(savedArticle);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
        }
      });
  };

  return (
    <form
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(evt) => evt.key === "enter" && evt.preventDefault()}
      ref={form}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          articleToEdit.id
            ? t("pages.customerArticleCreateOrEdit.editHeadline")
            : t("pages.customerArticleCreateOrEdit.createHeadline")
        }
      >
        <SaveButtons buttonRef={button} handleSubmit={handleSubmit} />
      </TopBar>
      <Box title={t("pages.customerArticleCreateOrEdit.basedata")}>
        <fieldset className="customerArticle-create__input-fieldset three-columns">
          <Dropdown
            selectedOption={articleToEdit.customerId}
            options={generateDropdownOptions(allCustomer, "name", "id")}
            label={t("pages.customerArticleCreateOrEdit.labels.customer")}
            onChange={(customerId) =>
              setArticleToEdit((old) => ({ ...old, customerId }))
            }
          />
          <Dropdown
            selectedMultiOptions={articleToEdit.officeIds}
            options={generateDropdownOptions(allOffices, "name", "id")}
            label={t("pages.customerArticleCreateOrEdit.labels.office")}
            multi
            onChangeMultiple={(officeIds) =>
              setArticleToEdit((old) => ({ ...old, officeIds }))
            }
          />
          <Input
            value={articleToEdit.group}
            type="text"
            onChange={(group) => setArticleToEdit((old) => ({ ...old, group }))}
            label={t("pages.customerArticleCreateOrEdit.labels.group")}
          />
          <Input
            value={articleToEdit.partnumber}
            type="text"
            onChange={(partnumber) =>
              setArticleToEdit((old) => ({ ...old, partnumber }))
            }
            label={t("pages.customerArticleCreateOrEdit.labels.partNumber")}
          />
          <Input
            value={articleToEdit.description}
            type="text"
            onChange={(description) =>
              setArticleToEdit((old) => ({ ...old, description }))
            }
            label={t("pages.customerArticleCreateOrEdit.labels.description")}
          />
          <Input
            value={articleToEdit.piecePerHour}
            type="number"
            onChangeNumber={(piecePerHour) =>
              setArticleToEdit((old) => ({ ...old, piecePerHour }))
            }
            label={t("pages.customerArticleCreateOrEdit.labels.piecePerHour")}
          />
          <Input
            value={articleToEdit.unit}
            type="text"
            onChange={(unit) => setArticleToEdit((old) => ({ ...old, unit }))}
            label={t("pages.customerArticleCreateOrEdit.labels.unit")}
          />
          <Input
            value={articleToEdit.price}
            type="number"
            onChangeNumber={(price) =>
              setArticleToEdit((old) => ({ ...old, price }))
            }
            label={t("pages.customerArticleCreateOrEdit.labels.price")}
          />
          <Dropdown
            selectedOption={articleToEdit.currencyId}
            options={generateDropdownOptions(allCurrencies, "symbol", "id")}
            label={t("pages.customerArticleCreateOrEdit.labels.currency")}
            onChange={(currencyId) =>
              setArticleToEdit((old) => ({ ...old, currencyId }))
            }
          />
        </fieldset>
      </Box>
    </form>
  );
};
export default CustomerArticleCreateOrEdit;
