import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useData } from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { convertCrmEntriesIntoTableEntries } from "../../utils/crm/Crm.utils";

export const CrmEntryOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [filterValue, setFilterValue] = useState<string>("");

  const { data: allEntries } = useData("CRM_ENTRIES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCustomerUser } = useData("CUSTOMER_USERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCustomer } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * TableRows containing all crm manage entries
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertCrmEntriesIntoTableEntries(
        allEntries,
        navigate,
        allCustomer,
        allCustomerUser
      ),
    [allCustomer, allCustomerUser, allEntries, navigate]
  );

  return (
    <>
      <TopBar
        title={t(`pages.crm.overview.topBarHeadline`)}
        onSearch={setFilterValue}
      >
        <PlusIcon width={30} onClick={() => navigate("edit")} />
      </TopBar>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.crm.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
