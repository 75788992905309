import { Button, Checkbox, Input } from "@sam/components";
import { useTranslation } from "react-i18next";
import { Bike, Car, MonthlyReceipt, useData } from "shared";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { generateEmptyBonusPayout } from "../../utils/monthlyReceipt/MonthlyReceipt.utils";

/**
 * Component to display and edit data of a monthlyReceipt
 * @param monthlyReceipt to display and edit
 * @param setMonthlyOverviews setter for monthly overviews that include the monthlyReceipt
 * @param monthlyReceipts list of all monthlyReceipts
 * @returns  JSX with the detailBox
 */
const ReceiptDetailBox: React.FC<{
  monthlyReceipt: MonthlyReceipt;
  setMonthlyOverviews: Dispatch<SetStateAction<MonthlyReceipt[]>>;
  monthlyReceipts: MonthlyReceipt[];
}> = ({ monthlyReceipt, setMonthlyOverviews, monthlyReceipts }) => {
  const { t } = useTranslation();
  const [car, setCar] = useState<Car>();
  const [bike, setBike] = useState<Bike>();

  const { data: cars } = useData("CARS_ALL", {
    config: { fallbackData: [] },
  });

  const { data: bikes } = useData("BIKES_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * useEffect hook to set a bike or a car of a receipt, if the receipt contains the id
   */
  useEffect(() => {
    if (monthlyReceipt.car)
      setCar(cars.find((car) => car.id === monthlyReceipt.car));
    if (monthlyReceipt.bycicle)
      setBike(bikes.find((bike) => bike.id === monthlyReceipt.bycicle));
  }, [bikes, cars, monthlyReceipt.bycicle, monthlyReceipt.car]);

  return (
    <div id="receipt-edit-form" className="receipt-detail-box__wrapper">
      <div className="receipt-edit-form__left-section">
        <div className="receipt-detail-box__box">
          <Checkbox
            isChecked={!!monthlyReceipt.bycicle}
            label={t("pages.monthlySummary.children.bike")}
            disabled
            onCheck={() => {}}
          />
          <Input
            labelBold={false}
            disabled
            type="text"
            value={bike?.description || ""}
            onChange={() => {}}
            label={t("pages.monthlySummary.children.description")}
          />
        </div>
        <div className="receipt-detail-box__box">
          <Checkbox
            onCheck={() => {}}
            isChecked={!!monthlyReceipt.car}
            label={t("pages.monthlySummary.children.car")}
            disabled
          />
          <div className="receipt-detail-box__box__double-input">
            <Input
              onChange={() => {}}
              type="text"
              value={car?.grossListPrice || 0}
              label={t("pages.monthlySummary.children.grossListPrice")}
              labelBold={false}
              disabled
            />
            <Input
              onChange={() => {}}
              labelBold={false}
              type="text"
              value={car?.registrationPlate || ""}
              label={t("pages.monthlySummary.children.licensePlate")}
              disabled
            />
          </div>
        </div>
        <div className="receipt-detail-box__box">
          <p>{t("pages.monthlySummary.children.travelCost")}</p>
          <div className="receipt-detail-box__box__double-input">
            <Input
              labelBold={false}
              type="number"
              value={monthlyReceipt.travelCost?.km || 0}
              label={t("pages.monthlySummary.children.km")}
              onChangeNumber={(km) =>
                setMonthlyOverviews(
                  monthlyReceipts.map((receipt) =>
                    receipt.id === monthlyReceipt.id
                      ? {
                          ...receipt,
                          travelCost: { ...receipt.travelCost, km },
                        }
                      : receipt
                  )
                )
              }
            />
            <Input
              labelBold={false}
              type="number"
              value={monthlyReceipt.travelCost?.vma || 0}
              label={t("pages.monthlySummary.children.vma")}
              onChangeNumber={(vma) =>
                setMonthlyOverviews(
                  monthlyReceipts.map((receipt) =>
                    receipt.id === monthlyReceipt.id
                      ? {
                          ...receipt,
                          travelCost: { ...receipt.travelCost, vma },
                        }
                      : receipt
                  )
                )
              }
            />
          </div>
        </div>
        <div className="receipt-detail-box__box">
          <p>{t("pages.monthlySummary.children.travelCostTaxFree")}</p>
          <div className="receipt-detail-box__box__double-input">
            <Input
              labelBold={false}
              type="number"
              value={monthlyReceipt.travelCostTaxFree?.km || 0}
              label={t("pages.monthlySummary.children.km")}
              onChangeNumber={(km) =>
                setMonthlyOverviews(
                  monthlyReceipts.map((receipt) =>
                    receipt.id === monthlyReceipt.id
                      ? {
                          ...receipt,
                          travelCostTaxFree: {
                            ...receipt.travelCostTaxFree,
                            km: km,
                          },
                        }
                      : receipt
                  )
                )
              }
            />
            <Input
              labelBold={false}
              type="number"
              value={monthlyReceipt.travelCostTaxFree?.vma || 0}
              label={t("pages.monthlySummary.children.vma")}
              onChangeNumber={(vma) =>
                setMonthlyOverviews(
                  monthlyReceipts.map((receipt) =>
                    receipt.id === monthlyReceipt.id
                      ? {
                          ...receipt,
                          travelCostTaxFree: {
                            ...receipt.travelCostTaxFree,
                            vma,
                          },
                        }
                      : receipt
                  )
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="receipt-edit-form__right-section">
        <div className="receipt-detail-box__box__50">
          <p>{t("pages.monthlySummary.children.bonusPayment")}</p>
          <div className="receipt-detail-box__box__bonus-payment-inputs">
            {monthlyReceipt.bonusPayouts.map((payout) => {
              return (
                <div className="receipt-detail-box__box__bonus-payment-inputs__entry">
                  <Input
                    type="text"
                    value={payout.description}
                    onChange={(description) => {
                      setMonthlyOverviews(
                        monthlyReceipts.map((receipt) => {
                          return receipt.id === monthlyReceipt.id
                            ? {
                                ...receipt,
                                bonusPayouts: receipt.bonusPayouts.map(
                                  (bonusPayout) =>
                                    bonusPayout.id === payout.id
                                      ? { ...bonusPayout, description }
                                      : bonusPayout
                                ),
                              }
                            : receipt;
                        })
                      );
                    }}
                  />
                  <Input
                    type="number"
                    value={payout.amount}
                    onChangeNumber={(amount) =>
                      setMonthlyOverviews(
                        monthlyReceipts.map((receipt) => {
                          return receipt.id === monthlyReceipt.id
                            ? {
                                ...receipt,
                                bonusPayouts: receipt.bonusPayouts.map(
                                  (bonusPayout) =>
                                    bonusPayout.id === payout.id
                                      ? { ...bonusPayout, amount }
                                      : bonusPayout
                                ),
                              }
                            : receipt;
                        })
                      )
                    }
                  />
                  <div className="receipt-detail-box__box__bonus-payment-delete">
                    <DeleteIcon
                      width={35}
                      color="white"
                      onClick={() =>
                        setMonthlyOverviews(
                          monthlyReceipts.map((receipt) => {
                            return receipt.id === monthlyReceipt.id
                              ? {
                                  ...receipt,
                                  bonusPayouts:
                                    monthlyReceipt.bonusPayouts.filter(
                                      (oldPayout) => oldPayout.id !== payout.id
                                    ),
                                }
                              : receipt;
                          })
                        )
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <Button
            maxWidth={"none"}
            value={
              <div className="receipt-detail-box__button-value-wrapper">
                <PlusIcon width={25} color="white" />
                <p>{t("general.buttons.add")}</p>
              </div>
            }
            onClick={() => {
              setMonthlyOverviews(
                monthlyReceipts.map((receipt) =>
                  receipt.id === monthlyReceipt.id
                    ? {
                        ...receipt,
                        bonusPayouts: [
                          ...receipt.bonusPayouts,
                          generateEmptyBonusPayout(),
                        ],
                      }
                    : receipt
                )
              );
            }}
          />
        </div>
        <div className="receipt-detail-box__box__50">
          <p>{t("pages.monthlySummary.children.addOns")}</p>
          <p>{t("pages.monthlySummary.children.description")}</p>
          <div className="receipt-detail-box__box__addOns">
            <p>{t("pages.monthlySummary.children.saturdayNight")}</p>
            <Input
              value={monthlyReceipt.saturdayBonus + monthlyReceipt.nightBonus}
              type="number"
              label={t("pages.monthlySummary.children.hours")}
              onChangeNumber={(newBonus) =>
                setMonthlyOverviews(
                  monthlyReceipts.map((receipt) =>
                    receipt.id === monthlyReceipt.id
                      ? {
                          ...receipt,
                          saturdayBonus: newBonus,
                          nightBonus: 0,
                        }
                      : receipt
                  )
                )
              }
            />
          </div>
          <div className="receipt-detail-box__box__addOns">
            <p>{t("pages.monthlySummary.children.sunday")}</p>
            <Input
              type="number"
              value={monthlyReceipt.sundayBonus}
              onChangeNumber={(newBonus) => {
                if (newBonus)
                  setMonthlyOverviews(
                    monthlyReceipts.map((receipt) =>
                      receipt.id === monthlyReceipt.id
                        ? {
                            ...receipt,
                            sundayBonus: newBonus,
                          }
                        : receipt
                    )
                  );
              }}
            />
          </div>

          <div className="receipt-detail-box__box__addOns">
            <p>{t("pages.monthlySummary.children.holiday100")}</p>
            <Input
              type="number"
              value={monthlyReceipt.holidayBonus100}
              onChangeNumber={(newBonus) => {
                if (newBonus)
                  setMonthlyOverviews(
                    monthlyReceipts.map((receipt) =>
                      receipt.id === monthlyReceipt.id
                        ? {
                            ...receipt,
                            holidayBonus100: newBonus,
                          }
                        : receipt
                    )
                  );
              }}
            />
          </div>

          <div className="receipt-detail-box__box__addOns">
            <p>{t("pages.monthlySummary.children.holiday150")}</p>
            <Input
              type="number"
              value={monthlyReceipt.holidayBonus150}
              onChangeNumber={(newBonus) => {
                if (newBonus)
                  setMonthlyOverviews(
                    monthlyReceipts.map((receipt) =>
                      receipt.id === monthlyReceipt.id
                        ? {
                            ...receipt,
                            holidayBonus150: newBonus,
                          }
                        : receipt
                    )
                  );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReceiptDetailBox;
