import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Table,
  TableRow,
  TextArea,
  TopBar,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CrmActionEntry,
  CrmEntry,
  createNewCrmActionEntry,
  createNewCrmEntry,
  generateDropdownOptions,
  generateNotification,
  getAllCrmActionEntries,
  updateCrmEntry,
  updateMultipleCrmActionEntries,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import {
  generateEmptyCrmActionEntry,
  generateEmptyCrmEntry,
} from "../../utils/crm/Crm.utils";

export const CrmEntryEdit: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios } = useUser();
  const [isNonCustomer, toggleNonCustomer] = useState<boolean>(false);
  const location = useLocation<{
    entry?: CrmEntry;
  }>();
  const [entryToEdit, setEntryToEdit] = useState<CrmEntry>(
    location.state?.entry ? location.state.entry : generateEmptyCrmEntry()
  );
  const [newAction, setNewAction] = useState<CrmActionEntry>(
    generateEmptyCrmActionEntry({ crmEntryId: location.state?.entry?.id })
  );
  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const [actionsToEdit, setActionsToEdit] = useState<CrmActionEntry[]>([]);
  const isEdit: boolean = useMemo(() => !!entryToEdit.id, [entryToEdit.id]);

  const { data: allOffices } = useData("OFFICES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allTypes } = useData("CRM_TYPES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allActions } = useData("CRM_ACTIONS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCustomer } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCustomerUser } = useData("CUSTOMER_USERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allSimpleUser } = useData("SIMPLEUSERS_ALL", {
    config: { fallbackData: [] },
  });

  // lazy loads the crmActionEntries which belong to the entry to edit
  // also checks if the entry to edit is one with a custom name
  useEffect(() => {
    if (entryToEdit.id) {
      getAllCrmActionEntries(entryToEdit.id, axios).then(setActionsToEdit);
      toggleNonCustomer(!!entryToEdit.customerName);
    }
    // needs to be disabled as the toggle should only trigger on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, entryToEdit.id]);

  /**
   * Submit handler to update or create a crm entry with corresponding actions
   * @param redirect decides if the method navigates back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    if (entryToEdit.id) {
      Promise.all([
        updateCrmEntry(entryToEdit, axios),
        updateMultipleCrmActionEntries(actionsToEdit, axios),
      ]).then(([entryResp]) => {
        if (redirect && entryResp) navigate(-1);
        else if (entryResp)
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.updateCrmEntry"),
          });
      });
    } else {
      createNewCrmEntry(
        { initialAction: newAction, newEntry: entryToEdit },
        axios
      ).then((resp) => {
        if (redirect && resp) navigate(-1);
        else if (resp)
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.createCrmEntry"),
          });
      });
    }
  };

  /**
   * Helper to update the local entry to edit
   * @param newValue The user entered value
   * @param key The key to update
   */
  const updateEntryToEdit = (newValue: string, key: keyof CrmEntry): void => {
    setEntryToEdit((old) => ({ ...old, [key]: newValue }));
  };

  /**
   * Helper to update the new action
   * @param newValue The user entered value
   * @param key The key to update
   */
  const updateNewAction = (
    newValue: string | string[] | Date | undefined,
    key: keyof CrmActionEntry
  ): void => {
    setNewAction((old) => ({ ...old, [key]: newValue }));
  };

  /**
   * Helper to update a action in the array of all crm entry action
   * @param newValue The user entered value
   * @param key The key to update
   * @param actionId The id of the edited instance
   */
  const updateActions = useCallback(
    (
      newValue: string | string[] | Date | boolean | undefined,
      key: keyof CrmActionEntry,
      actionId?: string
    ): void => {
      const indexToUpdate: number = actionsToEdit.findIndex(
        (action) => action.id === actionId
      );
      setActionsToEdit((old) => {
        const localCopy: CrmActionEntry[] = [...old];
        localCopy[indexToUpdate] = {
          ...localCopy[indexToUpdate],
          [key]: newValue,
        };
        return localCopy;
      });
    },
    [actionsToEdit]
  );

  /**
   * Helper to handle the add event of a new crmActionEntry. Resets the form
   * on success
   * @param evt The form event
   */
  const addNewActionEntry = (evt: FormEvent): void => {
    evt.preventDefault();
    createNewCrmActionEntry(newAction, axios).then((resp) => {
      if (resp) {
        setActionsToEdit((old) => [...old, resp]);
        setNewAction(
          generateEmptyCrmActionEntry({ crmEntryId: entryToEdit.id })
        );
      }
    });
  };

  // holds the table entries for the crmActionEntries
  const actionRows: TableRow[] = useMemo(
    (): TableRow[] =>
      actionsToEdit.map(
        (action) =>
          ({
            id: action.id,
            content: [
              <Checkbox
                isChecked={action.done}
                onCheck={(checked) => updateActions(checked, "done", action.id)}
              />,
              allActions.find((act) => act.id === action.actionId)?.title ||
                "-",
              action.createDate.toLocaleDateString("de-DE"),
              action.note,
              allSimpleUser
                .filter((user) => action.internalContact.includes(user.id))
                .map((user) => user.lastName)
                .join(", "),
              action.dueDate?.toLocaleDateString("de-DE") || "-",
            ],
          } as TableRow)
      ),
    [actionsToEdit, allActions, allSimpleUser, updateActions]
  );

  /**
   * Holds the correct contact fields. Either dropdowns or inputs
   */
  const contactFields = useMemo(() => {
    if (isNonCustomer) {
      return (
        <>
          <Input
            type="text"
            value={entryToEdit.customerName}
            onChange={(newValue) => updateEntryToEdit(newValue, "customerName")}
            label={t("pages.crm.edit.customerId")}
          />
          <Input
            type="text"
            value={entryToEdit.contactName}
            onChange={(newValue) => updateEntryToEdit(newValue, "contactName")}
            label={t("pages.crm.edit.contactId")}
          />
        </>
      );
    } else {
      return (
        <>
          <Dropdown
            options={generateDropdownOptions(allCustomer, "name", "id")}
            onChange={(customer) => updateEntryToEdit(customer, "customerId")}
            label={t("pages.crm.edit.customerId")}
            selectedOption={entryToEdit.customerId}
          />
          <Dropdown
            disabled={!entryToEdit.customerId}
            options={generateDropdownOptions(
              allCustomerUser.filter(
                (user) => user.customerId === entryToEdit.customerId
              ),
              "lastName",
              "id"
            )}
            selectedOption={entryToEdit.contactId}
            onChange={(customer) => updateEntryToEdit(customer, "contactId")}
            label={t("pages.crm.edit.contactId")}
          />
        </>
      );
    }
  }, [allCustomer, allCustomerUser, entryToEdit, isNonCustomer, t]);

  /**
   * Helper to handle the checkbox click for a non customer contact
   *
   * @param toggled if the checkbox is checked or not
   */
  const handleNonCustomerToggle = (toggled: boolean): void => {
    if (toggled) {
      updateEntryToEdit("", "customerId");
      updateEntryToEdit("", "contactId");
    } else {
      updateEntryToEdit("", "customerName");
      updateEntryToEdit("", "contactName");
    }
    toggleNonCustomer(toggled);
  };

  return (
    <>
      <form
        ref={form}
        onSubmit={(evt) => evt.preventDefault()}
        onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
      >
        <TopBar
          onBackClick={() => navigate(-1)}
          title={t(
            `pages.crm.edit.topBarHeadline${isEdit ? "Edit" : "Create"}`
          )}
        >
          <SaveButtons buttonRef={button} handleSubmit={handleSubmit} />
        </TopBar>
        <Box title={t("pages.crm.edit.infoTitle")}>
          <Dropdown
            options={generateDropdownOptions(allOffices, "name", "id")}
            onChange={(office) => updateEntryToEdit(office, "officeId")}
            label={t("pages.crm.edit.officeId")}
            selectedOption={entryToEdit.officeId}
          />
          <Dropdown
            options={generateDropdownOptions(allTypes, "title", "id")}
            onChange={(type) => updateEntryToEdit(type, "typeId")}
            label={t("pages.crm.edit.type")}
            selectedOption={entryToEdit.typeId}
          />
          <Checkbox
            isChecked={isNonCustomer}
            onCheck={handleNonCustomerToggle}
            label={t("pages.crm.edit.toggleNonCustomer")}
          />
          {contactFields}
        </Box>
      </form>
      <Box title={t("pages.crm.edit.actionTitle")}>
        <form onSubmit={addNewActionEntry}>
          <div className="three-columns">
            <Dropdown
              options={generateDropdownOptions(allActions, "title", "id")}
              selectedOption={newAction.actionId}
              required
              onChange={(customer) => updateNewAction(customer, "actionId")}
              label={t("pages.crm.edit.actionType")}
            />
            <TextArea
              label={t("pages.crm.edit.note")}
              value={newAction.note}
              required
              onChange={(note) => updateNewAction(note, "note")}
            />
            <Dropdown
              multi
              options={allSimpleUser.map((user) => ({
                label: `${user.lastName}, ${user.firstName}`,
                value: user.id,
              }))}
              label={t("pages.crm.edit.internalContact")}
              onChangeMultiple={(contacts) =>
                updateNewAction(contacts, "internalContact")
              }
              selectedMultiOptions={newAction.internalContact}
              required
            />
            <Input
              type="date"
              label={t("pages.crm.edit.dueDate")}
              onChangeDate={(newDate) => updateNewAction(newDate, "dueDate")}
              value={newAction.dueDate}
            />
          </div>
          {entryToEdit.id && (
            <>
              <Button value={t("pages.crm.edit.addButton")} type="submit" />
              <Table
                header={
                  t("pages.crm.edit.actionTableHeader", {
                    returnObjects: true,
                  }) as TableHeader[]
                }
                rows={actionRows}
              />
            </>
          )}
        </form>
      </Box>
    </>
  );
};
