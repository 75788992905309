import { Box, Button, Dropdown, Input, TopBar } from "@sam/components";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BusinessArea,
  createNewBusinessArea,
  deactivateBusinessArea,
  generateDropdownOptions,
  generateNotification,
  updateBusinessArea,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import { generateEmptyBusinessArea } from "../../utils/businessArea/BusinessArea.utils";
import { getUserNameForSimpleUser } from "../../utils/user/User.utils";

export const BusinessAreaEdit: React.FC = () => {
  const { axios } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const form = useRef<HTMLFormElement>(null);
  const button = useRef<HTMLButtonElement>(null);

  const location = useLocation<{ businessArea?: BusinessArea }>();
  const { data: loadedCompanies } = useData("COMPANY_ALL", {
    config: { fallbackData: [] },
  });

  const [businessArea, setBusinessArea] = useState<BusinessArea>(
    location.state?.businessArea
      ? location.state.businessArea
      : generateEmptyBusinessArea()
  );

  const { data: loadedSimpleUsers } = useData("SIMPLEUSERS_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Helper method to handle the form on Submit
   * @param redirect decides if the method should navigate back after success

   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    if (businessArea.id) {
      updateBusinessArea(axios, businessArea).then((updatedBusinessArea) => {
        if (updatedBusinessArea && redirect) navigate("/businessarea");
        else if (updatedBusinessArea) {
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.updateBusinessArea"),
          });
          setBusinessArea(updatedBusinessArea);
        }
      });
    } else {
      createNewBusinessArea(axios, businessArea).then((createdBusinessArea) => {
        if (createdBusinessArea && redirect) navigate("/businessarea");
        else if (createdBusinessArea) {
          setBusinessArea(createdBusinessArea);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.createNewBusinessArea"),
          });
        }
      });
    }
  };

  /**
   * Helper method to deactivate a businessArea
   */
  const handleDeactivateBusinessArea = (): void => {
    deactivateBusinessArea(axios, businessArea.id).then((success) => {
      if (!success) return;
      generateNotification({
        type: NotificationType.SUCCESS,
        value: t("general.notification.success.deactivateBusinessArea"),
      });
      navigate("/businessarea");
    });
  };

  return (
    <form
      onSubmit={(evt) => evt.preventDefault()}
      ref={form}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          businessArea.id
            ? t("pages.businessArea.edit.topBarHeadlineEdit")
            : t("pages.businessArea.edit.topBarHeadlineCreate")
        }
      >
        {businessArea.id && (
          <Button
            value={t("general.buttons.deactivate")}
            onClick={handleDeactivateBusinessArea}
          />
        )}
        <SaveButtons buttonRef={button} handleSubmit={handleSubmit} />
      </TopBar>
      <Box title={t("pages.businessArea.edit.boxTitle")}>
        <div className="three-columns">
          <Input
            type="text"
            onChange={(name) => setBusinessArea({ ...businessArea, name })}
            label={t("pages.businessArea.edit.name")}
            value={businessArea.name}
          />
          <Dropdown
            label={t("pages.businessArea.edit.companyId")}
            selectedOption={businessArea.companyId}
            onChange={(companyId) =>
              setBusinessArea((old) => ({ ...old, companyId }))
            }
            options={generateDropdownOptions(loadedCompanies, "name", "id")}
          />
          <Input
            type="number"
            minValue={0}
            maxValue={9}
            onChangeNumber={(numberRangeValue) =>
              setBusinessArea({ ...businessArea, numberRangeValue })
            }
            value={businessArea.numberRangeValue}
            label={t("pages.businessArea.edit.numberRangeValue")}
          />
          <Input
            type="text"
            value={getUserNameForSimpleUser(
              businessArea.createdBy,
              loadedSimpleUsers
            )}
            disabled
            label={t("pages.businessArea.edit.createdBy")}
          />
          <Input
            type="date"
            disabled
            value={businessArea.createDate}
            label={t("pages.businessArea.edit.createDate")}
          />
          <Input
            type="date"
            disabled
            value={businessArea.lastUpdate}
            label={t("pages.businessArea.edit.lastUpdate")}
          />
          <Input
            type="text"
            value={
              businessArea.updatedBy
                ? getUserNameForSimpleUser(
                    businessArea.updatedBy,
                    loadedSimpleUsers
                  )
                : "-"
            }
            disabled
            label={t("pages.businessArea.edit.updatedBy")}
          />
        </div>
      </Box>
      <Box title={t("pages.businessArea.edit.financialTitle")}>
        <div className="three-columns">
          <Input
            type="text"
            value={businessArea.kstNr}
            onChange={(kstNr) => setBusinessArea((old) => ({ ...old, kstNr }))}
            label={t("pages.businessArea.edit.kstNr")}
          />
          <Input
            type="text"
            value={businessArea.sachInner}
            onChange={(sachInner) =>
              setBusinessArea((old) => ({ ...old, sachInner }))
            }
            label={t("pages.businessArea.edit.sachInner")}
          />
          <Input
            type="text"
            value={businessArea.sachEu}
            onChange={(sachEu) =>
              setBusinessArea((old) => ({ ...old, sachEu }))
            }
            label={t("pages.businessArea.edit.sachEu")}
          />
          <Input
            type="text"
            value={businessArea.sachThird}
            onChange={(sachThird) =>
              setBusinessArea((old) => ({ ...old, sachThird }))
            }
            label={t("pages.businessArea.edit.sachThird")}
          />
          <Input
            type="text"
            value={businessArea.vu}
            onChange={(vu) => setBusinessArea((old) => ({ ...old, vu }))}
            label={t("pages.businessArea.edit.vu")}
          />
          <Input
            type="text"
            value={businessArea.taxIdInner}
            onChange={(taxIdInner) =>
              setBusinessArea((old) => ({ ...old, taxIdInner }))
            }
            label={t("pages.businessArea.edit.taxIdInner")}
          />
          <Input
            type="text"
            value={businessArea.taxIdEu}
            onChange={(taxIdEu) =>
              setBusinessArea((old) => ({ ...old, taxIdEu }))
            }
            label={t("pages.businessArea.edit.taxIdEu")}
          />
          <Input
            type="text"
            value={businessArea.taxIdThird}
            onChange={(taxIdThird) =>
              setBusinessArea((old) => ({ ...old, taxIdThird }))
            }
            label={t("pages.businessArea.edit.taxIdThird")}
          />
          <Input
            type="text"
            value={businessArea.taxIdVu}
            onChange={(taxIdVu) =>
              setBusinessArea((old) => ({ ...old, taxIdVu }))
            }
            label={t("pages.businessArea.edit.taxIdVu")}
          />
        </div>
      </Box>
    </form>
  );
};
