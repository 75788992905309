import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Right, useData } from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { NotAllowed } from "../../components/NotAllowed";
import { useUser } from "../../components/UserContext";
import {
  convertUserRolesIntoTableEntries,
  isUserAllowedToDo,
} from "../../utils/user/User.utils";

const UserRoleOverview: React.FC = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: loadedRoles } = useData("ROLES_ALL", {
    config: { fallbackData: [] },
  });

  // Holds tableRows for userRoles
  const rows: TableRow[] = useMemo(
    (): TableRow[] => convertUserRolesIntoTableEntries(loadedRoles, navigate),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, loadedRoles]
  );

  return isUserAllowedToDo(user.right, Right.USERROLE_OVERVIEW) ? (
    <>
      <TopBar title={t("pages.userRole.overview.headline")}>
        {isUserAllowedToDo(user.right, Right.USERROLE_CREATE) && (
          <PlusIcon width={40} onClick={() => navigate("/roles/create")} />
        )}
      </TopBar>
      <Box>
        <Table
          rows={rows}
          header={
            t("pages.userRole.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  ) : (
    <NotAllowed neccessaryRight={Right.USERROLE_OVERVIEW} />
  );
};

export default UserRoleOverview;
