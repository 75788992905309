import { AxiosInstance } from "axios";
import { Branche } from "../customer/Customer.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import { toast } from "react-toastify";

/**
 * API method to get all branches
 * @param axios instance of axios
 * @returns Array of all branches
 */
export const getAllBranches = async (
  axios: AxiosInstance
): Promise<Branche[]> => {
  return axios
    .get("/branche/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all branches", exc);
      return [];
    });
};

/**
 * Util method to create a new branche
 * @param axios instance of axios
 * @param branche branche to save onto the database
 * @returns  Promise with the created branche or undefined
 */
export const createNewBranche = async (
  axios: AxiosInstance,
  branche: Branche
): Promise<Branche | undefined> => {
  return axios
    .post("/branche/", branche)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating new branche", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewBranch"),
      });
    });
};

/**
 * API Method to update a branche
 * @param axios instance of axios
 * @param branche branch to update
 * @returns  updated Branch or undefined on error
 */
export const updateBranche = async (
  axios: AxiosInstance,
  branche: Branche
): Promise<Branche | undefined> => {
  return axios
    .post("/branche/updated", branche)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while updating branche", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateBranche"),
      });
    });
};

/**
 * API method to count the instances which will be affected by the branche
 * deletion
 *
 * @param brancheId The id which will get deleted
 * @param axios The axios instance
 * @returns The amount of models that will be affected by the deletion
 */
export const countBrancheDeleteAffectedEntities = async (
  brancheId: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .get("/branche/delete/request", { params: { brancheId } })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during branche delete request call", exc);
      toast(i18n.t("general.notification.error.brancheDeleteCount"), {
        type: "error",
      });
      return -1;
    });
};

/**
 * API method to completly delete the branche
 *
 * @param brancheId The id of the instance to delete
 * @param axios The axios instance
 */
export const deleteBranche = async (
  brancheId: string,
  axios: AxiosInstance
): Promise<void> => {
  await axios
    .post("/branche/delete", brancheId, {
      headers: { "Content-Type": "text/plain" },
    })
    .catch((exc) => {
      console.error("Error during branche delete", exc);
      toast(i18n.t("general.notification.error.brancheDelete"), {
        type: "error",
      });
    });
};
