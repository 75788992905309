import { useEffect, useState } from "react";
import { getActiveLockDate, LockDate } from "shared";
import { useUser } from "../../components/UserContext";

export const useLockDate = (): Date | undefined => {
  const [activeLockDate, setActiveLockDate] = useState<LockDate>();
  const { axios } = useUser();

  useEffect(() => {
    getActiveLockDate(axios).then((loadedDate) =>
      setActiveLockDate(loadedDate)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return activeLockDate?.date;
};
