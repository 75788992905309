import dayjs from "dayjs";
import { useMemo } from "react";

interface DateHeaderProps {
  startDate: dayjs.Dayjs;
  projectTitle: string;
}

export const DateHeader: React.FC<DateHeaderProps> = ({
  startDate,
  projectTitle,
}) => {
  /**
   * Holds the actual dates to display in the header
   */
  const entries: dayjs.Dayjs[] = useMemo(() => {
    const endDate: dayjs.Dayjs = startDate.add(4, "day");
    const targetArray: dayjs.Dayjs[] = [];
    for (
      let currDate = startDate;
      currDate.isBefore(endDate) || currDate.isSame(endDate);
      currDate = currDate.add(1, "day")
    )
      targetArray.push(currDate);
    return targetArray;
  }, [startDate]);

  return (
    <div className="project-planning__header-wrapper">
      <div className="project-planning__header-wrapper__header">
        <p className="project-planning__header-wrapper__header__offer">
          {projectTitle}
        </p>
        {entries.map((entry) => (
          <p
            className={`project-planning__header-wrapper__header__date ${
              entry.day() === 6 || entry.day() === 0 ? "weekend-row" : ""
            }`}
          >
            {entry.format("dd, DD.MM.")}
          </p>
        ))}
      </div>
    </div>
  );
};
