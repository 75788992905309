import { Box, Checkbox, Input, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Department, generateNotification } from "shared";
import {
  createNewDepartment,
  updateDepartment,
} from "shared/src/department/Department.axios";
import { NotificationType } from "shared/src/notification/notification.types";
import { useUser } from "../../components/UserContext";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { generateTableRowForLanguageTranslation } from "../../utils/InputUtils";
import { generateEmptyDepartment } from "../../utils/department/Department.utils";

export const DepartmentEdit: React.FC = () => {
  const { axios } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation<{ department?: Department }>();
  const [departmentToEdit, setDepartmentToEdit] = useState<Department>(
    location.state?.department
      ? location.state.department
      : generateEmptyDepartment()
  );

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  /**
   * Rows to show and edit the different translations
   */
  const languageNameRows: TableRow[] = useMemo(
    (): TableRow[] =>
      generateTableRowForLanguageTranslation(
        departmentToEdit,
        "names",
        setDepartmentToEdit
      ),
    [departmentToEdit]
  );

  /**
   * Util method to update or create a department on submit
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    departmentToEdit.id
      ? updateDepartment(axios, departmentToEdit).then((updatedDepartment) => {
          if (redirect && updatedDepartment) navigate("/department");
          else if (updatedDepartment) {
            setDepartmentToEdit(updatedDepartment);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.saveSuccessfull"),
            });
          }
        })
      : createNewDepartment(axios, departmentToEdit).then(
          (createdDepartment) => {
            if (redirect && createdDepartment) navigate("/department");
            else if (createdDepartment) {
              setDepartmentToEdit(createdDepartment);
              generateNotification({
                type: NotificationType.SUCCESS,
                value: t("general.notification.success.saveSuccessfull"),
              });
            }
          }
        );
  };

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          departmentToEdit.id
            ? t("pages.department.edit.topBarHeadlineEdit")
            : t("pages.department.edit.topBarHeadlineCreate")
        }
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      <Box>
        <Input
          maxWidth={350}
          required
          type="text"
          value={departmentToEdit.title}
          label={t("pages.department.edit.title")}
          onChange={(title) =>
            setDepartmentToEdit({ ...departmentToEdit, title })
          }
        />
        <Table
          rows={languageNameRows}
          header={
            t("pages.department.edit.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
        <Checkbox
          isChecked={departmentToEdit.disabled}
          onCheck={() =>
            setDepartmentToEdit({
              ...departmentToEdit,
              disabled: !departmentToEdit.disabled,
            })
          }
          label={t("pages.department.edit.disabled")}
        />
      </Box>
    </form>
  );
};
