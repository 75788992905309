import { AxiosInstance } from "axios";
import { Department } from "./Department.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import { mapReplacer } from "..";

/**
 * API method to get all departments
 * @param axios instance of axios
 * @returns  array of all found workQaulifications
 */
export const getAllDepartments = async (
  axios: AxiosInstance
): Promise<Department[]> => {
  return axios
    .get("/department/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading departments", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllDepartments"),
      });
      return [];
    });
};

/**
 * API method to create a new department
 * @param axios instance of axios
 * @param department department to save
 * @returns  created department or undefined on error
 */
export const createNewDepartment = async (
  axios: AxiosInstance,
  department: Department
): Promise<Department | undefined> => {
  return axios
    .post("/department/", JSON.stringify(department, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating department", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewDepartment"),
      });
    });
};

/**
 * API method to update a workdepartment
 * @param axios instance of axios
 * @param department updated department
 * @returns  updated department or undefined
 */
export const updateDepartment = async (
  axios: AxiosInstance,
  department: Department
): Promise<Department | undefined> => {
  return axios
    .post("/department/update", JSON.stringify(department, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while updating department", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateDepartment"),
      });
    });
};
