import { Option, TableRow } from "@sam/components";
import { BusinessArea } from "shared";
import i18n from "../../i18n/i18n";
import { NavigateFunction } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";

/**
 * Util method to generateDropdownOptions for businessAreas
 * @param areas  to generate the DropdownOptions for
 * @returns  Array of Options
 */
export const generateDropdownOptionsForBusinessArea = (
  areas: BusinessArea[]
): Option[] => areas.map((area) => ({ label: area.name, value: area.id }));

/**
 * util method to generate an empty businessArea
 * @param override partial BusinessArea to adjust the created object
 * @returns generated BusinessArea
 */
export const generateEmptyBusinessArea = (
  override?: Partial<BusinessArea>
): BusinessArea => ({
  createDate: new Date(),
  createdBy: "",
  disabled: false,
  id: undefined!,
  name: "",
  numberRangeValue: 0,
  companyId: "",
  kstNr: "",
  sachEu: "",
  sachInner: "",
  sachThird: "",
  taxIdEu: "",
  taxIdInner: "",
  taxIdThird: "",
  taxIdVu: "",
  vu: "",
  ...override,
});

/**
 * Util method to convert BusinessAreas into tableEntries
 * @param areas array of BusinessAreas
 * @param navigate NavigateFunction to navigate to edit
 * @returns  array of TableRows
 */
export const convertBusinessAreasIntoTableEntries = (
  areas: BusinessArea[],
  navigate: NavigateFunction
): TableRow[] =>
  areas.map((area) => ({
    id: area.id,
    onClick: () =>
      navigate("/businessArea/edit", { state: { businessArea: area } }),
    content: [
      area.createDate.toLocaleDateString("DE-de"),
      area.name,
      area.numberRangeValue,
      area.disabled
        ? i18n.t("pages.businessArea.overview.disabled")
        : i18n.t("pages.businessArea.overview.active"),
      <div>
        <EditIcon
          width={30}
          onClick={(evt) => {
            evt.stopPropagation();
            navigate("/businessArea/edit", { state: { businessArea: area } });
          }}
        />
      </div>,
    ],
  }));
