/**
 * Attributes for all InventoryItems that are extended by the different item
 * classes
 */
export interface InventoryItem {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdate?: string;
  updatedBy?: string;
  disabled?: boolean;
  name: string;
  type: InventoryType;
  number: number;
  note?: string;
  officeId: string;
  currentUserId: string; 
  userHistory: Map<Date, string>; 
  producent?: string;
  serialNumber?: string;
  customDate?: Date;
  active: boolean;
}

/**
 * Different types of inventory items to categorize them
 */
export enum InventoryType {
  PC = "PC",
  LAPTOP = "LAPTOP",
  HANDY = "HANDY",
  PHONE_CONTRACT = "PHONE_CONTRACT",
  MISC_TRANSPORT = "MISC_TRANSPORT",
  MEASURE = "MEASURE",
  MONITOR = "MONITOR",
  PHONE = "PHONE",
  MISC = "MISC",
}

/**
 * Defines the differend groupings for toolings
 */
export enum ToolingGroup {
  CHECK = "CHECK",
  TESTING = "TESTING",
  MISC = "MISC",
}

/**
 * Defines the differend types of toolings
 */
export enum ToolingType {
  MESSCHIEBER = "MESSCHIEBER",
  ENDMASS = "ENDMASS",
  GRENZLEHRDORN = "GRENZLEHRDORN",
  DREHMOMENT = "DREHMOMENT",
  MESSUHR = "MESSUHR",
  MESSTASTER = "MESSTASTER",
  MIKROSKOP = "MIKROSKOP",
  WAAGE = "WAAGE",
  PRUFSTIFT = "PRUFSTIFT",
  SONSTIGES = "SONSTIGES",
}

/**
 * The inventory item implementation for handies
 */
export interface InventoryHandy extends InventoryItem {
  pin?: string;
  supplier?: string;
  priceBrutto?: number;
  withCharge?: boolean;
  withCase?: boolean;
  withFoil?: boolean;
}

/**
 * The inventory item implementation for laptops
 */
export interface InventoryLaptop extends InventoryItem {
  cpu?: string;
  ram?: string;
  hasGPU?: boolean;
  hasLTE?: boolean;
}

/**
 * The inventory item implementation for measure equipment
 */
export interface InventoryMeasure extends InventoryItem {
  approved?: boolean;
  holder?: string;
  group?: ToolingGroup;
  toolType?: ToolingType;
}

/**
 * The inventory item implementation for misc stuff
 */
export interface InventoryMisc extends InventoryItem {
  assetClass?: string;
  assetNumber?: string;
  price?: number;
}

/**
 * The inventory item implementation for misc transport items
 */
export interface InventoryMiscTransport extends InventoryItem {
  assetClass?: string;
  assetNumber?: string;
  price?: number;
}

/**
 * The inventory item implementation for monitors
 */
export interface InventoryMonitor extends InventoryItem {
  size?: number;
}

/**
 * The inventory item implementation for pcs
 */
export interface InventoryPc extends InventoryItem {
  cpu?: string;
  withKeyboard?: boolean;
  supportMail?: string;
}

/**
 * The inventory item implementation for phones
 */
export interface InventoryPhone extends InventoryItem {}

/**
 * The inventory item implementation for phone contracts
 */
export interface InventoryPhoneContract extends InventoryItem {
  phoneNumber?: string;
  contract?: string;
  begin?: Date;
  frameworkContract?: string;
  customerNumber?: string;
  customerAccount?: string;
  simNumber?: string;
  pin?: string;
  monthlyCosts?: number;
}

/**
 * Collection of all interfaces in one type for convenience reasons
 */
export type AllInventoryInterfaces =
  | InventoryHandy
  | InventoryLaptop
  | InventoryMeasure
  | InventoryMisc
  | InventoryMiscTransport
  | InventoryMonitor
  | InventoryPc
  | InventoryPhone
  | InventoryPhoneContract;
