import {
  Box,
  Button,
  Dropdown,
  Popup,
  Table,
  TableRow,
  TopBar,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Offer,
  OfferState,
  Order,
  Right,
  generateOfferCopy,
  useData,
} from "shared";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { convertOffersIntoTableEntries } from "../../utils/order/Order.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

export const OfferOverview: React.FC = () => {
  const { axios, user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedStates, setSelectedStates] = useState<OfferState[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");

  const [selectedBusinessAreaId, setSelectedBusinessAreaId] =
    useState<string>();

  const { data: loadedCustomers } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedOffers } = useData("OFFER_ALL", {
    config: { fallbackData: [] },
  });

  const { data: loadedBusinessAreas } = useData("BUSINESSAREA_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerContacts } = useData("CUSTOMER_USERS_ALL", {
    config: { fallbackData: [] },
  });

  const [offerToCopy, setOfferToCopy] = useState<string>();
  /**
   * Helper method to copy an existing offer
   * @param offerId id of the offer to copy
   */
  const copyOffer = useCallback(
    (offerId: string): void => {
      generateOfferCopy(axios, offerId).then(
        (generatedOffer) =>
          generatedOffer &&
          navigate("/offer/create", { state: { offer: generatedOffer } })
      );
    },
    [axios, navigate]
  );

  //Hook to set tableRows everytime the offers or the filters change
  const rows: TableRow[] = useMemo((): TableRow[] => {
    let filteredOffers: Offer[] = loadedOffers;
    if (selectedStates.length > 0) {
      filteredOffers = filteredOffers.filter((offer) =>
        selectedStates.includes(offer.offerState)
      );
    }
    if (selectedBusinessAreaId) {
      filteredOffers = filteredOffers.filter((offer) => {
        const order: Order | undefined = loadedOrders.find(
          (order) => order.id === offer.orderId
        );
        return order && selectedBusinessAreaId === order.businessAreaId;
      });
    }
    return convertOffersIntoTableEntries(
      filteredOffers,
      loadedOrders,
      loadedCustomers,
      loadedCustomerLocations,
      loadedCustomerContacts,
      navigate,
      setOfferToCopy
    );
  }, [
    loadedCustomerContacts,
    loadedCustomerLocations,
    loadedCustomers,
    loadedOffers,
    loadedOrders,
    navigate,
    selectedBusinessAreaId,
    selectedStates,
  ]);

  return (
    <>
      <TopBar
        title={t("pages.order.overview.topBarHeadline")}
        onSearch={setFilterValue}
      >
        {isUserAllowedToDo(user.right, Right.OFFER_CREATE) && (
          <AddIcon onClick={() => navigate("/offer/create")} width={30} />
        )}
      </TopBar>
      <Popup
        isOpen={!!offerToCopy}
        buttons={[
          <Button
            value={t("general.buttons.cancel")}
            onClick={() => setOfferToCopy(undefined)}
          />,
          <Button
            value={t("general.buttons.yes")}
            onClick={() => copyOffer(offerToCopy!)}
          />,
        ]}
      >
        <p>{t("pages.order.overview.copyPopup")}</p>
      </Popup>
      <Box>
        <div className="three-columns">
          <Dropdown
            multi
            onChangeMultiple={(state) =>
              setSelectedStates(state as OfferState[])
            }
            options={Object.values(OfferState).map((state) => ({
              label: t(`pages.order.overview.offerState.${state}`),
              value: state,
            }))}
          />
          {loadedBusinessAreas.map((area) => (
            <Button
              value={area.name}
              active={selectedBusinessAreaId === area.id}
              onClick={() =>
                selectedBusinessAreaId === area.id
                  ? setSelectedBusinessAreaId(undefined)
                  : setSelectedBusinessAreaId(area.id)
              }
            />
          ))}
        </div>
      </Box>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.order.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
