/* eslint-disable @typescript-eslint/ban-types */
import dayjs from "dayjs";
import { getAllAbsences } from "../absence/Absence.axios";
import { getAllAbsenceReasons } from "../absenceReason/AbsenceReason.axios";
import { getAllBikes } from "../bike/Bike.axios";
import { getAllBranches } from "../branche/Branche.axios";
import { getAllCars, getAllSchedulesForCar } from "../car/Car.axios";
import { getAllChanges } from "../changeHistory/ChangeHistory.axios";
import { getAllCompanies } from "../company/Company.axios";
import { getAllCurrencies } from "../currency/Currency.axios";
import {
  getAllCustomerUsers,
  getAllCustomers,
} from "../customer/Customer.axios";
import { getAllCustomerFeedbackEntries } from "../customerFeedbackEntry/CustomerFeedbackEntry.axios";
import { getAllCustomerLocations } from "../customerLocation/CustomerLocation.axios";
import {
  getAllHolidays,
  getAllHolidaysForUser,
  getAllSubordinateHolidayRequests,
} from "../holiday/Holiday.axios";
import { getAllPaymentConditions } from "../invoice/Invoice.axios";
import { getAllOffers } from "../offer/Offer.axios";
import { getAllOffices, getOfficeById } from "../office/Office.axios";
import { getAllOfficialHolidays } from "../officialHoliday/OfficialHoliday.axios";
import { getAllNumberRanges, getAllOrders } from "../order/Order.axios";
import {
  getAllActiveProjects,
  getAllProjectProtocols,
  getAllProjectSchedulesForStartDate,
} from "../project/Project.axios";
import { getAllStandardTasks, getAllTasks } from "../tasks/Task.axios";
import { getAllTimeTrackingsByUserId } from "../time/TimeTracking.axios";
import {
  getAllSchedulesForUser,
  loadAllSchambeckUsers,
  loadAllSimpleUsers,
} from "../user/User.axios";
import { getAllUserRoles } from "../userRole/UserRole.axios";
import { getAllWorkQualifications } from "../workQualification/WorkQualification.axios";
import { DataFetchers } from "./data.types";
import { getAllDunningLevels } from "../dunningLevel/DunningLevel.axios";
import { getAllBusinessAreas } from "../businessArea/BusinessArea.axios";
import { getAllUserLanguages } from "../userLanguage/UserLanguage.axios";
import { getAllWorkEquipmentEntries } from "../workEquipment/WorkEquipment.axios";
import { getAllUserFunctions } from "../userFunction/UserFunction.axios";
import { getAllDepartments } from "../department/Department.axios";
import { getAllPdls } from "../pdl/Pdl.axios";
import {
  getAllCrmActions,
  getAllCrmEntries,
  getAllCrmTypes,
} from "../crm/Crm.axios";
import { getAllCustomerArticles } from "../customerArticle/CustomerArticle.axios";
import { OfferType } from "../offer/Offer.types";

/**
 * Defines all data types which can be fetched as keys (which can be used in the `useData` hook).
 * The value is the dependency for the data to be fetched.
 *
 * Example: data type `USERS_BY_CUSTOMER` needs a `customerUid` to be fetched, so the value is { customerUid: string }
 *
 * Axios and the current user are always passed to the fetcher function.
 */
export type Data = {
  OFFICES_ALL: {} | undefined;
  OFFICES_ID: { officeId: string };
  SCHAMBECK_USERS_ALL: {} | undefined;
  TIMETRACKING_ALL_USER: { userId: string };
  OFFICIAL_HOLIDAYS_ALL: {} | undefined;
  HOLIDAYS_ALL: {} | undefined;
  HOLIDAYS_ALL_USER: { userId: string };
  HOLIDAYS_ALL_SUBS: { userId: string };
  ABSENCE_REASONS_ALL: {} | undefined;
  ROLES_ALL: {} | undefined;
  CARS_ALL: {} | undefined;
  BIKES_ALL: {} | undefined;
  SIMPLEUSERS_ALL: {} | undefined;
  TASKS_ALL: {} | undefined;
  TASKS_ALL_STANDARD: {} | undefined;
  CUSTOMER_ALL: {} | undefined;
  BRANCHES_ALL: {} | undefined;
  CUSTOMER_USERS_ALL: {} | undefined;
  ABSENCE_ALL: {} | undefined;
  CUSTOMER_LOCATION_ALL: {} | undefined;
  ORDER_ALL: {} | undefined;
  CURRENCY_ALL: {} | undefined;
  NUMBER_RANGE_ALL: {} | undefined;
  PROJECT_ALL: {} | undefined;
  OFFER_ALL: {} | undefined;
  PROJECT_PLANNING_ALL: { startDate: dayjs.Dayjs };
  QUALIFICATIONS_ALL: {} | undefined;
  COMPANY_ALL: {} | undefined;
  PAYMENT_CONDITION_ALL: {} | undefined;
  CHANGE_HISTORY_ALL: {} | undefined;
  FEEDBACK_ALL: {} | undefined;
  WORK_EQUIPMENT_ALL: {} | undefined;
  DUNNING_ALL: {} | undefined;
  BUSINESSAREA_ALL: {} | undefined;
  USER_LANGUAGE_ALL: {} | undefined;
  PROTOCOL_ALL: {} | undefined;
  USER_FUNCTION_ALL: {} | undefined;
  DEPARTMENT_ALL: {} | undefined;
  PDL_ALL: {} | undefined;
  CRM_TYPES_ALL: {} | undefined;
  CRM_ACTIONS_ALL: {} | undefined;
  CRM_ENTRIES_ALL: {} | undefined;
  SCHEDULE_USER_ALL: { referenceId: string };
  SCHEDULE_CAR_ALL: { referenceId: string };
  CUSTOMER_ARTICLE_ALL: {} | undefined;
  CUSTOMER_PROJECT: {} | undefined;

  /**
   * Can be used to load no data.
   *
   * example: `useData(hasRole(user, UserRole.ADMIN) ? "SOME_DATA": "NONE")`
   */
  NONE: undefined;
};

/**
 * Defines all data fetchers (a async function which returns the desired data).
 *
 * The key is the data type which can be used in the `useData` hook.
 * The value is a object containing the key (swr cache key) and the fetcher function.
 *
 * The fetcher function has a first parameter which is the in `Data` defined dependencies
 * and a second parameter with the axios instance and the current user.
 */
export const dataFetchers = {
  OFFICES_ALL: {
    key: "/offices/all",
    fetcher: (_, { axios }) => getAllOffices(axios),
  },
  OFFICES_ID: {
    key: "/offices/all",
    fetcher: (params, { axios }) => getOfficeById(axios, params.officeId),
  },
  SCHAMBECK_USERS_ALL: {
    key: "/users/all",
    fetcher: (_, { axios }) => loadAllSchambeckUsers(axios),
  },
  TIMETRACKING_ALL_USER: {
    key: "/timetracking/all/user",
    fetcher: (params, { axios }) =>
      getAllTimeTrackingsByUserId(axios, params.userId),
  },
  OFFICIAL_HOLIDAYS_ALL: {
    key: "/officialholidays/all",
    fetcher: (_, { axios }) => getAllOfficialHolidays(axios),
  },
  HOLIDAYS_ALL: {
    key: "holidays/all",
    fetcher: (_, { axios }) => getAllHolidays(axios),
  },
  HOLIDAYS_ALL_USER: {
    key: "holidays/all/user",
    fetcher: (params, { axios }) => getAllHolidaysForUser(params.userId, axios),
  },
  HOLIDAYS_ALL_SUBS: {
    key: "holidays/all/subs",
    fetcher: (params, { axios }) =>
      getAllSubordinateHolidayRequests(axios, params.userId),
  },
  ABSENCE_REASONS_ALL: {
    key: "absence/reason/all",
    fetcher: (_, { axios }) => getAllAbsenceReasons(axios),
  },
  ROLES_ALL: {
    key: "roles/",
    fetcher: (_, { axios }) => getAllUserRoles(axios),
  },
  CARS_ALL: {
    key: "car/",
    fetcher: (_, { axios }) => getAllCars(axios),
  },
  BIKES_ALL: {
    key: "bike/",
    fetcher: (_, { axios }) => getAllBikes(axios),
  },
  SIMPLEUSERS_ALL: {
    key: "user/simple/all",
    fetcher: (_, { axios }) => loadAllSimpleUsers(axios),
  },
  TASKS_ALL: { key: "task/all", fetcher: (_, { axios }) => getAllTasks(axios) },
  TASKS_ALL_STANDARD: {
    key: "task/all/standard",
    fetcher: (_, { axios }) => getAllStandardTasks(axios),
  },
  CUSTOMER_ALL: {
    key: "customer/all",
    fetcher: (_, { axios }) => getAllCustomers(axios),
  },
  BRANCHES_ALL: {
    key: "branch/all",
    fetcher: (_, { axios }) => getAllBranches(axios),
  },
  CUSTOMER_USERS_ALL: {
    key: "customer/user",
    fetcher: (_, { axios }) => getAllCustomerUsers(axios),
  },
  ABSENCE_ALL: {
    key: "absence/all",
    fetcher: (_, { axios }) => getAllAbsences(axios),
  },
  CUSTOMER_LOCATION_ALL: {
    key: "customer/location/all",
    fetcher: (_, { axios }) => getAllCustomerLocations(axios),
  },
  ORDER_ALL: {
    key: "order/all",
    fetcher: (_, { axios }) => getAllOrders(axios),
  },
  CURRENCY_ALL: {
    key: "currency/all",
    fetcher: (_, { axios }) => getAllCurrencies(axios),
  },
  NUMBER_RANGE_ALL: {
    key: "numberRanges/all",
    fetcher: (_, { axios }) => getAllNumberRanges(axios),
  },
  PROJECT_ALL: {
    key: "project/all",
    fetcher: (_, { axios }) => getAllActiveProjects(axios),
  },
  OFFER_ALL: {
    key: "offer/all",
    fetcher: (_, { axios }) => getAllOffers(axios),
  },
  PROJECT_PLANNING_ALL: {
    key: "project/planning/all",
    fetcher: (params, { axios }) =>
      getAllProjectSchedulesForStartDate(params.startDate, axios),
  },
  QUALIFICATIONS_ALL: {
    key: "qualification/all",
    fetcher: (_, { axios }) => getAllWorkQualifications(axios),
  },
  COMPANY_ALL: {
    key: "company/all",
    fetcher: (_, { axios }) => getAllCompanies(axios),
  },
  PAYMENT_CONDITION_ALL: {
    key: "payment/all",
    fetcher: (_, { axios }) => getAllPaymentConditions(axios),
  },
  CHANGE_HISTORY_ALL: {
    key: "changes/all",
    fetcher: (_, { axios }) => getAllChanges(axios),
  },
  FEEDBACK_ALL: {
    key: "customer/feedback/all",
    fetcher: (_, { axios }) => getAllCustomerFeedbackEntries(axios),
  },
  WORK_EQUIPMENT_ALL: {
    key: "workequipment/all",
    fetcher: (_, { axios }) => getAllWorkEquipmentEntries(axios),
  },
  DUNNING_ALL: {
    key: "dunning/all",
    fetcher: (_, { axios }) => getAllDunningLevels(axios),
  },
  BUSINESSAREA_ALL: {
    key: "businessarea/all",
    fetcher: (_, { axios }) => getAllBusinessAreas(axios),
  },
  USER_LANGUAGE_ALL: {
    key: "language/all",
    fetcher: (_, { axios }) => getAllUserLanguages(axios),
  },
  PROTOCOL_ALL: {
    key: "project/protocol/all",
    fetcher: (_, { axios }) => getAllProjectProtocols(axios),
  },
  USER_FUNCTION_ALL: {
    key: "user/function/all",
    fetcher: (_, { axios }) => getAllUserFunctions(axios),
  },
  DEPARTMENT_ALL: {
    key: "department/all",
    fetcher: (_, { axios }) => getAllDepartments(axios),
  },
  PDL_ALL: {
    key: "pdl/all",
    fetcher: (_, { axios }) => getAllPdls(axios),
  },
  CRM_ACTIONS_ALL: {
    key: "crm/actions/all",
    fetcher: (_, { axios }) => getAllCrmActions(axios),
  },
  CRM_ENTRIES_ALL: {
    key: "crm/entries/all",
    fetcher: (_, { axios }) => getAllCrmEntries(axios),
  },
  CRM_TYPES_ALL: {
    key: "crm/types/all",
    fetcher: (_, { axios }) => getAllCrmTypes(axios),
  },
  SCHEDULE_USER_ALL: {
    key: "schedules/user/all",
    fetcher: (params, { axios }) =>
      getAllSchedulesForUser(params.referenceId, axios),
  },
  SCHEDULE_CAR_ALL: {
    key: "schedules/car/all",
    fetcher: (params, { axios }) =>
      getAllSchedulesForCar(params.referenceId, axios),
  },
  CUSTOMER_ARTICLE_ALL: {
    key: "customer/articles/all",
    fetcher: (_, { axios }) => getAllCustomerArticles(axios),
  },
  CUSTOMER_PROJECT: {
    key: "customer/project",
    fetcher: (_, { axios }) => getAllOffers(axios, OfferType.CUSTOMER_PROJECT),
  },
  NONE: {
    key: "none",
    fetcher: () => Promise.resolve(undefined),
  },
} satisfies DataFetchers<Data>;
