import { KeycloakInitOptions } from "keycloak-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App.tsx";
import i18n from "./i18n/i18n.ts";
import "./styles/index.scss";
import "./styles/pages.scss";

// add destyling used to reset browser styles
import { ReactKeycloakProvider } from "@react-keycloak/web";
import "destyle.css/destyle.min.css";
import { ToastContainer } from "react-toastify";
import { initEnvironment } from "shared/src/environment.ts";
import keycloak from "./keycloak.ts";

initEnvironment({
  i18n,
  selectedOffices: [],
});

const keycloakProviderInitConfig: KeycloakInitOptions = {
  onLoad: "check-sso",
  enableLogging: true,
  checkLoginIframe: false,
  silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ReactKeycloakProvider
    authClient={keycloak}
    LoadingComponent={<h2>TODO KC LOADER</h2>}
    initOptions={keycloakProviderInitConfig}
  >
    <ToastContainer />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ReactKeycloakProvider>
);
