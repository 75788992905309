import { TableRow } from "@sam/components";
import { Language } from "shared/src/Language/Language.types";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { NavigateFunction } from "react-router-dom";
import { WorkEquipment } from "shared";

/**
 * Util method to convert workEquipment into tableRows
 * @param entries to convert
 * @param navigate navigateFunction to handle edit click
 * @returns Array of TableRows
 */
export const convertWorkEquipmentIntoTableEntries = (
  entries: WorkEquipment[],
  navigate: NavigateFunction
): TableRow[] => {
  return entries.map((entry) => ({
    id: entry.id,
    onClick: () => navigate("edit", { state: { entry } }),
    content: [
      entry.title,
      entry.createDate
        ? new Date(entry.createDate).toLocaleDateString("de-DE")
        : "-",
      entry.lastUpdate
        ? new Date(entry.lastUpdate).toLocaleDateString("de-DE")
        : "-",
      <EditIcon
        width={30}
        onClick={(evt) => {
          evt.stopPropagation();
          navigate("edit", { state: { entry } });
        }}
      />,
    ],
  }));
};

/**
 * Util method to generate an empty workEquipment
 * @param override partial work equipment entry to adjust the created object
 * @returns  created workEquipment
 */
export const generateEmptyWorkEquipment = (
  override?: Partial<WorkEquipment>
): WorkEquipment => ({
  id: undefined!,
  createDate: new Date(),
  lastUpdate: new Date(),
  createdBy: undefined!,
  disabled: false,
  names: new Map<Language, string>(),
  title: "",
  updatedBy: undefined,
  ...override,
});
