import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PaymentCondition, disablePaymentCondition, useData } from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { convertPaymentConditionsIntoTableEntries } from "../../utils/invoice/Invoice.utils";
import { useUser } from "../../components/UserContext";

export const PaymentConditionOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios } = useUser();
  const [paymentConditions, setPaymentConditions] = useState<
    PaymentCondition[]
  >([]);
  const { data: loadedPaymentConditions } = useData("PAYMENT_CONDITION_ALL", {
    config: { fallbackData: [] },
  });

  // Hook to set paymentConditions after they are loaded
  useEffect(
    () => setPaymentConditions(loadedPaymentConditions),
    [loadedPaymentConditions]
  );

  /**
   * Helper method to deactivate paymentConditions
   * @param id of the condition to deactivate
   */
  const handleDisablePaymentCondition = useCallback(
    (id: string): void => {
      disablePaymentCondition(axios, id).then((disabledCondition) => {
        if (disabledCondition)
          setPaymentConditions((prevCondition) =>
            prevCondition.filter((condition) => condition.id !== id)
          );
      });
    },
    [axios]
  );
  /**
   * useMemo to keep the tableRows up to date
   */
  const rows: TableRow[] = useMemo(
    () =>
      paymentConditions
        ? convertPaymentConditionsIntoTableEntries(
            paymentConditions,
            navigate,
            handleDisablePaymentCondition
          )
        : [],
    [handleDisablePaymentCondition, navigate, paymentConditions]
  );

  return (
    <>
      <TopBar title={t("pages.paymentCondition.overview.topBarHeadline")}>
        <PlusIcon width={30} onClick={() => navigate("/payment/edit")} />
      </TopBar>
      <Box>
        <Table
          rows={rows}
          header={
            t("pages.paymentCondition.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
