import { TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import { UserLanguage } from "shared/src/userLanguage/UserLanguage.types";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { Language } from "shared/src/Language/Language.types";

/**
 * Helper method to generate an empty userLanguage
 * @param override partial UserLanguage
 * @returns  generated UserLanguage
 */
export const generateEmptyUserLanguage = (
  override?: Partial<UserLanguage>
): UserLanguage => ({
  id: undefined!,
  createDate: new Date(),
  createdBy: "",
  name: "",
  names: new Map<Language, string>(),
  ...override,
});

/**
 * Util method to convert UserLanguages into TableRows
 * @param languages to convert
 * @param navigate to go to the edit page
 * @returns
 */
export const convertUserLanguagesIntoTableRows = (
  languages: UserLanguage[],
  navigate: NavigateFunction
): TableRow[] => {
  return languages.map((language) => ({
    id: language.id,
    onClick: () => navigate("/language/edit", { state: { language } }),
    content: [
      language.name,
      language.createDate
        ? new Date(language.createDate).toLocaleDateString("de-DE")
        : "-",
      language.lastUpdate
        ? new Date(language.lastUpdate).toLocaleDateString("DE-de")
        : "-",
      <div>
        <EditIcon
          width={30}
          onClick={() => navigate("/language/edit", { state: { language } })}
        />
      </div>,
    ],
  }));
};
