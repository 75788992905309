import { Button } from "@sam/components";

import { ReactComponent as ArrowLeft } from "../../assets/chevron-left.svg";
import { ReactComponent as SaveIcon } from "../../assets/save.svg";

interface SaveButtonProps {
  handleSubmit: (redirect: boolean) => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
}
export const SaveButtons: React.FC<SaveButtonProps> = ({
  handleSubmit,
  buttonRef,
}) => {
  return (
    <>
      <button type="submit" value={""} ref={buttonRef} className="hidden" />
      <Button
        value={
          <div className="save-and-navigate__wrapper">
            <ArrowLeft className="save-and-navigate__arrow" />
            <SaveIcon className="save-and-navigate__save" />
          </div>
        }
        type="button"
        onClick={() => handleSubmit(true)}
      />
      <Button
        value={<SaveIcon className="save-icon" />}
        type="button"
        onClick={() => handleSubmit(false)}
      />
    </>
  );
};
