import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Option,
  Popup,
  Table,
  TableRow,
  TextArea,
  TopBar,
} from "@sam/components";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";

import { TableHeader } from "@sam/components/src/Table/Table.types";
import {
  createNewOffer,
  createNewOrder,
  Customer,
  CustomerArticle,
  generateDropdownOptions,
  generateDropdownOptionsForLanguage,
  generateNotification,
  getCustomerArticlesForCustomer,
  Language,
  Offer,
  OfferProvidedBy,
  OfferType,
  Office,
  Order,
  PaymentCondition,
  ProjectInvoiceType,
  Task,
  updateOrderAndOffer,
  useData,
} from "shared";
import { CustomerLocation } from "shared/src/customerLocation/CustomerLocation.types";
import { NotificationType } from "shared/src/notification/notification.types";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { SoftLockedPopup } from "../../components/SoftLockedPopup";
import { useUser } from "../../components/UserContext";
import { generateDropdownOptionsForBusinessArea } from "../../utils/businessArea/BusinessArea.utils";
import {
  convertOfferedTasksIntoTableEntries,
  generateDropdownOptionsForCustomers,
  generateDropdownOptionsForCustomerUsers,
  generateEmptyOffer,
  generateEmptyOfferedTask,
  generateEmptyOrder,
} from "../../utils/order/Order.utils";
import { createNewProjectFromCustomerProject } from "shared/src/project/Project.axios";

export const CustomerProjectCreateOrEdit: React.FC = () => {
  const navigate = useNavigate();
  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);
  const { axios } = useUser();
  const location = useLocation<{ order?: Order; offer?: Offer }>();
  const { t } = useTranslation();

  const [customerProject, setCustomerProject] = useState<Offer>(
    location.state?.offer ??
      generateEmptyOffer({ offerType: OfferType.CUSTOMER_PROJECT })
  );
  const [projectOrder, setProjectOrder] = useState<Order>(
    location.state?.order ??
      generateEmptyOrder({ offerType: OfferType.CUSTOMER_PROJECT })
  );
  const [offeredTaskIdToDelete, setOfferedTaskIdToDelete] = useState<string>();
  const [customerArticles, setCustomerArticles] = useState<CustomerArticle[]>(
    []
  );
  //useEffect hook to get the customer specific articles
  useEffect(() => {
    if (!customerProject.customerId) return;
    getCustomerArticlesForCustomer(axios, customerProject.customerId).then(
      setCustomerArticles
    );
  }, [axios, customerProject.customerId]);

  const { data: loadedOffices } = useData("OFFICES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCurrencys } = useData("CURRENCY_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCompanies } = useData("COMPANY_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedBusinessAreas } = useData("BUSINESSAREA_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomers } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerUsers } = useData("CUSTOMER_USERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedPaymentConditions } = useData("PAYMENT_CONDITION_ALL", {
    params: { fallbackData: [] },
  });
  const { data: loadedTasks } = useData("TASKS_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Holds the tasks and the customer specific articles as tasks
   */
  const availableTasks: Task[] = useMemo(
    (): Task[] => [
      ...loadedTasks,
      ...customerArticles.map(
        (article): Task => ({
          id: article.id,
          amount: 1,
          annotation: article.annotation || "",
          description: article.description || "",
          identifier: article.partnumber || "",
          price: article.price || 1,
          standard: false,
          title: article.partnumber || "",
          translations: new Map(),
          unit: article.unit || "",
        })
      ),
    ],
    [loadedTasks, customerArticles]
  );

  //Holds generated TableRows out of offeredTasks
  const rows: TableRow[] = useMemo((): TableRow[] => {
    if (availableTasks.length === 0) return [];
    return convertOfferedTasksIntoTableEntries(
      customerProject.offeredTasks,
      availableTasks,
      setOfferedTaskIdToDelete,
      setCustomerProject
    );
  }, [availableTasks, customerProject.offeredTasks]);

  /**
   * Submit handler to create or update the order and offer
   * @param redirect decides if page should navigate to overview after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;
    if (customerProject.id) {
      updateOrderAndOffer(axios, customerProject, projectOrder).then(
        (updatedObjects) => {
          if (updatedObjects?.order && updatedObjects?.offer && redirect)
            navigate("/customer/project");
          else if (updatedObjects) {
            setCustomerProject(updatedObjects.offer);
            setProjectOrder(updatedObjects.order);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.offerUpdate"),
            });
          }
        }
      );
    } else {
      createNewOrder(axios, projectOrder).then((createdOrder) => {
        if (!createdOrder) return;
        createNewOffer(axios, {
          ...customerProject,
          orderId: createdOrder.id,
        }).then((offer) => {
          if (offer && redirect) navigate("/customer/project");
          else if (offer) {
            setCustomerProject(offer);
            setProjectOrder(createdOrder);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.offerCreate"),
            });
          }
        });
      });
    }
  };

  const [softLockedCustomer, setSoftLockedCustomer] = useState<Customer>();

  /**
   * Helper to update all relevant locations after selecting the central customer
   *
   * @param customerId The selected customer id
   */
  const handleCentralCustomerSelection = (customerId: string): void => {
    const foundCustomer: Customer | undefined = loadedCustomers.find(
      (cust) => cust.id === customerId
    );
    if (foundCustomer?.softLocked) {
      setSoftLockedCustomer(foundCustomer);
    }
    if (!foundCustomer) return;
    setProjectOrder({
      ...projectOrder,
      customerId,
      workingLocation: {
        customerId,
        customerLocationId: "",
        customerContactId: "",
      },
      invoiceRecipient: {
        customerUserId: "",
        customerId,
      },
      paymentCondition: foundCustomer.paymentCondition,
    });
    setCustomerProject({
      ...customerProject,
      customerId,
    });
  };
  /**
   * Helper to fill some fields in respect of the given office id
   *
   * @param officeId The selected id of the office
   */
  const handleOfficeSelection = (officeId: string): void => {
    const selectedOffice: Office | undefined = loadedOffices.find(
      (office) => office.id === officeId
    );
    if (!selectedOffice) return;
    setProjectOrder({
      ...projectOrder,
      officeId,
      companyId: selectedOffice.companyId,
    });
  };

  /*
   * Helper method to find the selected paymentCondition and update the offer
   * @param id of the selected paymentCondition
   */
  const handlePaymentConditionChange = (id: string): void => {
    const condition: PaymentCondition | undefined =
      loadedPaymentConditions?.find((condition) => condition.id === id);
    if (condition)
      setProjectOrder({ ...projectOrder, paymentCondition: condition });
  };

  /**
   * DropdownOptions for the businessAreas
   */
  const businessAreaOptions: Option[] = useMemo(
    (): Option[] =>
      generateDropdownOptionsForBusinessArea(
        loadedBusinessAreas.filter(
          (area) => area.companyId === projectOrder.companyId
        )
      ),
    [loadedBusinessAreas, projectOrder.companyId]
  );

  /**
   * location of the customer that is selected for the project
   */
  const customerLocations: CustomerLocation[] = useMemo(
    (): CustomerLocation[] =>
      loadedCustomerLocations.filter(
        (location) => location.customerId === customerProject.customerId
      ),
    [customerProject.customerId, loadedCustomerLocations]
  );

  /**
   * Helper method to create a new project for a custoimerProject
   */
  const handleCreateProject = (): void => {
    createNewProjectFromCustomerProject(axios, customerProject.id).then(
      (createdProject) =>
        createdProject &&
        navigate("/project/edit", { state: { project: createdProject } })
    );
  };

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar onBackClick={() => navigate(-1)} title="Projekt-Bestellung">
        {customerProject.id && (
          <Button
            value={t("general.buttons.createCustomerProject")}
            onClick={handleCreateProject}
          />
        )}
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      <SoftLockedPopup
        customer={softLockedCustomer}
        closePopup={() => setSoftLockedCustomer(undefined)}
      />
      <Popup
        isOpen={!!offeredTaskIdToDelete}
        onClose={() => setOfferedTaskIdToDelete(undefined)}
        title={t("pages.order.create.deleteTaskPopup")}
        buttons={[
          <Button
            backgroundColor="#BC2E46"
            value={t("general.buttons.yes")}
            onClick={() => {
              setCustomerProject((prev) => ({
                ...prev,
                offeredTasks: customerProject.offeredTasks.filter(
                  (task) => task.id !== offeredTaskIdToDelete
                ),
              }));
              setOfferedTaskIdToDelete(undefined);
            }}
          />,
          <Button
            value={t("general.buttons.no")}
            onClick={() => setOfferedTaskIdToDelete(undefined)}
          />,
        ]}
      >
        <p>{t("pages.order.create.deleteTask")}</p>
      </Popup>
      <Box>
        <Input
          onChange={() => {}}
          type="text"
          disabled
          value={customerProject.numberRangeNumber || "-"}
          label={t("pages.order.create.numberRange")}
        />
        <fieldset className="three-columns">
          <legend>{t("pages.order.create.generalInformation")}</legend>
          <Dropdown
            selectedOption={projectOrder.officeId}
            onChange={handleOfficeSelection}
            label={t("pages.order.create.office")}
            options={loadedOffices.map((office) => ({
              label: office.name,
              value: office.id,
            }))}
          />
          <Dropdown
            label={t("pages.order.create.currency")}
            selectedOption={projectOrder.currencyId}
            onChange={(currencyId) =>
              setProjectOrder({ ...projectOrder, currencyId })
            }
            options={generateDropdownOptions(loadedCurrencys, "title", "id")}
          />
          <Dropdown
            options={Object.values(OfferProvidedBy).map((providedBy) => ({
              label: t(`pages.order.create.providedBy.${providedBy}`),
              value: providedBy,
            }))}
            label={t("pages.order.create.providedBy.label")}
            selectedOption={customerProject.providedBy}
            onChange={(providedBy) =>
              setCustomerProject({
                ...customerProject,
                providedBy: providedBy as OfferProvidedBy,
              })
            }
          />
          <Dropdown
            label={t("pages.order.create.language")}
            selectedOption={customerProject.language}
            onChange={(language) =>
              setCustomerProject({
                ...customerProject,
                language: language as Language,
              })
            }
            options={generateDropdownOptionsForLanguage()}
          />
          <Dropdown
            options={generateDropdownOptions(loadedCompanies, "name", "id")}
            selectedOption={projectOrder.companyId}
            onChange={(companyId) =>
              setProjectOrder({ ...projectOrder, companyId })
            }
            label={t("pages.order.create.company")}
          />
          <Dropdown
            onChange={(businessAreaId) =>
              setProjectOrder({
                ...projectOrder,
                businessAreaId,
              })
            }
            options={businessAreaOptions}
            label={t("pages.order.create.businessArea")}
            selectedOption={projectOrder.businessAreaId}
          />
        </fieldset>
      </Box>
      <Box>
        <fieldset className="three-columns">
          <legend>{t("pages.order.create.customerInformation")}</legend>
          <div>
            <Dropdown
              required
              onChange={handleCentralCustomerSelection}
              selectedOption={projectOrder.customerId}
              options={generateDropdownOptionsForCustomers(loadedCustomers)}
              label={t("pages.order.create.customer")}
            />
            <Dropdown
              required
              onChange={(locationId) => {
                setProjectOrder({ ...projectOrder, locationId });
              }}
              selectedOption={projectOrder.locationId}
              options={generateDropdownOptions(customerLocations, "name", "id")}
              label={t("pages.order.create.customerLocation")}
            />
            <Dropdown
              required
              label={t("pages.order.create.workingLocationCustomer")}
              options={generateDropdownOptions(loadedCustomers, "name", "id")}
              onChange={(customerId) =>
                setProjectOrder({
                  ...projectOrder,
                  workingLocation: {
                    customerId,
                    customerLocationId: "",
                    customerContactId: "",
                  },
                })
              }
              selectedOption={projectOrder?.workingLocation?.customerId || ""}
            />
            <Dropdown
              required
              label={t("pages.order.create.workingLocation")}
              options={generateDropdownOptions(
                loadedCustomerLocations.filter(
                  (location) =>
                    location.customerId ===
                    projectOrder.workingLocation.customerId
                ),
                "name",
                "id"
              )}
              onChange={(customerLocationId) =>
                setProjectOrder({
                  ...projectOrder,
                  workingLocation: {
                    ...projectOrder.workingLocation,
                    customerLocationId,
                  },
                })
              }
              selectedOption={
                projectOrder?.workingLocation?.customerLocationId || ""
              }
            />
            <Dropdown
              required
              options={generateDropdownOptions(
                loadedCustomerUsers.filter(
                  (custoemrUser) =>
                    custoemrUser.customerId ===
                    projectOrder.workingLocation.customerId
                ),
                ["firstName", "lastName"],
                "id"
              )}
              label={t("pages.order.create.workingLocationContact")}
              onChange={(selectedUserId) =>
                setProjectOrder({
                  ...projectOrder,
                  workingLocation: {
                    ...projectOrder.workingLocation,
                    customerContactId: selectedUserId,
                  },
                })
              }
              selectedOption={projectOrder.workingLocation.customerContactId}
            />
          </div>
          <div>
            <Dropdown
              required
              selectedOption={projectOrder.customerContact}
              options={generateDropdownOptions(
                loadedCustomerUsers.filter(
                  (customerUser) =>
                    customerUser.customerId === projectOrder.customerId
                ),
                ["firstName", "lastName"],
                "id"
              )}
              label={t("pages.order.create.contactPerson")}
              onChange={(customerContact) =>
                setProjectOrder({ ...projectOrder, customerContact })
              }
            />
            <Dropdown
              required
              options={generateDropdownOptions(
                loadedPaymentConditions ?? [],
                "description",
                "id"
              )}
              selectedOption={projectOrder.paymentCondition?.id}
              label={t("pages.order.create.paymentCondition")}
              onChange={handlePaymentConditionChange}
            />
            <TextArea
              label={t("pages.order.create.workingLocationInformation")}
              onChange={(workingLocationComment) =>
                setProjectOrder({ ...projectOrder, workingLocationComment })
              }
              value={projectOrder.workingLocationComment}
              resizable
            />
          </div>
          <div>
            <Dropdown
              selectedOption={projectOrder.additionalReceivers?.[0] || ""}
              options={generateDropdownOptions(
                loadedCustomerUsers.filter(
                  (customerUser) =>
                    customerUser.customerId === projectOrder.customerId
                ),
                ["firstName", "lastName"],
                "id"
              )}
              label={t("pages.order.create.addReceiver")}
              onChangeMultiple={(additionalReceivers) =>
                setProjectOrder({ ...projectOrder, additionalReceivers })
              }
              multi
              create
              onCreateNew={(newReceiver) =>
                setProjectOrder({
                  ...projectOrder,
                  additionalReceivers: [
                    ...(projectOrder.additionalReceivers
                      ? [...projectOrder.additionalReceivers, newReceiver.value]
                      : newReceiver.value),
                  ],
                })
              }
            />
            <Dropdown
              required
              selectedOption={projectOrder.invoiceRecipient?.customerId}
              label={t("pages.order.create.invoiceRecipient")}
              options={generateDropdownOptions(loadedCustomers, "name", "id")}
              onChange={(customerId) =>
                setProjectOrder({
                  ...projectOrder,
                  invoiceRecipient: {
                    customerUserId: "",
                    customerId,
                  },
                })
              }
            />

            <Dropdown
              required
              disabled={!projectOrder.invoiceRecipient?.customerId}
              selectedOption={projectOrder.invoiceRecipient?.customerUserId}
              label={t("pages.order.create.invoiceContact")}
              options={generateDropdownOptionsForCustomerUsers(
                loadedCustomerUsers,
                projectOrder.invoiceRecipient.customerId
              )}
              onChange={(customerUserId) => {
                if (projectOrder.invoiceRecipient)
                  setProjectOrder({
                    ...projectOrder,
                    invoiceRecipient: {
                      ...projectOrder.invoiceRecipient,
                      customerUserId,
                    },
                  });
              }}
            />
          </div>
        </fieldset>
      </Box>
      <Box>
        <fieldset>
          <legend>{t("pages.order.create.orderDescription")}</legend>
          <Input
            type="text"
            value={customerProject.annotation}
            onChange={(annotation) =>
              setCustomerProject({ ...customerProject, annotation })
            }
            label={t("pages.order.create.description")}
          />
          <div className="three-columns">
            <Input
              onChange={(customerArticleNumber) =>
                setProjectOrder({ ...projectOrder, customerArticleNumber })
              }
              type="text"
              value={projectOrder.customerArticleNumber}
              label={t("pages.order.create.customerArticleNumber")}
            />
            <Input
              onChange={(customerArticleDescription) =>
                setProjectOrder({
                  ...projectOrder,
                  customerArticleDescription,
                })
              }
              type="text"
              value={projectOrder.customerArticleDescription}
              label={t("pages.order.create.customerArticleDescription")}
            />
            <Input
              label={t("pages.order.create.amountPersonal")}
              type="number"
              value={customerProject.plannedAmountWorker}
              onChangeNumber={(plannedAmountWorker) =>
                setCustomerProject({
                  ...customerProject,
                  plannedAmountWorker: plannedAmountWorker,
                })
              }
            />
            <Input
              value={projectOrder.pieces}
              type="number"
              onChangeNumber={(pieces) =>
                setProjectOrder({ ...projectOrder, pieces })
              }
              label={t("pages.order.create.pieces")}
            />
            <Dropdown
              selectedOption={projectOrder.invoiceType}
              onChange={(projectInvoiceType) =>
                setProjectOrder({
                  ...projectOrder,
                  invoiceType: projectInvoiceType as ProjectInvoiceType,
                })
              }
              label={t("pages.order.create.invoiceType")}
              options={Object.values(ProjectInvoiceType).map((type) => ({
                label: t(`general.projectInvoiceType.${type}`),
                value: type,
              }))}
            />
          </div>
          <div>
            <div className="order-create--options-wrapper">
              <AddIcon
                width={30}
                onClick={() =>
                  setCustomerProject({
                    ...customerProject,
                    offeredTasks: [
                      ...customerProject.offeredTasks,
                      generateEmptyOfferedTask(),
                    ],
                  })
                }
              />
              <div>
                <Checkbox
                  label={t("pages.order.create.offerTop")}
                  isChecked={customerProject.topInfo}
                  onCheck={(topInfo) =>
                    setCustomerProject({
                      ...customerProject,
                      topInfo,
                    })
                  }
                />
                <Checkbox
                  label={t("pages.order.create.offerBottom")}
                  isChecked={customerProject.bottomInfo}
                  onCheck={(bottomInfo) =>
                    setCustomerProject({
                      ...customerProject,
                      bottomInfo,
                    })
                  }
                />
                <Checkbox
                  label={t("pages.order.create.showSum")}
                  isChecked={customerProject.showSum}
                  onCheck={(showSum) =>
                    setCustomerProject({ ...customerProject, showSum })
                  }
                />
              </div>
            </div>
            {rows.length > 0 && (
              <Table
                rows={rows}
                header={
                  t("pages.order.create.tableHeader", {
                    returnObjects: true,
                  }) as TableHeader[]
                }
              />
            )}
          </div>
        </fieldset>
      </Box>
    </form>
  );
};
