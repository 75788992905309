import { Button, Input } from "@sam/components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TimeTracking, createNewTimeTracking } from "shared";
import { ReactComponent as LoadingCircle } from "../../assets/loading.svg";
import { TimeWidgetButtonProps } from "./Widget.types";
import { addTimeTrackingIndexedDB } from "../../utils/indexedDB/db.utils";
import {
  endTimeTracking,
  getCorrectDisplayText,
  getTimeTrackingFromIndexedOrMongoDB,
} from "../../utils/timeTracking/TimeTracking.utils";
import { useUser } from "../UserContext";
import "./widgets.scss";

export const TimeWidget: React.FC = () => {
  const { t } = useTranslation();
  const { axios, user } = useUser();
  const [activeTracking, setActiveTracking] = useState<TimeTracking>();

  //useEffect hook to check if there is an existing timetracking on the indexedDB or mongoDB
  useEffect(() => {
    getTimeTrackingFromIndexedOrMongoDB(axios, user.id).then(setActiveTracking);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  /**
   * Helper method to end timeTracking and save updated objects on MongoDB and indexedDB
   * @returns void
   */
  const handleEndTracking = useCallback((): void => {
    if (!activeTracking) return;
    endTimeTracking(user.id, activeTracking, axios).then((updatedTracking) => {
      if (updatedTracking) setActiveTracking(updatedTracking);
    });
  }, [activeTracking, axios, user]);

  /**
   * Helper method to start timeTracking
   */
  const handleStartTracking = useCallback((): void => {
    createNewTimeTracking(axios, user.id).then((createdTracking) => {
      if (createdTracking) {
        setActiveTracking(createdTracking);
        addTimeTrackingIndexedDB(createdTracking);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  /**
   * useCallback hook to display the correct button to start or stop the timeTracking
   */
  const buttonConfig: TimeWidgetButtonProps =
    useMemo((): TimeWidgetButtonProps => {
      if (!!activeTracking && activeTracking.endTime === 0)
        return {
          color: "#BC2E46",
          onClick: handleEndTracking,
          text: t("general.buttons.stop"),
        };
      else if (!!activeTracking && activeTracking.endTime !== 0)
        return {
          color: "#808080",
          text: t("general.buttons.done"),
          disabled: true,
        };
      else
        return {
          color: "#BFBFBF",
          onClick: handleStartTracking,
          text: t("general.buttons.start"),
        };
    }, [activeTracking, handleEndTracking, handleStartTracking, t]);

  return (
    <div className="time-widget-component__wrapper">
      <p className="time-widget-component__headline">
        {t("pages.dashboard.widgets.headlineTimeTracking")}
      </p>
      <div className="time-widget-component__display-time">
        {activeTracking && activeTracking.endTime === 0 && <LoadingCircle />}
        <p>{getCorrectDisplayText(activeTracking)}</p>
      </div>
      <div className="time-widget-component__break">
        <p className="time-widget-component__break__label">
          {t("pages.dashboard.widgets.breakTimeLabel")}
        </p>
        <Input
          type="number"
          value={activeTracking?.breakTime || 0}
          onChangeNumber={(breakTime) => {
            if (!!activeTracking && activeTracking.endTime === 0)
              setActiveTracking({ ...activeTracking, breakTime });
          }}
          disabled={!!activeTracking?.startTime && activeTracking.endTime === 0}
        />
      </div>

      <Button
        backgroundColor={buttonConfig.color}
        value={buttonConfig.text}
        onClick={buttonConfig.onClick}
        disabled={buttonConfig.disabled}
      />
    </div>
  );
};
