import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Right, useData } from "shared";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { convertCarIntoTableEntries } from "../../utils/car/Car.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

const CarOverview: React.FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<string>("");

  const { data: loadedCars } = useData("CARS_ALL", {
    config: { fallbackData: [] },
  });

  //Holds TableRows for cars
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertCarIntoTableEntries(user.right, loadedCars, navigate),
    [loadedCars, navigate, user.right]
  );

  return (
    <>
      <TopBar title={t("pages.carOverview.headline")} onSearch={setFilterValue}>
        {isUserAllowedToDo(user.right, Right.CAR_CREATE) && (
          <AddIcon width={30} onClick={() => navigate("/cars/create")} />
        )}
      </TopBar>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.carOverview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
export default CarOverview;
