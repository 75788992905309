import { useMemo } from "react";
import { useData } from "shared";
import { convertCurrencyIntoTableEntries } from "../../utils/currency/Currency.utils";
import { useNavigate } from "react-router-dom";
import { Box, Table, TopBar } from "@sam/components";
import { useTranslation } from "react-i18next";
import { TableHeader, TableRow } from "@sam/components/src/Table/Table.types";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";

export const CurrencyOverview: React.FC = () => {
  const { data: loadedCurrencies } = useData("CURRENCY_ALL", {
    config: { fallbackData: [] },
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertCurrencyIntoTableEntries(loadedCurrencies, navigate),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadedCurrencies]
  );
  return (
    <>
      <TopBar title={t("pages.currency.overview.topBarHeadline")}>
        <PlusIcon width={30} onClick={() => navigate("/currency/edit")} />
      </TopBar>
      <Box>
        <Table
          rows={rows}
          header={
            t("pages.currency.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
