import { TableRow } from "@sam/components";
import { SimpleUser, Task } from "shared";
import { getUserNameForSimpleUser } from "../user/User.utils";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { NavigateFunction } from "react-router-dom";
import { Language } from "shared/src/Language/Language.types";

/**
 * Util method to convert tasks into tableEntries
 * @param tasks list of all tasks to display
 * @param users list of simpleUsers to get the names from
 * @param navigate NavigateFunction
 * @returns  Array of converted TableRows
 */
export const convertTasksIntoTableEntries = (
  tasks: Task[],
  users: SimpleUser[],
  navigate: NavigateFunction
): TableRow[] => {
  return tasks.map((task) => ({
    id: task.id,
    onClick: () => navigate("/task/create", { state: { task } }),
    content: [
      task.title,
      task?.updatedAt?.toLocaleDateString() || "",
      getUserNameForSimpleUser(task?.updatedBy || "", users),
      <div>
        <EditIcon
          width={20}
          onClick={(evt) => {
            evt.stopPropagation();
            navigate("/task/create", { state: { task } });
          }}
        />
      </div>,
    ],
  }));
};

/**
 * Helper method to generate an empty task
 * @param override override set attributes as needed
 * @returns  generated Task
 */
export const generateEmptyTask = (override?: Partial<Task>): Task => ({
  id: undefined!,
  createdBy: undefined!,
  description: "",
  title: "",
  updatedAt: undefined!,
  updatedBy: undefined!,
  identifier: "",
  lastUpdate: undefined!,
  price: 0,
  translations: new Map<Language, string>(),
  unit: "",
  amount: 1,
  annotation: "",
  standard: false,
  ...override,
});
