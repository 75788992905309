import { Box, Table, TableRow, TopBar } from "@sam/components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { MailType } from "shared";
import { TableHeader } from "@sam/components/src/Table/Table.types";

export const MailConfigOverview: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  /**
   * TableRows to display
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      Object.keys(MailType).map((type) => ({
        content: [t(`general.mailTypes.${type}`)],
        id: type,
        onClick: () => navigate("/mail/edit", { state: { type } }),
      })),

    [navigate, t]
  );

  return (
    <>
      <TopBar title={t("pages.mailConfig.overview.topBarHeadline")} />
      <Box>
        <Table
          rows={rows}
          header={
            t("pages.mailConfig.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
