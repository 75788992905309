import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useData } from "shared";
import { convertNumberRangesIntoTableEntries } from "../../utils/order/Order.utils";

export const NumberRangeOverview = () => {
  const { data: loadedNumberRanges } = useData("NUMBER_RANGE_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCompanies } = useData("COMPANY_ALL", {
    config: { fallbackData: [] },
  });

  const { t } = useTranslation();

  //Holds tableRows for numberRange
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertNumberRangesIntoTableEntries(loadedNumberRanges, loadedCompanies),

    [loadedNumberRanges, loadedCompanies]
  );

  return (
    <>
      <TopBar title={t("pages.numberRanges.topBarHeadline")} />
      <Box>
        <Table
          rows={rows}
          header={
            t("pages.numberRanges.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
