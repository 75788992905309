/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Column,
  ColumnFiltersState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import { ReactComponent as ChevronDown } from "../../assets/chevron-down.svg";

import { ReactComponent as FilterIcon } from "../../assets/filter.svg";
import { Checkbox } from "../Checkbox/Checkbox";
import "./Table.scss";
import { TableComponentProps, TableHeader, TableRow } from "./Table.types";

/**
 * Render gate to decide if the complete table should be rendered or a
 * fallback component. This is needed to minimize the rerender logic
 */
export const Table: React.FC<TableComponentProps> = ({
  rows,
  header = [],
  paginationConfig = {
    pageSize: 30,
  },
  filterValue = "",
}) => {
  const renderOutput = useMemo(() => {
    if (rows.length > 0)
      return (
        <InternalTable
          rows={rows}
          header={header}
          paginationConfig={paginationConfig}
          filterValue={filterValue}
        />
      );
    return <h2>no data :(</h2>;
  }, [filterValue, header, paginationConfig, rows]);

  return renderOutput;
};

const Filter = ({ column }: { column: Column<any, unknown> }) => {
  const columnFilterValue = column.getFilterValue();
  return (
    <input
      value={
        typeof columnFilterValue === "string"
          ? columnFilterValue
          : typeof columnFilterValue === "number"
          ? columnFilterValue.toString()
          : ""
      }
      onChange={(e) => {
        column.setFilterValue(e.target.value || undefined);
      }}
      placeholder={`Filter`}
    />
  );
};

/**
 * The _actual_ table component
 */
const InternalTable: React.FC<TableComponentProps> = ({
  rows,
  header = [],
  filterValue = "",
}) => {
  const [visibleChild, setVisibleChild] = useState<string>("");
  const [showFilter, toggleFilter] = useState<boolean>(false);
  const [localHeader, setLocalHeader] = useState<TableHeader[]>([...header]);

  /**
   * Holds the react table columns to display
   */
  const columns = React.useMemo(() => {
    if (!rows.length) return [];

    const columnHelper = createColumnHelper<TableRow>();

    if (
      rows.some(
        (row) =>
          row.children && !localHeader.some((header) => header.text === "")
      )
    )
      localHeader.push({ text: "Ausklappen", visible: true });
    if (rows.some((row) => row.children)) {
      rows.forEach((row) =>
        row.content.push(
          <ChevronDown
            className="table-component__sorting-indicator"
            onClick={() => {
              setVisibleChild((prev) => {
                const newValue = prev === row.id ? "" : row.id;
                return newValue;
              });
            }}
          />
        )
      );
    }

    const localCols = localHeader.map((h, index) => {
      return columnHelper.accessor((row) => row.content[index], {
        header: h.text,
        cell: (row) => row.row.original.content[index],
        id: `column_${index}`,
        enableColumnFilter: true,
      });
    });

    return localCols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localHeader]);

  const data: TableRow[] = React.useMemo(() => {
    if (!rows.length) return [];
    return rows
      .filter(
        (row) =>
          filterValue === "" ||
          row.content
            .toString()
            .trim()
            .toLowerCase()
            .includes(filterValue.trim().toLowerCase())
      )
      .map((row) => ({
        ...row,
        content: row.content,
        expandable: row.children ? true : false,
        onClick: row.onClick,
        id: row.id,
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, rows]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableInstance = useReactTable({
    columns,
    data,
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
  });

  const updateLocalHeaderFilter = (index: number): void => {
    const workingCopy: TableHeader[] = [...localHeader];
    workingCopy[index].visible = !localHeader[index].visible;
    setLocalHeader(workingCopy);
  };

  return (
    <div className="table-component">
      <div className="table-component__filter__wrapper">
        <div className="table-component__filter__icon__wrapper">
          <FilterIcon
            className="table-component__filter__icon"
            onClick={() => toggleFilter((old) => !old)}
          />
        </div>
        {showFilter && (
          <div className="table-component__filter__entry__wrapper">
            {localHeader.map((head, index) => (
              <div className="table-component__filter__entry">
                <Checkbox
                  isChecked={head.visible || false}
                  onCheck={() => updateLocalHeaderFilter(index)}
                  label={head.text}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <table>
        <thead>
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <tr {...headerGroup.headers} key={headerGroup.id}>
              {headerGroup.headers
                .filter((_, index) => localHeader[index]?.visible)
                // check this thread and see that any is the best option -.-
                // https://github.com/TanStack/table/issues/1481
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .map((column: any) => (
                  <th colSpan={column.colSpan} key={column.id}>
                    <div
                      className="table-component__head-wrapper"
                      onClick={column.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        column.column.columnDef.header,
                        column.getContext()
                      )}
                      {{
                        asc: " 🔼",
                        desc: " 🔽",
                      }[column.column.getIsSorted() as string] ?? null}
                    </div>
                    <div className="table-component__filter-wrapper">
                      {column.column.getCanFilter() ? (
                        <Filter column={column.column} />
                      ) : null}
                    </div>
                  </th>
                ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {tableInstance.getRowModel().rows.map((row: any) => {
            return (
              <React.Fragment key={row.id}>
                <tr
                  onClick={() => row.original.onClick?.()}
                  key={`tr-${row.original.id}`}
                >
                  {row
                    .getVisibleCells()
                    .filter(
                      (_: any, index: number) => localHeader[index]?.visible
                    )
                    .map((cell: any) => {
                      return (
                        <td
                          style={{
                            cursor: row.original.onClick ? "pointer" : "",
                            backgroundColor:
                              filterValue !== "" &&
                              cell
                                .getValue()
                                .toString()
                                .toLocaleLowerCase()
                                .includes(filterValue.toLocaleLowerCase())
                                ? "#ffdd33"
                                : "",
                          }}
                          key={`td-${row.original.id}`}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                </tr>
                {row.original.id === visibleChild && row.original.children && (
                  <tr>
                    <td colSpan={columns.length}>{row.original.children}</td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div>
        <button
          onClick={() => tableInstance.previousPage()}
          disabled={!tableInstance.getCanPreviousPage()}
        >
          Previous
        </button>
        <span>
          {"    "}
          <strong>{+1}</strong>
          {"    "}
        </span>
        <button
          onClick={() => tableInstance.nextPage()}
          disabled={!tableInstance.getCanNextPage()}
        >
          Next
        </button>
      </div>
    </div>
  );
};
