import { Agenda, Box, Button, Dropdown, Input, TopBar } from "@sam/components";
import { AgendaEntryGroup } from "@sam/components/src/Agenda/Agenda.types";
import { FormEvent, useEffect, useState } from "react";
import {
  Absence,
  Right,
  createNewAbsence,
  generateEmptyAbsenceReason,
  generateNotification,
  useData,
} from "shared";
import { generateAgendaEntries } from "../../utils/holiday/Holiday.utils";
import { generateEmptyAbsence } from "../../utils/absence/Absence.utils";
import {
  getUserNameForSimpleUser,
  isUserAllowedToDo,
} from "../../utils/user/User.utils";
import { ReactComponent as SaveIcon } from "../../assets/save.svg";
import { useUser } from "../../components/UserContext";
import { NotificationType } from "shared/src/notification/notification.types";
import { useTranslation } from "react-i18next";

export const PlanningBoard: React.FC = () => {
  const { axios, user } = useUser();
  const { data: loadedSimpleUsers } = useData("SIMPLEUSERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedAbsences, mutate: refreshAbsences } = useData(
    "ABSENCE_ALL",
    {
      config: { fallbackData: [] },
    }
  );
  const { data: loadedHolidays } = useData("HOLIDAYS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedAbsenceReasons } = useData("ABSENCE_REASONS_ALL", {
    config: { fallbackData: [] },
  });
  const [entryGroups, setEntryGroups] = useState<AgendaEntryGroup[]>([]);
  const [absenceToEdit, setAbsenceToEdit] = useState<Absence>();

  const { t } = useTranslation();

  //Hook to generate the agenda entries after all neccessary data is available
  useEffect(
    () =>
      setEntryGroups(
        generateAgendaEntries(loadedSimpleUsers, loadedAbsences, loadedHolidays)
      ),
    [loadedSimpleUsers, loadedAbsences, loadedHolidays]
  );

  /**
   * Submit handler to create a new Absence
   * @param evt FormEvent to prevent default behavior
   */
  const handleSubmit = (evt: FormEvent): void => {
    evt.preventDefault();
    if (!absenceToEdit) return;
    createNewAbsence(axios, absenceToEdit).then((createdAbsence) => {
      generateNotification({
        type: NotificationType.SUCCESS,
        value: t("general.notification.success.absenceCreate"),
      });
      setAbsenceToEdit(createdAbsence);
      refreshAbsences();
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <TopBar title={t("pages.planningBoard.topBarHeadline")}>
        <Button type="submit" value={<SaveIcon className="save-icon" />} />
      </TopBar>
      <Box>
        <Agenda
          entryGroups={entryGroups}
          addItem={(item) => {
            setAbsenceToEdit(
              generateEmptyAbsence({
                startDate: new Date(item.start),
                userId: item.group?.toString() || "",
                reason: generateEmptyAbsenceReason(),
              })
            );
          }}
        />
      </Box>
      {absenceToEdit &&
        loadedSimpleUsers &&
        isUserAllowedToDo(user.right, Right.ABSENCE_CREATE) && (
          <Box>
            <>
              {loadedAbsenceReasons && (
                <Dropdown
                  label={t("pages.planningBoard.absenceReason")}
                  selectedOption={absenceToEdit.reason?.id || ""}
                  onChange={(reasonId) =>
                    setAbsenceToEdit({
                      ...absenceToEdit,
                      reason: loadedAbsenceReasons.find(
                        (absenceReason) => reasonId === absenceReason.id
                      )!,
                    })
                  }
                  options={loadedAbsenceReasons.map((reason) => ({
                    label: reason.name,
                    value: reason.id,
                  }))}
                />
              )}
              <Input
                onChange={() => {}}
                label={t("pages.planningBoard.employeeName")}
                type="text"
                disabled
                value={getUserNameForSimpleUser(
                  absenceToEdit.userId,
                  loadedSimpleUsers
                )}
              />
              <Input
                label={t("pages.planningBoard.startDate")}
                type="date"
                value={absenceToEdit.startDate}
                onChangeDate={(startDate) => {
                  if (startDate)
                    setAbsenceToEdit({ ...absenceToEdit, startDate });
                }}
              />
              <Input
                label={t("pages.planningBoard.endDate")}
                type="date"
                value={absenceToEdit.endDate}
                onChangeDate={(endDate) => {
                  if (endDate) setAbsenceToEdit({ ...absenceToEdit, endDate });
                }}
              />
            </>
          </Box>
        )}
    </form>
  );
};
