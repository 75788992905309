import { Box, Dropdown, Table, TopBar } from "@sam/components";
import { TableHeader, TableRow } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  InventoryItem,
  InventoryType,
  convertInventoryItemsIntoTableEntries,
  generateDropdownOptionsForInventoryTypes,
  getAllInventoryItemsByType,
  useData,
} from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";

const InventoryOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: allOffices } = useData("OFFICES_ALL", {
    config: { fallbackData: [], revalidateOnMount: true },
  });
  const location = useLocation<{ type?: InventoryType }>();
  const { axios } = useUser();
  const [selectedInventoryType, setSelectedInventoryType] =
    useState<InventoryType>(location.state?.type || InventoryType.HANDY);
  const [loadedInventory, setLoadedInventory] = useState<InventoryItem[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");

  // loads the inventory items for the given type
  useEffect(() => {
    if (!axios) return;
    getAllInventoryItemsByType(selectedInventoryType, axios).then(
      setLoadedInventory
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInventoryType]);

  /**
   * Holds the single row entries for every inventory item
   */
  const overviewRows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertInventoryItemsIntoTableEntries(
        loadedInventory,
        navigate,
        selectedInventoryType,
        allOffices
      ),
    [allOffices, loadedInventory, navigate, selectedInventoryType]
  );

  return (
    <>
      <TopBar title={t("pages.inventory.title")} onSearch={setFilterValue}>
        <PlusIcon
          height={20}
          width={20}
          onClick={() =>
            navigate("new", { state: { type: selectedInventoryType } })
          }
        />
      </TopBar>
      <Box>
        <Dropdown
          options={generateDropdownOptionsForInventoryTypes()}
          onChange={(selectedValue) =>
            setSelectedInventoryType(selectedValue as InventoryType)
          }
          selectedOption={selectedInventoryType}
        />
        <Table
          filterValue={filterValue}
          header={
            t("pages.inventory.overviewTable", {
              returnObjects: true,
            }) as TableHeader[]
          }
          rows={overviewRows}
        />
      </Box>
    </>
  );
};

export default InventoryOverview;
