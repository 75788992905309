import { AxiosInstance } from "axios";
import {
  DocumentBucket,
  FileDeleteRequest,
  FileRequest,
  FileUploadConfig,
  SchambeckDoc,
} from "./Files.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";

/**
 * API method to get a List of all SchambeckDocs for files in a folder
 * @param axios instance of axios
 * @param request FileRequest containing of the folder structure and the bucket
 * @returns Array of all found SchmabeckDocs
 */
export const getDocumentListForFolder = async (
  axios: AxiosInstance,
  request: FileRequest
): Promise<SchambeckDoc[]> => {
  return axios
    .post("/document/folder", request)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading files", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getDocumentList"),
      });
      return [];
    });
};

/**
 * API method to delete a schambeckDoc
 * @param axios instance of axios
 * @param id of the schambeckDoc to delete
 * @returns boolean depending on success
 */
export const deleteSchambeckDoc = async (
  axios: AxiosInstance,
  id: string
): Promise<boolean> => {
  return axios
    .post("/document/delete", id, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => res.status === 200)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.deleteSchambeckDoc"),
      });
      console.error("Error while deleting schambeckDoc", exc);
      return false;
    });
};

/**
 * API Method to save a new File to the minio ObjectStorage
 * @param axios instance of axios
 * @param config to save the file containing attributes like fileName, bucket etc.
 * @param fileData the file to upload
 * @returns SchambeckDoc of the saved file or undefined on error
 */
export const saveNewFile = async (
  axios: AxiosInstance,
  config: FileUploadConfig,
  fileData: File
): Promise<SchambeckDoc | undefined> => {
  const uploadFormdata: FormData = new FormData();
  uploadFormdata.append(
    "config",
    new Blob([JSON.stringify(config)], { type: "application/json" })
  );
  uploadFormdata.append("fileData", fileData);

  return axios
    .post("/storage/new", uploadFormdata, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.saveNewFile"),
      });
      console.error("Error while saving File", exc);
      return undefined;
    });
};

/**
 * API Method to read a filie from the minio objectStorage
 * @param axios instance of axios
 * @param bucket to get the file from
 * @param path String of the folder structure and the filename e.g. customerId/orderId/filename.pdf
 * @param type contentType of the file
 * @returns found blob or undefined on error
 */
export const readFileFromMinio = async (
  axios: AxiosInstance,
  bucket: DocumentBucket,
  path: string,
  type: string
): Promise<Blob | undefined> => {
  return axios
    .get("/storage/", {
      params: { bucket, path },
      responseType: "arraybuffer",
    })
    .then((res) => new Blob([res.data], { type }))
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.readFile"),
      });
      console.error("Error while loading file", exc);
      return undefined;
    });
};

/**
 * API method to delete a file on the minio objectStorage and delete the SchambeckDoc for the file
 * @param axios instance of axios
 * @param request String of the folder structure and the filename e.g. customerId/orderId/filename.pdf
 * @returns boolean depending on success
 */
export const deleteFileFromMinio = async (
  axios: AxiosInstance,
  request: FileDeleteRequest
): Promise<boolean> => {
  return axios
    .post("/storage/delete", request)
    .then((res) => res.status === 200)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.deleteFile"),
      });
      console.error("Error while deleting file", exc);
      return false;
    });
};
