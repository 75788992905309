import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Right, useData } from "shared";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { convertCustomersIntoTableEntries } from "../../utils/customer/Customer.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

export const CustomerOverview: React.FC = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState<string>("");
  const { data: customers } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });

  //Holds tableRows for the customers
  const rows: TableRow[] = useMemo(
    () => convertCustomersIntoTableEntries(customers, navigate),
    [customers, navigate]
  );

  return (
    <>
      <TopBar
        title={t("pages.customerOverview.topBarHeadline")}
        onSearch={setFilterValue}
      >
        {isUserAllowedToDo(user.right, Right.CUSTOMER_CREATE) && (
          <AddIcon width={20} onClick={() => navigate("/customer/create")} />
        )}
      </TopBar>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.customerOverview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
