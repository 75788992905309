import { TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import { Language } from "shared/src/Language/Language.types";
import { Department } from "shared/src/department/Department.types";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";

/**
 * Helper to create a new department instance
 *
 * @param override Optional parameters to override on create
 * @returns A new department instance
 */
export const generateEmptyDepartment = (
  override?: Partial<Department>
): Department => ({
  id: undefined!,
  createDate: undefined!,
  createdBy: undefined!,
  disabled: false,
  title: "",
  names: new Map<Language, string>(),
  ...override,
});

/**
 * Util method to convert departments into tableEntries
 * @param departments to display
 * @param navigate navigateFunction
 * @returns Array of TableRows
 */
export const convertDepartmentsIntoTableRows = (
  departments: Department[],
  navigate: NavigateFunction
): TableRow[] => {
  return departments.map((department) => ({
    id: department.id,
    onClick: () =>
      navigate("edit", {
        state: { department },
      }),
    content: [
      department.title,
      department.createDate.toLocaleDateString("DE-de"),
      department.lastUpdate?.toLocaleDateString("DE-de") || "-",
      <div>
        <EditIcon
          width={20}
          onClick={() =>
            navigate("edit", {
              state: { department },
            })
          }
        />
      </div>,
    ],
  }));
};
