import {
  Agenda,
  Box,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Option,
  Table,
  TableRow,
  TextArea,
  TopBar,
} from "@sam/components";
import { AgendaEntryGroup } from "@sam/components/src/Agenda/Agenda.types";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppointmentState,
  Bike,
  Car,
  ContractInformation,
  Country,
  DayOfWeek,
  DocumentBucket,
  DriverLicenseType,
  EducationQualification,
  EmploymentType,
  FamilyState,
  Gender,
  Position,
  RequiredState,
  SchambeckUser,
  Schedule,
  TaxClass,
  WorkQualification,
  WorkScheduleDistribution,
  createSchambeckUser,
  deleteSchedule,
  generateDropdownOptions,
  generateNewSchedule,
  generateNotification,
  updateBike,
  updateCar,
  updateSchambeckUser,
  updateSchedule,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { Right } from "shared/src/userRole/UserRole.types";
import { ReactComponent as TimeIcon } from "../../assets/time.svg";
import { FileOverview } from "../../components/files/FileOverview";
import { NavigationAnchor } from "../../components/NavigationAnchor";
import { NotAllowed } from "../../components/NotAllowed";
import { useUser } from "../../components/UserContext";
import WorkScheduleEdit from "../../components/userEdit/WorkScheduleEdit";
import {
  generateEmptyAppointmentDetails,
  generateEmptySchedule,
} from "../../utils/project/Project.utils";
import {
  generateEmptySchambeckUser,
  generateUserAgendaScheduleEntries,
  generateWorkTimeSchedulesForUser,
  getSubordinatesForUser,
  getUserNameForSimpleUser,
  isUserAllowedToDo,
} from "../../utils/user/User.utils";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";

const UserCreateOrEditPage: React.FC = () => {
  const { user, axios } = useUser();
  const location = useLocation<{ user?: SchambeckUser }>();
  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const [editUser, setEditUser] = useState<SchambeckUser>(
    location.state?.user || generateEmptySchambeckUser(user.id)
  );
  const [carToUpdate, setCarToUpdate] = useState<Car>();
  const [selectedWorkingDays, setSelectedWorkingDays] = useState<DayOfWeek[]>(
    []
  );
  const [selectedQualification, setSelectedQualification] =
    useState<string>("");
  const [createdPreciseEntries, setPreciseEntries] = useState<
    Map<DayOfWeek, number>
  >(new Map<DayOfWeek, number>());
  const [bikeToEdit, setBikeToEdit] = useState<Bike>();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { data: offices } = useData("OFFICES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: users } = useData("SCHAMBECK_USERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: roles } = useData("ROLES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: customers } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: cars } = useData("CARS_ALL", { config: { fallbackData: [] } });
  const { data: bikes } = useData("BIKES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedQualifications } = useData("QUALIFICATIONS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allUserFunctions } = useData("USER_FUNCTION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allProject } = useData("PROJECT_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allAbsenceReasons } = useData("ABSENCE_REASONS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allUserSchedules, mutate: updateUserSchedules } = useData(
    "SCHEDULE_USER_ALL",
    {
      config: { fallbackData: [] },
      params: { referenceId: editUser.id },
    }
  );

  const { data: loadedLanguages } = useData("USER_LANGUAGE_ALL", {
    config: { fallbackData: [] },
  });

  const [scheduleToEdit, setScheduleToEdit] = useState<Schedule>();

  /**
   * Dropdown options for userLanguages
   */
  const userLanguageOptions = useMemo(
    (): Option[] => generateDropdownOptions(loadedLanguages, "name", "id"),
    [loadedLanguages]
  );

  // TableRows to display the time in hours, that the user worked for different customers
  const customerTime: TableRow[] = useMemo((): TableRow[] => {
    const rows: TableRow[] = [];
    editUser.customerTimes.forEach((value, customerId) =>
      rows.push({
        id: customerId,
        content: [
          customers.find((customer) => customer.id === customerId)?.name ||
            t("pages.user.create.noCustomer"),
          (value / 60).toFixed(1),
        ],
      })
    );
    return rows;
  }, [editUser.customerTimes, customers, t]);

  // initialize the qualification filter with the first value
  useEffect(() => {
    if (selectedQualification || allUserFunctions.length === 0) return;
    setSelectedQualification(allUserFunctions[0].id);
  }, [allUserFunctions, selectedQualification]);

  /**
   * Qualifications to be displayed based on the active filter
   */
  const qualificationsToDisplay: WorkQualification[] = useMemo(
    () =>
      loadedQualifications.filter(
        (quali) => quali.userFunctionId === selectedQualification
      ),
    [loadedQualifications, selectedQualification]
  );

  /**
   * Holds the entries needed to display the agenda
   */
  const agendaEntries: AgendaEntryGroup[] = useMemo((): AgendaEntryGroup[] => {
    if (!allUserSchedules) return [];
    return generateUserAgendaScheduleEntries(
      allUserSchedules,
      editUser.id,
      allProject,
      allAbsenceReasons
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProject, editUser, allUserSchedules]);

  //UseEffect to set selected working Days based on the users contractInformation
  useEffect(() => {
    setSelectedWorkingDays(
      editUser.contractInformation.workSchedules
        .filter((scheduleDay) => scheduleDay.workingHours !== 0)
        .map((schedule) => schedule.dayOfWeek)
    );
  }, [editUser]);
  /**
   * Helper method to convert roleOptions into an array of options
   * @returns array of options with the label as name and id as value
   */
  const generateRoleOptions = (): Option[] =>
    roles
      .filter((role) => !role.disabled)
      .map((role) => ({ label: role.name, value: role.id }));
  /**
   * Helper method to generate dropdown options from cars
   * @returns Array of options
   */
  const generateCarOptions = useCallback((): Option[] => {
    const options: Option[] = cars
      .filter(
        (car) =>
          car.id === editUser.contractInformation.carId || car.userId === ""
      )
      .map((filteredCar) => {
        return {
          label: `${filteredCar.registrationPlate}, ${filteredCar.brand} ${filteredCar.model}`,
          value: filteredCar.id,
        };
      });
    options.push({ label: "", value: "" });
    return options;
  }, [editUser, cars]);

  const generateBikeOptions = (): Option[] =>
    bikes
      .filter((bike) => !bike.deactivated)
      .map((bike) => ({ label: bike.description, value: bike.id }));
  /**
   * Helper method to handle the change of a car
   * @param carId of the selected Car
   * @returns void
   */
  const handleChangeCar = (carId: string): void => {
    setEditUser({
      ...editUser,
      contractInformation: { ...editUser.contractInformation, carId: carId },
    });
    const selectedCar: Car | undefined = cars.find((car) => car.id === carId);
    if (!selectedCar) return;
    setCarToUpdate({ ...selectedCar, userId: user.id });
  };
  /**
   * Helper method to save or edit user
   * updates or creates user depending on isEdit
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = async (redirect: boolean): Promise<void> => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    const userToSave: SchambeckUser = generateWorkTimeSchedulesForUser(
      editUser,
      selectedWorkingDays,
      createdPreciseEntries
    );
    if (carToUpdate) await updateCar(axios, carToUpdate);
    if (bikeToEdit) await updateBike(axios, bikeToEdit);
    if (
      userToSave.contractInformation.workScheduleDistribution ===
        WorkScheduleDistribution.DAILY &&
      userToSave.contractInformation.workSchedules.length === 0
    ) {
      generateNotification({
        value: t("general.notification.error.worktimeDistribution"),
        type: NotificationType.WARNING,
      });
      return;
    }
    userToSave.id
      ? updateSchambeckUser(userToSave, axios).then((savedUser) => {
          if (savedUser && redirect) navigate(-1);
          else if (savedUser) {
            setEditUser(savedUser);
            generateNotification({
              value: t("general.notification.success.saveSuccessfull"),
              type: NotificationType.SUCCESS,
            });
          }
        })
      : createSchambeckUser(userToSave, axios).then((savedUser) => {
          if (savedUser && redirect) {
            navigate(-1);
          } else if (savedUser) {
            setEditUser(savedUser);
            generateNotification({
              value: t("general.notification.success.saveSuccessfull"),
              type: NotificationType.SUCCESS,
            });
          }
        });
  };

  /**
   * Helper to create a new schedule entry on the backend and reset the local state
   */
  const handleScheduleCreate = (): void => {
    if (!scheduleToEdit) return;
    if (!scheduleToEdit.id)
      generateNewSchedule(scheduleToEdit, axios).then((schedule) => {
        if (!schedule) return;
        updateUserSchedules([schedule]);
        setScheduleToEdit(undefined);
      });
    else {
      updateSchedule(axios, scheduleToEdit).then((schedule) => {
        if (!schedule) return;
        updateUserSchedules([schedule]);
        setScheduleToEdit(undefined);
      });
    }
  };
  /**
   * Helper method to delete a schedule
   */
  const handleDeleteSchedule = (): void => {
    if (!scheduleToEdit || !scheduleToEdit.id) return;
    deleteSchedule(axios, scheduleToEdit.id).then((success) => {
      success &&
        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.deleteSchedule"),
        });
      setScheduleToEdit(undefined);
      updateUserSchedules();
    });
  };

  /**
   * Helper method to add or remove a qualification
   * @param qualificationId id of the qualification to remove or add
   */
  const handleQualificationCheck = (qualificationId: string): void => {
    if (editUser.workQualifications?.includes(qualificationId))
      setEditUser({
        ...editUser,
        workQualifications: editUser.workQualifications.filter(
          (quali) => quali !== qualificationId
        ),
      });
    else
      setEditUser({
        ...editUser,
        workQualifications: [...editUser.workQualifications, qualificationId],
      });
  };

  /**
   * Helper method to update the contractInformation
   * @param key of cintractInfo to update
   * @param newValue new value for the key
   */
  const updateContract = (
    key: keyof ContractInformation,
    newValue: Date | string | number | boolean | undefined
  ): void => {
    const contractInformation: ContractInformation = {
      ...editUser.contractInformation,
      [key]: newValue,
    };
    setEditUser((old) => ({ ...old, contractInformation }));
  };

  return isUserAllowedToDo(user.right, Right.USER_CREATE) ? (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <NavigationAnchor id="main-information" />
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          editUser.firstName + editUser.lastName === ""
            ? t("pages.user.create.newUser")
            : `${editUser.firstName} ${editUser.lastName}`
        }
      >
        <div className="user-create__topbar-content hide-on-small">
          <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
          <Button
            value={<TimeIcon width={20} />}
            onClick={() =>
              navigate("/timekeeping", { state: { userId: editUser.id } })
            }
          />
          <Dropdown
            options={[
              {
                label: t("pages.user.create.mainInformation"),
                value: "main-information",
              },
              {
                label: t("pages.user.create.contactInformation"),
                value: "contact-information",
              },
              {
                label: t("pages.user.create.privateInformation"),
                value: "private-information",
              },
              {
                label: t("pages.user.create.bankInformation"),
                value: "bank-information",
              },
              {
                label: t("pages.user.create.contractInformation"),
                value: "contract-information",
              },
              {
                label: t("pages.user.create.workTimeInformation"),
                value: "worktime-information",
              },
              {
                label: t("pages.user.create.qualifications"),
                value: "qualification-information",
              },
              {
                label: t("pages.user.create.legend.car"),
                value: "car-information",
              },
              {
                label: t("pages.user.create.userTimes"),
                value: "time-information",
              },
              {
                label: t("pages.user.create.documents"),
                value: "documents",
              },
              {
                label: t("pages.user.create.schedules"),
                value: "schedules",
              },
            ]}
            onChange={(value) => {
              const elem = document.getElementById(value);
              elem?.scrollIntoView({ behavior: "smooth", block: "start" });
            }}
            selectedOption={"main-information"}
          />
        </div>
      </TopBar>
      <Box>
        <fieldset className="three-columns">
          <legend className="legend">
            {t("pages.user.create.legend.mainInformation")}
          </legend>
          <Dropdown
            label={t("pages.user.create.gender.label")}
            selectedOption={editUser.gender}
            options={Object.values(Gender).map((gender) => ({
              label: t(`pages.user.create.gender.${gender}`),
              value: gender,
            }))}
            onChange={(gender) =>
              setEditUser({ ...editUser, gender: gender as Gender })
            }
          />
          <Input
            type="text"
            value={editUser.title}
            onChange={(title) => setEditUser({ ...editUser, title })}
            label={t("pages.user.create.title")}
          />
          <Input
            required
            type="text"
            value={editUser.firstName}
            onChange={(firstName) => setEditUser({ ...editUser, firstName })}
            label={t("pages.user.create.firstName")}
          />
          <Input
            required
            type="text"
            value={editUser.lastName}
            onChange={(lastName) => setEditUser({ ...editUser, lastName })}
            label={t("pages.user.create.lastName")}
          />
          <TextArea
            value={editUser.annotation || ""}
            onChange={(annotation) => setEditUser({ ...editUser, annotation })}
            label={t("pages.user.create.annotation")}
          />
          <Input
            type="text"
            value={editUser.personalNumber}
            onChange={(personalNumber) =>
              setEditUser({ ...editUser, personalNumber })
            }
            label={t("pages.user.create.personalNumber")}
          />
          <Checkbox
            label={t("pages.user.create.active")}
            isChecked={editUser.active}
            onCheck={(active) => setEditUser({ ...editUser, active })}
          />
          <Dropdown
            label={t("pages.user.create.office")}
            onChange={(officeId) =>
              setEditUser({
                ...editUser,
                contractInformation: {
                  ...editUser.contractInformation,
                  officeId,
                },
              })
            }
            selectedOption={editUser.contractInformation.officeId}
            options={offices.map((office) => ({
              label: office.name,
              value: office.id,
            }))}
          />
          <Dropdown
            label={t("pages.user.create.currentOffice")}
            onChange={(currentOfficeId) =>
              setEditUser({
                ...editUser,
                contractInformation: {
                  ...editUser.contractInformation,
                  currentOfficeId,
                },
              })
            }
            selectedOption={editUser.contractInformation.currentOfficeId}
            options={offices.map((office) => ({
              label: office.name,
              value: office.id,
            }))}
          />
          <Dropdown
            label={t("pages.user.create.supervisor")}
            selectedOption={editUser.supervisorId}
            options={generateDropdownOptions(allUserFunctions, "title", "id")}
            onChange={(supervisorId) =>
              setEditUser({ ...editUser, supervisorId: supervisorId })
            }
          />
          <Dropdown
            label={t("pages.user.create.deputy")}
            selectedOption={editUser.deputyId}
            options={generateDropdownOptions(allUserFunctions, "title", "id")}
            onChange={(deputyId) => setEditUser({ ...editUser, deputyId })}
          />
          {getSubordinatesForUser(users, editUser.id).length > 0 && (
            <div>
              <p>{t("pages.user.create.supervisorOf")}</p>
              <ul>
                {getSubordinatesForUser(users, editUser.id).map(
                  (subordinateUser) => (
                    <li
                      key={`subordinate-user-${subordinateUser.id}`}
                    >{`${subordinateUser.firstName} ${subordinateUser.lastName}`}</li>
                  )
                )}
              </ul>
            </div>
          )}

          <NavigationAnchor id="contact-information" />
        </fieldset>
      </Box>
      <Box title={t("pages.user.create.sami")}>
        <div className="three-columns">
          <Input
            value={editUser.username}
            onChange={(username) => setEditUser({ ...editUser, username })}
            type="text"
            label={t("pages.user.create.username")}
          />
          <Input
            value={editUser.password}
            onChange={(password) => setEditUser({ ...editUser, password })}
            type="text"
            label={t("pages.user.create.password")}
          />
          <Dropdown
            multi
            onChangeMultiple={(availableOffices) =>
              setEditUser((old) => ({ ...old, availableOffices }))
            }
            options={generateDropdownOptions(offices, "name", "id")}
            label={t("pages.user.create.availableOffices")}
            selectedMultiOptions={editUser.availableOffices}
          />
          <Dropdown
            label={t("pages.user.create.userRole")}
            options={generateRoleOptions()}
            onChange={(newRole) =>
              setEditUser({
                ...editUser,
                right: {
                  ...editUser.right,
                  role: roles.filter((role) => role.id === newRole)[0],
                },
              })
            }
            selectedOption={editUser?.right?.role?.id || ""}
          />
          <div className="user-create__password-info__wrapper">
            <p className="user-create__password-info__wrapper__title">
              {t("pages.user.create.passwordPolicy.title")}
            </p>
            <ul className="user-create__password-info__wrapper__list">
              <li>{t("pages.user.create.passwordPolicy.characters")}</li>
              <li>{t("pages.user.create.passwordPolicy.specialCharacters")}</li>
              <li>{t("pages.user.create.passwordPolicy.letterCasing")}</li>
              <li>{t("pages.user.create.passwordPolicy.name")}</li>
              <li>{t("pages.user.create.passwordPolicy.schambeck")}</li>
              <li>{t("pages.user.create.passwordPolicy.lastTwo")}</li>
            </ul>
          </div>
        </div>
      </Box>
      <Box>
        <fieldset className="three-columns">
          <legend className="legend">
            {t("pages.user.create.legend.contactInformation")}
          </legend>
          <Input
            type="text"
            value={editUser.contact.phone}
            onChange={(phone) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, phone },
              })
            }
            label={t("pages.user.create.phone")}
          />
          <Input
            type="text"
            value={editUser.contact.mobile}
            onChange={(mobile) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, mobile },
              })
            }
            label={t("pages.user.create.mobile")}
          />{" "}
          <Input
            type="text"
            value={editUser.contact.fax || ""}
            onChange={(fax) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, fax },
              })
            }
            label={t("pages.user.create.fax")}
          />
          <Input
            type="email"
            value={editUser.contact.mail}
            onChange={(mail) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, mail },
              })
            }
            label={t("pages.user.create.mail")}
          />
          <Input
            type="text"
            value={editUser.contact.street}
            onChange={(street) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, street },
              })
            }
            label={t("pages.user.create.street")}
          />
          <Input
            type="text"
            value={editUser.contact.streetNumber}
            onChange={(streetNumber) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, streetNumber },
              })
            }
            label={t("pages.user.create.streetNumber")}
          />
          <Dropdown
            selectedOption={editUser.contact.country}
            label={t("pages.user.create.country")}
            onChange={(country) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, country: country as Country },
              })
            }
            options={Object.keys(Country).map((country) => ({
              label: t(`pages.user.create.countries.${country}`),
              value: country as Country,
            }))}
          />
          <Input
            type="text"
            value={editUser.contact.zip}
            onChange={(zip) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, zip },
              })
            }
            label={t("pages.user.create.zip")}
          />
          <Input
            type="text"
            value={editUser.contact.city}
            onChange={(city) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, city },
              })
            }
            label={t("pages.user.create.city")}
          />
          <NavigationAnchor id="private-information" />
        </fieldset>
      </Box>
      <Box>
        <fieldset className="three-columns">
          <legend className="legend">
            {t("pages.user.create.legend.contactInformationPrivate")}
          </legend>
          <Input
            type="text"
            value={editUser.contact.phonePrivate}
            onChange={(phonePrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, phonePrivate },
              })
            }
            label={t("pages.user.create.phone")}
          />
          <Input
            type="text"
            value={editUser.contact.mobilePrivate}
            onChange={(mobilePrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, mobilePrivate },
              })
            }
            label={t("pages.user.create.mobile")}
          />
          <Input
            type="email"
            value={editUser.contact.mailPrivate}
            onChange={(mailPrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, mailPrivate },
              })
            }
            label={t("pages.user.create.mail")}
          />
          <Input
            type="text"
            value={editUser.contact.streetPrivate}
            onChange={(streetPrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, streetPrivate },
              })
            }
            label={t("pages.user.create.street")}
          />
          <Input
            type="text"
            value={editUser.contact.streetNumberPrivate}
            onChange={(streetNumberPrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, streetNumberPrivate },
              })
            }
            label={t("pages.user.create.streetNumber")}
          />
          <Dropdown
            selectedOption={editUser.contact.countryPrivate}
            label={t("pages.user.create.country")}
            onChange={(country) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, country: country as Country },
              })
            }
            options={Object.keys(Country).map((country) => ({
              label: t(`pages.user.create.countries.${country}`),
              value: country as Country,
            }))}
          />
          <Input
            type="text"
            value={editUser.contact.zipPrivate}
            onChange={(zipPrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, zipPrivate },
              })
            }
            label={t("pages.user.create.zip")}
          />
          <Input
            type="text"
            value={editUser.contact.cityPrivate}
            onChange={(cityPrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, cityPrivate },
              })
            }
            label={t("pages.user.create.city")}
          />
          <NavigationAnchor id="private-information" />
        </fieldset>
      </Box>
      <Box>
        <fieldset className="three-columns">
          <legend className="legend">
            {t("pages.user.create.legend.privateInformation")}
          </legend>
          <Input
            type="date"
            required
            value={editUser.privateInformation?.birthDate || new Date()}
            onChangeDate={(birthDate) => {
              if (birthDate)
                setEditUser({
                  ...editUser,
                  privateInformation: {
                    ...editUser.privateInformation,
                    birthDate,
                  },
                });
            }}
            label={t("pages.user.create.birthDate")}
          />
          <Input
            type="text"
            value={editUser.privateInformation.birthCity}
            onChange={(birthCity) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  birthCity,
                },
              })
            }
            label={t("pages.user.create.birthCity")}
          />
          <Input
            type="text"
            value={editUser.privateInformation.passportNumber}
            onChange={(passportNumber) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  passportNumber,
                },
              })
            }
            label={t("pages.user.create.passportNumber")}
          />
          <Input
            type="date"
            value={
              editUser.privateInformation.residencePermitEndDate || new Date()
            }
            onChangeDate={(residencePermitEndDate) => {
              if (residencePermitEndDate)
                setEditUser({
                  ...editUser,
                  privateInformation: {
                    ...editUser.privateInformation,
                    residencePermitEndDate,
                  },
                });
            }}
            label={t("pages.user.create.residencePermitStartDate")}
          />
          <Input
            type="date"
            value={editUser.privateInformation.workPermitEndDate || new Date()}
            onChangeDate={(workPermitEndDate) => {
              if (workPermitEndDate)
                setEditUser({
                  ...editUser,
                  privateInformation: {
                    ...editUser.privateInformation,
                    workPermitEndDate,
                  },
                });
            }}
            label={t("pages.user.create.workPermitEndDate")}
          />
          <NavigationAnchor id="bank-information" />
          <Dropdown
            options={Object.values(EducationQualification).map((type) => ({
              label: t(`general.educationQualifications.${type}`),
              value: type,
            }))}
            selectedOption={editUser.privateInformation.educationQualification}
            label={t("pages.user.create.educationQualification")}
            onChange={(type) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  educationQualification: type as EducationQualification,
                },
              })
            }
          />
          <Dropdown
            options={Object.values(DriverLicenseType).map((type) => ({
              label: t(`general.driverLicenseTypes.${type}`),
              value: type,
            }))}
            selectedOption={editUser.privateInformation.driverLicenseType}
            label={t("pages.user.create.driverLicenseType")}
            onChange={(type) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  driverLicenseType: type as DriverLicenseType,
                },
              })
            }
          />
          <Dropdown
            options={Object.values(RequiredState).map((req) => ({
              label: t(`general.requiredStates.${req}`),
              value: req,
            }))}
            selectedOption={editUser.privateInformation.driverLicenseCopyState}
            label={t("pages.user.create.driverLicenseCopyState")}
            onChange={(state) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  driverLicenseCopyState: state as RequiredState,
                },
              })
            }
          />
          <Input
            label={t(`pages.user.create.contract.yearlyStackerIntro`)}
            value={editUser.contractInformation.yearlyStackerIntro}
            onChangeDate={(newValue) =>
              updateContract("yearlyStackerIntro", newValue)
            }
            type="date"
          />
          <Input
            label={t(`pages.user.create.contract.eyeTestDate`)}
            value={editUser.contractInformation.eyeTestDate}
            onChangeDate={(newValue) => updateContract("eyeTestDate", newValue)}
            type="date"
          />
          <Input
            label={t(`pages.user.create.contract.eyeTestDeadline`)}
            value={editUser.contractInformation.eyeTestDeadline}
            onChangeNumber={(newValue) =>
              updateContract("eyeTestDeadline", newValue)
            }
            type="number"
            minValue={0}
            maxValue={36}
          />
        </fieldset>
      </Box>
      <Box title={t("pages.user.create.secretInformation")}>
        <div className="three-columns">
          {" "}
          <Input
            label={t(`pages.user.create.contract.salary`)}
            value={editUser.contractInformation.salary}
            onChangeNumber={(newValue) => updateContract("salary", newValue)}
            type="number"
          />{" "}
          <Input
            type="text"
            value={editUser.privateInformation.taxId}
            onChange={(taxId) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  taxId,
                },
              })
            }
            label={t("pages.user.create.taxId")}
          />
          <Checkbox
            isChecked={editUser.privateInformation.children}
            onCheck={(children) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  children,
                },
              })
            }
            label={t("pages.user.create.children")}
          />
          <Input
            type="text"
            value={editUser.privateInformation.healthInsurance}
            onChange={(healthInsurance) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  healthInsurance,
                },
              })
            }
            label={t("pages.user.create.healthInsurance")}
          />
          <Input
            type="text"
            value={editUser.privateInformation.religion}
            onChange={(religion) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  religion,
                },
              })
            }
            label={t("pages.user.create.religion")}
          />
          <Dropdown
            options={Object.values(TaxClass).map((tax) => ({
              label: t(`pages.user.create.taxClasses.${tax}`),
              value: tax,
            }))}
            selectedOption={editUser.privateInformation.taxClass}
            label={t("pages.user.create.taxClass")}
            onChange={(tax) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  taxClass: tax as TaxClass,
                },
              })
            }
          />
          <Dropdown
            options={Object.values(FamilyState).map((fam) => ({
              label: t(`general.familyStates.${fam}`),
              value: fam,
            }))}
            selectedOption={editUser.privateInformation.familyState}
            label={t("pages.user.create.familyState")}
            onChange={(state) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  familyState: state as FamilyState,
                },
              })
            }
          />
          <Input
            type="text"
            value={editUser.privateInformation.bankAccount.owner}
            onChange={(owner) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  bankAccount: {
                    ...editUser.privateInformation.bankAccount,
                    owner,
                  },
                },
              })
            }
            label={t("pages.user.create.bankAccount.owner")}
          />
          <Input
            type="text"
            value={editUser.privateInformation.bankAccount.bic}
            onChange={(bic) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  bankAccount: {
                    ...editUser.privateInformation.bankAccount,
                    bic,
                  },
                },
              })
            }
            label={t("pages.user.create.bankAccount.bic")}
          />
          <Input
            type="text"
            value={editUser.privateInformation.disabledLevel}
            onChange={(disabledLevel) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  disabledLevel,
                },
              })
            }
            label={t("pages.user.create.disabledLevel")}
          />
          <Checkbox
            isChecked={editUser.privateInformation.severlyDisabled}
            onCheck={(severlyDisabled) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  severlyDisabled,
                },
              })
            }
            label={t("pages.user.create.severlyDisabled")}
          />
        </div>
      </Box>
      <Box>
        <fieldset>
          <legend className="legend">
            {t("pages.user.create.legend.contractInformation")}
          </legend>
          <p className="user-create__contract-category">
            {" "}
            {t("pages.user.create.legend.jobInformation")}
          </p>
          <div className="three-columns">
            <Dropdown
              options={generateDropdownOptions(allUserFunctions, "title", "id")}
              selectedOption={editUser.userFunctionId}
              label={t("pages.user.create.userFunction")}
              onChange={(userFunctionId) =>
                setEditUser({ ...editUser, userFunctionId })
              }
            />
            <Dropdown
              label={t(`pages.user.create.contract.employmentTypeTitle`)}
              onChange={(newValue) =>
                updateContract("employmentType", newValue)
              }
              selectedOption={editUser.contractInformation.employmentType}
              options={Object.keys(EmploymentType).map((type) => ({
                label: t(`pages.user.create.contract.employmentType.${type}`),
                value: type,
              }))}
            />
            <Dropdown
              label={t(`pages.user.create.contract.position`)}
              onChange={(newValue) => updateContract("position", newValue)}
              selectedOption={editUser.contractInformation.position}
              options={Object.keys(Position).map((pos) => ({
                label: t(`pages.user.create.contract.${pos}`),
                value: pos,
              }))}
            />
            <Input
              label={t(`pages.user.create.contract.entryDate`)}
              value={editUser.contractInformation.entryDate}
              onChangeDate={(newValue) => updateContract("entryDate", newValue)}
              type="date"
            />
            <Input
              label={t(`pages.user.create.contract.leavingDate`)}
              value={editUser.contractInformation.leavingDate}
              onChangeDate={(newValue) =>
                updateContract("leavingDate", newValue)
              }
              type="date"
            />
            <Input
              label={t(`pages.user.create.contract.reentryDate`)}
              value={editUser.contractInformation.reentryDate}
              onChangeDate={(newValue) =>
                updateContract("reentryDate", newValue)
              }
              type="date"
            />
            {editUser.contractInformation.employmentHistory &&
              Array.from(
                editUser.contractInformation.employmentHistory.entries()
              ).map(([key, value]) => (
                <p>
                  {key.toLocaleDateString()}:{" "}
                  {t(`general.employmentChange.${value}`)}
                </p>
              ))}
            <Input
              label={t(`pages.user.create.contract.holidayAmount`)}
              value={editUser.contractInformation.holidayAmount}
              onChangeNumber={(newValue) =>
                updateContract("holidayAmount", newValue)
              }
              type="number"
            />
          </div>{" "}
          <p className="user-create__contract-category">
            {t(`pages.user.create.contract.workTime`)}
          </p>
          <div>
            <WorkScheduleEdit
              updateUser={(contractInformation) =>
                setEditUser((old) => ({ ...old, contractInformation }))
              }
              contractInformation={editUser.contractInformation}
              createdPreciseEntries={createdPreciseEntries}
              selectedDays={selectedWorkingDays}
              setPreciseEntries={setPreciseEntries}
              setSelectedDays={setSelectedWorkingDays}
            />
          </div>
          <p className="user-create__contract-category">
            {" "}
            {t(`pages.user.create.contract.furtherInformation`)}
          </p>
          <div className="three-columns">
            <Input
              label={t(`pages.user.create.contract.propationEndDate`)}
              value={editUser.contractInformation.propationEndDate}
              onChangeDate={(newValue) =>
                updateContract("propationEndDate", newValue)
              }
              type="date"
            />{" "}
            <Input
              label={t(`pages.user.create.contract.vwl`)}
              value={editUser.contractInformation.vwl}
              disabled={!editUser.contractInformation.secondaryActivityAllowed}
              onChange={(newValue) => updateContract("vwl", newValue)}
              type="text"
            />
            <div>
              <Checkbox
                label={t(`pages.user.create.contract.secondaryActivityAllowed`)}
                isChecked={
                  editUser.contractInformation.secondaryActivityAllowed
                }
                onCheck={(checked) =>
                  updateContract("secondaryActivityAllowed", checked)
                }
              />
              <Checkbox
                isChecked={
                  editUser.contractInformation.capitalAccumulationAllowed
                }
                label={t(
                  `pages.user.create.contract.capitalAccumulationAllowed`
                )}
                onCheck={(checked) =>
                  updateContract("capitalAccumulationAllowed", checked)
                }
              />
              <Checkbox
                label={t(`pages.user.create.contract.propationFinished`)}
                isChecked={editUser.contractInformation.propationFinished}
                onCheck={(checked) =>
                  updateContract("propationFinished", checked)
                }
              />
            </div>{" "}
            <Input
              label={t(`pages.user.create.contract.azk`)}
              value={editUser.contractInformation.azk}
              onChange={(newValue) => updateContract("azk", newValue)}
              type="text"
            />{" "}
            <Dropdown
              disabled={!isUserAllowedToDo(user.right, Right.CAR_EDIT)}
              options={generateCarOptions()}
              selectedOption={editUser.contractInformation.carId}
              onChange={handleChangeCar}
              label={t("pages.user.create.car")}
            />
            <Dropdown
              label={t("pages.user.create.bike")}
              selectedOption={editUser.contractInformation.bycicleId}
              options={generateBikeOptions()}
              onChange={(bycicleId) => {
                setEditUser({
                  ...editUser,
                  contractInformation: {
                    ...editUser.contractInformation,
                    bycicleId,
                  },
                });
                setBikeToEdit(
                  bikes.find((bycicle) => bycicle.id === bycicleId)
                );
              }}
            />
          </div>
          <TextArea
            value={editUser.contractInformation.description}
            onChange={(newValue) => updateContract("description", newValue)}
            label={t(`pages.user.create.contract.description`)}
          />
        </fieldset>
      </Box>
      <Box
        title={
          <div className="user-create__box-title-wrapper">
            <p className="box__title">
              {t("pages.user.create.qualifications")}
            </p>
          </div>
        }
      >
        <fieldset className="three-columns">
          <NavigationAnchor id="qualification-information" />
          <Dropdown
            options={generateDropdownOptions(allUserFunctions, "title", "id")}
            label={t("pages.user.create.type")}
            selectedOption={selectedQualification}
            onChange={(quali) => setSelectedQualification(quali)}
          />
          <Dropdown
            label={t("pages.user.create.userLanguages")}
            options={userLanguageOptions}
            multi
            onChangeMultiple={(userLanguages) =>
              setEditUser({ ...editUser, userLanguages })
            }
          />
          {qualificationsToDisplay.map((qualification) => (
            <Checkbox
              isChecked={editUser.workQualifications?.includes(
                qualification.id
              )}
              label={qualification.name}
              onCheck={() => handleQualificationCheck(qualification.id)}
            />
          ))}
        </fieldset>
      </Box>
      <NavigationAnchor id="time-information" />
      {editUser.id && (
        <Box title={t("pages.user.create.documents")}>
          <FileOverview
            bucket={DocumentBucket.USER}
            path={`${editUser.id}/`}
            userId={user.id}
          />
        </Box>
      )}
      <NavigationAnchor id="documents" />
      <Box title={t("pages.user.create.schedules")}>
        <Agenda
          handleEdit={(itemId) =>
            setScheduleToEdit(
              allUserSchedules.find((schedule) => schedule.id === itemId)
            )
          }
          entryGroups={agendaEntries}
          addItem={(clickTarget) =>
            setScheduleToEdit(
              generateEmptySchedule({
                scheduleDate: new Date(clickTarget.start),
                referenceId: editUser.id,
                appointmentDetail: generateEmptyAppointmentDetails(),
              })
            )
          }
        />
        {scheduleToEdit && (
          <div className="three-columns">
            <Input
              label={t("pages.user.create.schedule.title")}
              value={scheduleToEdit.appointmentDetail?.title}
              onChange={(title) =>
                setScheduleToEdit((old) => ({
                  ...old!,
                  appointmentDetail: { ...old!.appointmentDetail!, title },
                }))
              }
              type="text"
            />
            <Input
              label={t("pages.user.create.schedule.location")}
              value={scheduleToEdit.appointmentDetail?.location}
              onChange={(location) =>
                setScheduleToEdit((old) => ({
                  ...old!,
                  appointmentDetail: { ...old!.appointmentDetail!, location },
                }))
              }
              type="text"
            />
            <Checkbox
              label={t("pages.user.create.schedule.entireDay")}
              isChecked={!!scheduleToEdit.appointmentDetail?.entireDay}
              onCheck={(entireDay) =>
                setScheduleToEdit((old) => ({
                  ...old!,
                  appointmentDetail: {
                    ...old!.appointmentDetail!,
                    entireDay,
                  },
                }))
              }
            />
            <Input
              label={t("pages.user.create.schedule.scheduleDay")}
              type="date"
              onChangeDate={(scheduleDate) =>
                setScheduleToEdit((old) => ({
                  ...old!,
                  scheduleDate: scheduleDate || new Date(),
                }))
              }
              value={scheduleToEdit.scheduleDate}
            />
            {scheduleToEdit.appointmentDetail?.entireDay || (
              <>
                <Input
                  label={t("pages.user.create.schedule.startTime")}
                  value={scheduleToEdit.appointmentDetail?.startTime}
                  onChangeTime={(startTime) =>
                    setScheduleToEdit((old) => ({
                      ...old!,
                      appointmentDetail: {
                        ...old!.appointmentDetail!,
                        startTime,
                      },
                    }))
                  }
                  type="time"
                />
                <Input
                  label={t("pages.user.create.schedule.endTime")}
                  value={scheduleToEdit.appointmentDetail?.endTime}
                  onChangeTime={(endTime) =>
                    setScheduleToEdit((old) => ({
                      ...old!,
                      appointmentDetail: {
                        ...old!.appointmentDetail!,
                        endTime,
                      },
                    }))
                  }
                  type="time"
                />
              </>
            )}
            <Dropdown
              label={t("pages.user.create.schedule.reasonId")}
              options={generateDropdownOptions(allAbsenceReasons, "name", "id")}
              selectedOption={scheduleToEdit.appointmentDetail?.reasonId}
              onChange={(reasonId) =>
                setScheduleToEdit((old) => ({
                  ...old!,
                  appointmentDetail: {
                    ...old!.appointmentDetail!,
                    reasonId,
                  },
                }))
              }
            />
            <Dropdown
              label={t("pages.user.create.schedule.state")}
              options={Object.keys(AppointmentState).map((state) => ({
                label: t(`pages.user.create.schedule.states.${state}`),
                value: state,
              }))}
              selectedOption={scheduleToEdit.appointmentDetail?.state}
              onChange={(state: AppointmentState) =>
                setScheduleToEdit((old) => ({
                  ...old!,
                  appointmentDetail: {
                    ...old!.appointmentDetail!,
                    state,
                  },
                }))
              }
            />
            <div className="schedule-button-wrapper">
              <Button
                value={t(
                  `pages.user.create.schedule.${
                    scheduleToEdit.id ? "editButton" : "createButton"
                  }`
                )}
                onClick={handleScheduleCreate}
                type="button"
              />
              {scheduleToEdit.id && (
                <Button
                  value={t(`pages.user.create.schedule.deleteButton`)}
                  onClick={handleDeleteSchedule}
                  type="button"
                  backgroundColor="#bc2e46"
                />
              )}
            </div>
          </div>
        )}
      </Box>
      <Box title={t("pages.user.create.userTimes")}>
        <Table
          rows={customerTime}
          header={
            t("pages.user.create.userTimesTableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
      <NavigationAnchor id="schedules" />
      <p className="footnote">
        {t("pages.user.create.changeHistory", {
          replace: {
            createDate: editUser.createDate.toLocaleDateString("DE-de"),
            createdBy: getUserNameForSimpleUser(editUser.createdBy, users),
            lastUpdated:
              editUser.lastUpdated?.toLocaleDateString("DE-de") ?? "",
            updatedBy: getUserNameForSimpleUser(
              editUser.updatedBy ?? "",
              users
            ),
          },
        })}
      </p>
    </form>
  ) : (
    <NotAllowed neccessaryRight={Right.USER_CREATE} />
  );
};
export default UserCreateOrEditPage;
