import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CrmManageType, useData } from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { convertCrmManageEntriesIntoTableEntries } from "../../utils/crm/Crm.utils";

export const CrmManageOverview: React.FC<{ type: CrmManageType }> = ({
  type,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: allTypes } = useData("CRM_TYPES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allActions } = useData("CRM_ACTIONS_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * TableRows containing all crm manage entries
   */
  const rows: TableRow[] = useMemo((): TableRow[] => {
    if (type === CrmManageType.ACTION)
      return convertCrmManageEntriesIntoTableEntries(allActions, navigate);
    else return convertCrmManageEntriesIntoTableEntries(allTypes, navigate);
  }, [allActions, allTypes, navigate, type]);

  return (
    <>
      <TopBar title={t(`pages.crmManage.overview.topBarHeadline_${type}`)}>
        <PlusIcon width={30} onClick={() => navigate("edit")} />
      </TopBar>
      <Box>
        <Table
          rows={rows}
          header={
            t("pages.crmManage.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
