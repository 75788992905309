import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useData } from "shared";
import { getProjectDescriptionPdf } from "shared/src/project/Project.axios";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { downloadFile } from "../../utils/files/Files.utils";
import { convertProjectsIntoTableEntries } from "../../utils/project/Project.utils";

export const ProjectOverview: React.FC = () => {
  const { axios } = useUser();
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<string>("");

  const { data: loadedProjects } = useData("PROJECT_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomers } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerUsers } = useData("CUSTOMER_USERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });

  const navigate = useNavigate();

  /**
   * Helper method to download a preojectDescription
   */
  const downloadDescription = useCallback((projectId: string): void => {
    getProjectDescriptionPdf(axios, projectId).then(
      (loadedFile) =>
        loadedFile &&
        downloadFile(loadedFile, t("pages.project.overview.descriptionFileNae"))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Hook to convert projects into tableRows after all projects and customers are loaded
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertProjectsIntoTableEntries(
        loadedProjects,
        loadedCustomers,
        loadedCustomerLocations,
        loadedCustomerUsers,
        loadedOrders,
        navigate,
        downloadDescription
      ),
    [
      downloadDescription,
      loadedCustomerLocations,
      loadedCustomerUsers,
      loadedCustomers,
      loadedOrders,
      loadedProjects,
      navigate,
    ]
  );
  return (
    <>
      <TopBar
        title={t("pages.project.overview.topBarHeadline")}
        onSearch={setFilterValue}
      >
        <PlusIcon width={30} onClick={() => navigate("/project/create")} />
      </TopBar>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.project.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
