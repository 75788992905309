import { TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import { Bike, Right, UserRight } from "shared";
import { isUserAllowedToDo } from "../user/User.utils";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";
/**
 * Helper Method to convert Bike into TableRows
 * @param bikes to convert
 * @param navigate navigate method to implement in entries
 * @returns  Array of TableRows
 */
export const convertBikesIntoTableEntries = (
  userRights: UserRight,
  bikes: Bike[],
  navigate: NavigateFunction
): TableRow[] => {
  return bikes.map((bike) => ({
    id: bike.id,
    onClick: () => navigate(`/bikes/edit`, { state: { bike: bike } }),
    content: [
      bike.description,
      bike.assignedUserId === ""
        ? i18n.t("pages.bikes.overview.notAssigned")
        : i18n.t("pages.bikes.overview.assigned"),
      bike.deactivated
        ? i18n.t("pages.bikes.overview.inactive")
        : i18n.t("pages.bikes.overview.active"),
      <div>
        {isUserAllowedToDo(userRights, Right.BIKE_EDIT) && (
          <EditIcon
            width={40}
            onClick={(evt) => {
              evt.stopPropagation();
              navigate(`/bikes/edit`, { state: { bike: bike } });
            }}
          />
        )}
      </div>,
    ],
  }));
};

/**
 * Helper method to create an empty bike
 * @param userId of the user that is creating the bike
 * @returns  empty Bike
 */
export const generateEmptyBike = (userId: string): Bike => ({
  id: undefined!,
  assignedUserId: "",
  createDate: new Date(),
  createdBy: userId,
  deactivated: false,
  description: "",
  price: 0,
  officeId: "",
});
