import { AxiosInstance } from "axios";
import i18n from "../../../client/src/i18n/i18n";
import { NotificationType } from "../notification/notification.types";
import { generateNotification } from "../notification/notification.util";
import { InventoryItem, InventoryType } from "./Inventory.types";

/**
 * API method to create a new inventory item of the given implementation
 *
 * @param item The new item to create
 * @param axios The axios instance
 * @returns the newly created object or undefined in case of an error
 */
export const createNewInventoryItem = async <T extends InventoryItem>(
  item: T,
  axios: AxiosInstance
): Promise<T> => {
  return axios
    .post("/inventory/", item)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during inventory item creation", exc);
      generateNotification({
        value: i18n.t("general.notification.error.inventoryItemCreationError"),
        type: NotificationType.ERROR,
      });
    });
};

/**
 * API method to update an inventory item of the given implementation
 *
 * @param item The updated item
 * @param axios The axios instance
 * @returns the updated object or undefined in case of an error
 */
export const updateInventoryItem = async <T extends InventoryItem>(
  item: T,
  axios: AxiosInstance
): Promise<T> => {
  return axios
    .post("/inventory/update", item)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during inventory item update", exc);
      generateNotification({
        value: i18n.t("general.notification.error.inventoryItemUpdateError"),
        type: NotificationType.ERROR,
      });
    });
};

/**
 * API method to delete an inventory item of the given implementation
 *
 * @param item The item to delete
 * @param axios The axios instance
 */
export const deleteInventoryItem = async <T extends InventoryItem>(
  item: T,
  axios: AxiosInstance
): Promise<void> => {
  axios.post("/inventory/delete", item).catch((exc) => {
    console.error("Error during inventory item deletion", exc);
    generateNotification({
      value: i18n.t("general.notification.error.inventoryItemDeleteError"),
      type: NotificationType.ERROR,
    });
  });
};

/**
 * API method to load all inventory items for the given inventory type. The
 * return value generic has to be set accordingly
 *
 * @param type The requested inventory type
 * @param axios The axios instance
 * @returns All loaded items of the given type, which can be empty
 */
export const getAllInventoryItemsByType = async <T extends InventoryItem>(
  type: InventoryType,
  axios: AxiosInstance
): Promise<T[]> => {
  return axios
    .get("/inventory/type", { params: { type } })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during inventory fetch for type", type, exc);
      generateNotification({
        value: i18n.t("general.notification.error.inventoryLoadError"),
        type: NotificationType.ERROR,
      });
    });
};
