import { DeliveryNote } from "../deliveryNote/DeliveryNote.types";
import { Language } from "../Language/Language.types";
import { SmtpConfiguration } from "../office/Office.types";

/**
 * MailConfiguration that includes the neccessary data to send the mail in the smtp config and the receiver
 */
export interface MailConfiguration {
  smtpConfig: SmtpConfiguration;
  receiver: string;
  subject: string;
  receiverCC: string[];
  content: string;
  type: MailType;
  language?: Language;
}

/**
 * MailConfiguration together with the invoice to send
 */
export interface InvoiceMailConfiguration extends MailConfiguration {
  invoiceId: string;
  userId: string;
}

/**
 * MailConfiguration together with the project to send
 */
export interface OrderConfirmationMailConfiguration extends MailConfiguration {
  projectId: string;
}

/**
 * Request class including the mailConfig as well as the configuration needed
 * for sending offers
 */
export interface OfferMailConfiguration extends MailConfiguration {
  offerId: string;
  activeUser: string;
  isOfferTop?: boolean;
  isOfferBottom?: boolean;
}

/**
 * Request class including the mailConfig as well as the configuration needed
 * for sending a deliveryNote
 */
export interface DeliveryNoteMailConfiguration extends MailConfiguration {
  deliveryNote: DeliveryNote;
  activeUser: string;
}

/**
 * templates with content in multiple languages for a mailType
 */
export interface MailTemplate {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdated?: Date;
  updatedBy?: string;
  type: MailType;
  mailContent: Map<Language, string>;
  subject: Map<Language, string>;
}

/**
 * Different Mails that could be sent
 */
export enum MailType {
  CONNECTION_TEST = "CONNECTION_TEST",
  OFFER = "OFFER",
  INVOICE = "INVOICE",
  DELIVERY_NOTE = "DELIVERY_NOTE",
  ORDER_CONFIRMATION = "ORDER_CONFIRMATION",
}
