import { AxiosInstance } from "axios";
import { Task } from "../order/Order.types";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import { generateNotification } from "../notification/notification.util";
import { mapReplacer } from "..";
import { StandardPriceTypeAlreadyTakenException } from "../errors";

/**
 * API Method to get all tasks
 * @param axios instance of axios
 * @returns  List of found tasks
 */
export const getAllTasks = async (axios: AxiosInstance): Promise<Task[]> => {
  return axios
    .get("/task/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all tasks", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notifications.error.loadAllTasks"),
      });
      return [];
    });
};

/**
 * API Method to get all standard tasks
 * @param axios instance of axios
 * @returns  List of found tasks that are marked as "standard"
 */
export const getAllStandardTasks = async (
  axios: AxiosInstance
): Promise<Task[]> => {
  return axios
    .get("/task/all/standard")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all standard tasks", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notifications.error.loadAllTasks"),
      });
      return [];
    });
};

/**
 * API method to create a new task and store it on the db
 * @param axios instance of axios
 * @param task to create on the db
 * @returns created task or undefined on error
 * @throws StandardPriceTypeAlreadyTakenException in case the standard price check
 * on the backend was not successfull
 */
export const createNewTask = async (
  axios: AxiosInstance,
  task: Task
): Promise<Task | undefined> => {
  return axios
    .post("/task/", JSON.stringify(task, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating new Task", exc);
      if (exc.response.status === 409)
        throw new StandardPriceTypeAlreadyTakenException("");
      generateNotification({
        type: NotificationType.ERROR,
        value: "general.notification.error.createTask",
      });
      return undefined;
    });
};

/**
 * API method to update a task on the db
 * @param axios instance of axios
 * @param task updated tesk object
 * @returns  updated task or undefined on error
 * @throws StandardPriceTypeAlreadyTakenException in case the standard price check
 * on the backend was not successfull
 */
export const updateTask = async (
  axios: AxiosInstance,
  task: Task
): Promise<Task | undefined> => {
  return axios
    .post("/task/update", JSON.stringify(task, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while updating task", exc);
      if (exc.response.status === 409)
        throw new StandardPriceTypeAlreadyTakenException("");
      return undefined;
    });
};
