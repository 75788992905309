import { AxiosInstance } from "axios";
import { Project, Schedule } from "./Project.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import dayjs from "dayjs";
import { Language, ProjectProtocol, mapReplacer } from "..";

/**
 * API method to load all projects
 * @param axios instance of axios
 * @returns Array of all projects
 */
export const getAllProjects = async (
  axios: AxiosInstance
): Promise<Project[]> => {
  return axios
    .get("/project/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading projects", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadAllProjects"),
      });
      return [];
    });
};
/**
 * API method to load all active projects
 * @param axios instance of axios
 * @returns Array of all projects
 */
export const getAllActiveProjects = async (
  axios: AxiosInstance
): Promise<Project[]> => {
  return axios
    .get("/project/all/active")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading projects", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadAllProjects"),
      });
      return [];
    });
};

/**
 * API Method to complete a project and set the state to completed
 * @param axios instance of axios
 * @param projectId id of the project to complete
 * @returns completed Project
 */
export const completeProject = async (
  axios: AxiosInstance,
  projectId: string
): Promise<Project | undefined> => {
  return axios
    .post("/project/complete", projectId, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.completeProject"),
      });
      console.error("Error while completing project", exc);
      return undefined;
    });
};

/**
 * API Method to update a project
 * @param axios instance of axios
 * @param project updated project
 * @returns updated project or undefined on error
 */
export const updateProject = async (
  axios: AxiosInstance,
  project: Project
): Promise<Project | undefined> => {
  return axios
    .post("/project/update", project)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while updatingProject", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateProject"),
      });
      return undefined;
    });
};

/**
 * API method to deactivate a project
 * @param axios instance of axios
 * @param id id of the project to deactivate
 * @returns updated project or undefined on error
 */
export const deactivateProject = async (
  axios: AxiosInstance,
  id: string
): Promise<Project> => {
  return axios
    .post("/project/deactivate", id, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while deactivating project", exc);
      return undefined;
    });
};

/**
 * API method to load all project schedule entries that are on the backend
 * for the time frame from the given date + 4 days
 * @param startDate The user selected start date
 * @param axios The axios instance
 * @returns List of Project schedules for the time frame
 */
export const getAllProjectSchedulesForStartDate = async (
  startDate: dayjs.Dayjs,
  axios: AxiosInstance
): Promise<Schedule[]> => {
  return axios
    .get("/project/schedule/all", {
      params: { startDate: startDate.format("YYYY-MM-DD") },
    })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during project schedule fetch", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadProjectSchedules"),
      });
      return [];
    });
};

/**
 * API method to update all given project schedules on the backend
 *
 * @param schedules The updated schedules
 * @param axios The axios instance
 * @returns List of updated schedules
 */
export const updateMultipleProjectSchedules = async (
  schedules: Schedule[],
  axios: AxiosInstance
): Promise<Schedule[]> => {
  return axios
    .post(
      "/project/schedule/update/multiple",
      JSON.stringify(schedules, mapReplacer)
    )
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during schedules update!", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateProjectSchedules"),
      });
      return schedules;
    });
};

/**
 * API method to load all schedules for a project
 * @param axios instance of axios
 * @param projectId id of the project to load the schedulles for
 * @returns array of all found schedules
 */
export const getAllProjectSchedulesForProject = async (
  axios: AxiosInstance,
  projectId: string
): Promise<Schedule[]> => {
  return axios
    .get("/project/schedule/project", { params: { projectId } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllSchedulesProject"),
      });
      console.error("Error while loading schedules for project", exc);
      return [];
    });
};

/**
 * API method to update a projectSchedule
 * @param axios instance of axios
 * @param schedule updated schedule object
 * @returns updated projectSchedule or undefined on error
 */
export const updateProjectSchedule = async (
  axios: AxiosInstance,
  schedule: Schedule
): Promise<Schedule | undefined> => {
  return axios
    .post("/project/schedule/update", JSON.stringify(schedule, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateProjectSchedule"),
      });
      console.error("Error while updating projectSchedule", exc);
    });
};

/**
 * API Endpoint to create a new projectSchedule
 * @param axios instance of axios
 * @param schedule to create
 * @returns Promise of created Schedule or undefined on error
 */
export const createNewSchedule = async (
  axios: AxiosInstance,
  schedule: Schedule
): Promise<Schedule | undefined> => {
  return axios
    .post("/project/schedule", JSON.stringify(schedule, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating new Schedule", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createProjectSchedule"),
      });
    });
};

/**
 * API Method to load all projectProtocols
 * @param axios instance of axios
 * @returns Array with all found protocols
 */
export const getAllProjectProtocols = async (
  axios: AxiosInstance
): Promise<ProjectProtocol[]> => {
  return axios
    .get("project/protocol/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading protocols", exc);
      return [];
    });
};

/**
 * API Method to generate and get a projectDescription without persisting it
 * @param axios instance of Axios
 * @param projectId id of the project to get the description for
 * @returns Promise containing the file or undefined on error
 */
export const getProjectDescriptionPdf = async (
  axios: AxiosInstance,
  projectId: string
): Promise<Blob | undefined> => {
  return axios
    .get("/export/pdf/description", {
      params: { projectId },
      responseType: "blob",
    })
    .then((res) => new Blob([res.data], { type: "application/pdf" }))
    .catch((exc) => {
      console.error("Error while loading projectDescription", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createProjectDescriptionPdf"),
      });
      return undefined;
    });
};

/**
 * API MEthod to load all projects for a given customer
 * @param axios instance of axios
 * @param customerId id of the customer to search for
 * @returns Promise containing all found projects
 */
export const getAllProjectsForCustomer = async (
  axios: AxiosInstance,
  customerId: string
): Promise<Project[]> => {
  return axios
    .get("/project/all/customer", { params: { customerId } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getProjectsForCustomer"),
      });
      console.error("Error while loading projects for customer", exc);
      return [];
    });
};

/**
 * API Method to load all projects for with an specific invoice recipient
 * @param axios instance of axios
 * @param customerId id of the customer to search for
 * @returns Promise containing all found projects
 */
export const getAllProjectsByInvoiceRecipient = async (
  axios: AxiosInstance,
  customerId: string
): Promise<Project[]> => {
  return axios
    .get("project/all/invoice", { params: { customerId } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t(
          "general.notification.error.getProjectsForInvoiceRecipient"
        ),
      });
      console.error("Error while loading projects for invoiceRecipient", exc);
      return [];
    });
};

/**
 * API Method to get all projects with a specific workingLocation
 * @param axios instance of axios
 * @param customerId id of the customer to search
 * @returns  Promise containing all related projects
 */
export const getAllProjectsByWorkingLocationCustomer = async (
  axios: AxiosInstance,
  customerId: string
): Promise<Project[]> => {
  return axios
    .get("project/all/worklocation", { params: { customerId } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getProjectsForWorkLocation"),
      });
      console.error("Error while loading projects for workLocation", exc);
      return [];
    });
};

/**
 * API Method to get a preview of the orderConfirmation
 * @param axios instance of axios
 * @param projectId id of the project to create the document for
 * @param language to create the document in
 * @returns Promise containing the file or undefined on error
 */
export const getOrderConfirmationPdf = async (
  axios: AxiosInstance,
  projectId: string,
  language: Language
): Promise<Blob | undefined> => {
  return axios
    .get("/export/pdf/confirmation", {
      params: { projectId, language },
      responseType: "blob",
    })
    .then((res) => new Blob([res.data], { type: "application/pdf" }))
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getOrderConfirmationPdf"),
      });
      console.error("Error while generating projectDescr", exc);
      return undefined;
    });
};

/**
 * API Method to create a new project for a customerProject
 * @param axios instance of axios
 * @param offerId id of the customerProject to create from
 * @returns Promise containing the created Project or undefined on error
 */
export const createNewProjectFromCustomerProject = async (
  axios: AxiosInstance,
  offerId: string
): Promise<Project | undefined> => {
  return axios
    .post("/project/customer", offerId, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating project", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t(
          "general.notification.error.createProjectFromCustomerProject"
        ),
      });
    });
};
