import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Popup,
  Table,
  TableRow,
  TextArea,
  TopBar,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AllInventoryInterfaces,
  DocumentBucket,
  InventoryHandy,
  InventoryItem,
  InventoryLaptop,
  InventoryMeasure,
  InventoryMisc,
  InventoryMonitor,
  InventoryPc,
  InventoryPhoneContract,
  InventoryType,
  SimpleUser,
  createNewInventoryItem,
  deleteInventoryItem,
  generateDropdownOptionsForInventoryTypes,
  generateDropdownOptionsForToolingGroups,
  generateDropdownOptionsForToolingTypes,
  generateNewInventoryItem,
  generateNotification,
  updateInventoryItem,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import { generateDropdownOptionsForSimpleUser } from "../../utils/user/User.utils";
import { FileOverview } from "../../components/files/FileOverview";

const InventoryCreateOrEdit: React.FC = () => {
  const { t } = useTranslation();
  const { axios } = useUser();
  const [showDeletePopup, toggleDeletePopup] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation<{
    item?: InventoryItem;
    type?: InventoryType;
  }>();
  const { data: allOffices } = useData("OFFICES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allSimpleUser } = useData("SIMPLEUSERS_ALL", {
    config: { fallbackData: [] },
  });
  const [inventoryItem, setInventoryItem] = useState<AllInventoryInterfaces>(
    location.state?.item || generateNewInventoryItem(InventoryType.HANDY)
  );
  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  /**
   * Helper to update the inventory item on the given value and key
   *
   * @param newValue The user entered value
   * @param key The key to update
   */
  const updateInfo = <T extends InventoryItem>(
    newValue: string | number | boolean | Date | undefined,
    key: keyof T
  ): void => {
    setInventoryItem((old) => ({ ...old, [key]: newValue }));
  };

  useEffect(() => {
    if (location.state?.type)
      setInventoryItem((old) => ({ ...old, type: location.state!.type! }));
  }, [location]);

  /**
   * Holds the detail information inputs that are unique to
   * the specific inventory type
   */
  const detailInfo = useMemo(() => {
    switch (inventoryItem.type) {
      case InventoryType.HANDY:
        return (
          <div className="three-columns">
            <Input
              value={(inventoryItem as InventoryHandy).pin}
              onChangeNumber={(newValue) =>
                updateInfo<InventoryHandy>(newValue, "pin")
              }
              type="number"
              label={t("pages.inventory.createEdit.inputLabels.handy.pin")}
            />
            <Input
              value={(inventoryItem as InventoryHandy).supplier}
              onChange={(newValue) =>
                updateInfo<InventoryHandy>(newValue, "supplier")
              }
              type="text"
              label={t("pages.inventory.createEdit.inputLabels.handy.supplier")}
            />
            <Input
              value={(inventoryItem as InventoryHandy).priceBrutto}
              onChangeNumber={(newValue) =>
                updateInfo<InventoryHandy>(newValue, "priceBrutto")
              }
              type="number"
              label={t(
                "pages.inventory.createEdit.inputLabels.handy.priceBrutto"
              )}
            />
            <Checkbox
              isChecked={!!(inventoryItem as InventoryHandy).withCharge}
              onCheck={(checked) =>
                updateInfo<InventoryHandy>(checked, "withCharge")
              }
              label={t(
                "pages.inventory.createEdit.inputLabels.handy.withCharge"
              )}
            />
            <Checkbox
              isChecked={!!(inventoryItem as InventoryHandy).withCase}
              onCheck={(checked) =>
                updateInfo<InventoryHandy>(checked, "withCase")
              }
              label={t("pages.inventory.createEdit.inputLabels.handy.withCase")}
            />
            <Checkbox
              isChecked={!!(inventoryItem as InventoryHandy).withFoil}
              onCheck={(checked) =>
                updateInfo<InventoryHandy>(checked, "withFoil")
              }
              label={t("pages.inventory.createEdit.inputLabels.handy.withFoil")}
            />
          </div>
        );
      case InventoryType.LAPTOP:
        return (
          <div className="three-columns">
            <Input
              value={(inventoryItem as InventoryLaptop).cpu}
              onChange={(newValue) =>
                updateInfo<InventoryLaptop>(newValue, "cpu")
              }
              type="text"
              label={t("pages.inventory.createEdit.inputLabels.laptop.cpu")}
            />
            <Input
              value={(inventoryItem as InventoryLaptop).ram}
              onChange={(newValue) =>
                updateInfo<InventoryLaptop>(newValue, "ram")
              }
              type="text"
              label={t("pages.inventory.createEdit.inputLabels.laptop.ram")}
            />
            <Checkbox
              isChecked={!!(inventoryItem as InventoryLaptop).hasGPU}
              onCheck={(checked) =>
                updateInfo<InventoryLaptop>(checked, "hasGPU")
              }
              label={t("pages.inventory.createEdit.inputLabels.laptop.hasGPU")}
            />
            <Checkbox
              isChecked={!!(inventoryItem as InventoryLaptop).hasLTE}
              onCheck={(checked) =>
                updateInfo<InventoryLaptop>(checked, "hasLTE")
              }
              label={t("pages.inventory.createEdit.inputLabels.laptop.hasLTE")}
            />
          </div>
        );
      case InventoryType.MEASURE:
        return (
          <div className="three-columns">
            <Dropdown
              options={generateDropdownOptionsForToolingGroups()}
              selectedOption={(inventoryItem as InventoryMeasure).group}
              onChange={(selectedValue) =>
                updateInfo<InventoryMeasure>(selectedValue, "group")
              }
              label={t("pages.inventory.createEdit.inputLabels.measure.group")}
            />
            <Dropdown
              options={generateDropdownOptionsForToolingTypes()}
              selectedOption={(inventoryItem as InventoryMeasure).toolType}
              onChange={(selectedValue) =>
                updateInfo<InventoryMeasure>(selectedValue, "toolType")
              }
              label={t(
                "pages.inventory.createEdit.inputLabels.measure.toolType"
              )}
            />{" "}
            <Checkbox
              isChecked={!!(inventoryItem as InventoryMeasure).approved}
              onCheck={(checked) =>
                updateInfo<InventoryMeasure>(checked, "approved")
              }
              label={t(
                "pages.inventory.createEdit.inputLabels.measure.approved"
              )}
            />
          </div>
        );

      case InventoryType.MISC:
      case InventoryType.MISC_TRANSPORT:
        return (
          <div className="three-columns">
            <Input
              value={(inventoryItem as InventoryMisc).assetClass}
              onChange={(newValue) =>
                updateInfo<InventoryMisc>(newValue, "assetClass")
              }
              type="text"
              label={t(
                "pages.inventory.createEdit.inputLabels.misc.assetClass"
              )}
            />
            <Input
              value={(inventoryItem as InventoryMisc).assetNumber}
              onChange={(newValue) =>
                updateInfo<InventoryMisc>(newValue, "assetNumber")
              }
              type="text"
              label={t(
                "pages.inventory.createEdit.inputLabels.misc.assetNumber"
              )}
            />
            <Input
              value={(inventoryItem as InventoryMisc).price}
              onChangeNumber={(newValue) =>
                updateInfo<InventoryMisc>(newValue, "price")
              }
              type="number"
              label={t("pages.inventory.createEdit.inputLabels.misc.price")}
            />
          </div>
        );
      case InventoryType.MONITOR:
        return (
          <div>
            <Input
              value={(inventoryItem as InventoryMonitor).size}
              onChangeNumber={(newValue) =>
                updateInfo<InventoryMonitor>(newValue, "size")
              }
              type="number"
              label={t("pages.inventory.createEdit.inputLabels.monitor.size")}
            />
          </div>
        );
      case InventoryType.PC:
        return (
          <div className="three-columns">
            <Input
              value={(inventoryItem as InventoryPc).cpu}
              onChange={(newValue) => updateInfo<InventoryPc>(newValue, "cpu")}
              type="text"
              label={t("pages.inventory.createEdit.inputLabels.pc.cpu")}
            />
            <Input
              value={(inventoryItem as InventoryPc).supportMail}
              onChange={(newValue) =>
                updateInfo<InventoryPc>(newValue, "supportMail")
              }
              type="email"
              label={t("pages.inventory.createEdit.inputLabels.pc.supportMail")}
            />
            <Checkbox
              isChecked={!!(inventoryItem as InventoryPc).withKeyboard}
              onCheck={(checked) =>
                updateInfo<InventoryPc>(checked, "withKeyboard")
              }
              label={t(
                "pages.inventory.createEdit.inputLabels.pc.withKeyboard"
              )}
            />
          </div>
        );
      case InventoryType.PHONE:
        return (
          <div>
            <i>{t("pages.inventory.createEdit.inputLabels.noSpecials")}</i>
          </div>
        );
      case InventoryType.PHONE_CONTRACT:
        return (
          <div className="three-columns">
            <Input
              value={(inventoryItem as InventoryPhoneContract).phoneNumber}
              onChange={(newValue) =>
                updateInfo<InventoryPhoneContract>(newValue, "phoneNumber")
              }
              type="text"
              label={t(
                "pages.inventory.createEdit.inputLabels.phoneContract.phoneNumber"
              )}
            />
            <Input
              value={(inventoryItem as InventoryPhoneContract).contract}
              onChange={(newValue) =>
                updateInfo<InventoryPhoneContract>(newValue, "contract")
              }
              type="text"
              label={t(
                "pages.inventory.createEdit.inputLabels.phoneContract.contract"
              )}
            />
            <Input
              value={
                (inventoryItem as InventoryPhoneContract).frameworkContract
              }
              onChange={(newValue) =>
                updateInfo<InventoryPhoneContract>(
                  newValue,
                  "frameworkContract"
                )
              }
              type="text"
              label={t(
                "pages.inventory.createEdit.inputLabels.phoneContract.frameworkContract"
              )}
            />
            <Input
              value={(inventoryItem as InventoryPhoneContract).customerNumber}
              onChange={(newValue) =>
                updateInfo<InventoryPhoneContract>(newValue, "customerNumber")
              }
              type="text"
              label={t(
                "pages.inventory.createEdit.inputLabels.phoneContract.customerNumber"
              )}
            />
            <Input
              value={(inventoryItem as InventoryPhoneContract).customerAccount}
              onChange={(newValue) =>
                updateInfo<InventoryPhoneContract>(newValue, "customerAccount")
              }
              type="text"
              label={t(
                "pages.inventory.createEdit.inputLabels.phoneContract.customerAccount"
              )}
            />
            <Input
              value={(inventoryItem as InventoryPhoneContract).simNumber}
              onChange={(newValue) =>
                updateInfo<InventoryPhoneContract>(newValue, "simNumber")
              }
              type="text"
              label={t(
                "pages.inventory.createEdit.inputLabels.phoneContract.simNumber"
              )}
            />
            <Input
              value={(inventoryItem as InventoryPhoneContract).pin}
              onChange={(newValue) =>
                updateInfo<InventoryPhoneContract>(newValue, "pin")
              }
              type="text"
              label={t(
                "pages.inventory.createEdit.inputLabels.phoneContract.pin"
              )}
            />
            <Input
              value={(inventoryItem as InventoryPhoneContract).monthlyCosts}
              onChangeNumber={(newValue) =>
                updateInfo<InventoryPhoneContract>(newValue, "monthlyCosts")
              }
              type="number"
              label={t(
                "pages.inventory.createEdit.inputLabels.phoneContract.monthlyCosts"
              )}
            />
            <Input
              value={(inventoryItem as InventoryPhoneContract).begin}
              onChangeDate={(newValue) =>
                updateInfo<InventoryPhoneContract>(newValue, "begin")
              }
              type="date"
              label={t(
                "pages.inventory.createEdit.inputLabels.phoneContract.begin"
              )}
            />
          </div>
        );
    }
  }, [inventoryItem, t]);

  /**
   * Callback to handle the form submit
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    if (inventoryItem.id)
      updateInventoryItem(inventoryItem, axios).then((updatedItem) => {
        if (updatedItem && redirect)
          navigate("/inventory", { state: { type: location.state?.type } });
        else if (updatedItem) {
          setInventoryItem(updatedItem);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
        }
      });
    else
      createNewInventoryItem(inventoryItem, axios).then((createdItem) => {
        if (createdItem && redirect)
          navigate("/inventory", { state: { type: location.state?.type } });
        else if (createdItem) {
          setInventoryItem(createdItem);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
        }
      });
  };

  /**
   * Holds the table entries for the user history map. Sorts the
   * entries by date descending
   */
  const userHistoryTableEntries = useMemo((): TableRow[] => {
    if (!allSimpleUser) return [];
    return Array.from(inventoryItem.userHistory)
      .map(([key, value]) => {
        const user: SimpleUser | undefined = allSimpleUser.find(
          (user) => user.id === value
        );
        const nameToDisplay: string = user
          ? `${user.lastName}, ${user.firstName}`
          : t("pages.inventory.createEdit.userNotFound");
        return {
          id: key.toISOString(),
          content: [key.toLocaleDateString(), nameToDisplay],
        };
      })
      .sort((entry1, entry2) => entry2.id.localeCompare(entry1.id));
  }, [allSimpleUser, inventoryItem.userHistory, t]);

  /**
   * displayed item number filled with leading zeros
   */
  const displayedItemNumber: string = useMemo(
    (): string => inventoryItem.number.toString().padStart(5, "0"),
    [inventoryItem.number]
  );
  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        title={t("pages.inventory.title")}
        onBackClick={() =>
          navigate("/inventory", { state: { type: location.state?.type } })
        }
      >
        {inventoryItem.id && (
          <Button
            value={t("general.buttons.delete")}
            backgroundColor="#BC2E46"
            type="button"
            onClick={() => toggleDeletePopup(true)}
          />
        )}
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      <Popup
        isOpen={showDeletePopup}
        title={t("pages.inventory.createEdit.deletePopup.title")}
        buttons={[
          <Button
            value={t("general.buttons.cancel")}
            type="button"
            onClick={() => toggleDeletePopup(false)}
          />,
          <Button
            value={t("general.buttons.delete")}
            backgroundColor="#BC2E46"
            type="button"
            onClick={() =>
              deleteInventoryItem(inventoryItem, axios).then(() =>
                navigate("/inventory", {
                  state: { type: location.state?.type },
                })
              )
            }
          />,
        ]}
      >
        <p>
          {t("pages.inventory.createEdit.deletePopup.content", {
            replace: {
              NAME: inventoryItem.name,
              NUMBER: inventoryItem.number.toString().padStart(5, "0"),
            },
          })}
        </p>
      </Popup>
      <Box>
        <fieldset>
          <legend>{t("pages.inventory.createEdit.basicInfo")}</legend>{" "}
          <Checkbox
            isChecked={inventoryItem.active}
            onCheck={(checked) => updateInfo(checked, "active")}
            label={t("pages.inventory.createEdit.inputLabels.general.active")}
          />
          <div className="three-columns">
            <Dropdown
              options={generateDropdownOptionsForInventoryTypes()}
              selectedOption={inventoryItem.type}
              disabled={!!inventoryItem.id}
              label={t("pages.inventory.createEdit.inputLabels.general.type")}
              onChange={(selectedType) => updateInfo(selectedType, "type")}
            />{" "}
            <Dropdown
              label={t(
                "pages.inventory.createEdit.inputLabels.general.officeId"
              )}
              onChange={(officeId) => updateInfo(officeId, "officeId")}
              selectedOption={inventoryItem.officeId}
              options={allOffices.map((office) => ({
                label: office.name,
                value: office.id,
              }))}
            />
            <Dropdown
              label={t(
                "pages.inventory.createEdit.inputLabels.general.simpleUsers"
              )}
              onChange={(currentUser) =>
                updateInfo(currentUser, "currentUserId")
              }
              selectedOption={inventoryItem.currentUserId}
              options={generateDropdownOptionsForSimpleUser(allSimpleUser)}
            />
            <Input
              value={inventoryItem.name}
              onChange={(newValue) => updateInfo(newValue, "name")}
              type="text"
              label={t("pages.inventory.createEdit.inputLabels.general.name")}
            />
            <Input
              maxValue={99999}
              value={displayedItemNumber}
              onChangeNumber={(newValue) => updateInfo(newValue, "number")}
              type="number"
              label={t("pages.inventory.createEdit.inputLabels.general.number")}
            />
            <TextArea
              value={inventoryItem.note || ""}
              onChange={(newValue) => updateInfo(newValue, "note")}
              label={t("pages.inventory.createEdit.inputLabels.general.note")}
            />
            <Input
              value={inventoryItem.producent}
              onChange={(newValue) => updateInfo(newValue, "producent")}
              type="text"
              label={t(
                "pages.inventory.createEdit.inputLabels.general.producent"
              )}
            />
            <Input
              value={inventoryItem.serialNumber}
              onChange={(newValue) => updateInfo(newValue, "serialNumber")}
              type="text"
              label={t(
                "pages.inventory.createEdit.inputLabels.general.serialNumber"
              )}
            />
            <Input
              value={inventoryItem.customDate}
              onChangeDate={(newValue) => updateInfo(newValue, "customDate")}
              type="date"
              label={t(
                "pages.inventory.createEdit.inputLabels.general.customDate"
              )}
            />
          </div>
        </fieldset>
      </Box>
      <Box>
        <fieldset>
          <legend>{t("pages.inventory.createEdit.detailInfo")}</legend>
          {detailInfo}
        </fieldset>
      </Box>
      <Box>
        <fieldset>
          <legend>{t("pages.inventory.createEdit.userHistory")}</legend>
          <Table
            header={
              t("pages.inventory.createEdit.userHistoryTitles", {
                returnObjects: true,
              }) as TableHeader[]
            }
            rows={userHistoryTableEntries}
          />
        </fieldset>
      </Box>
      {inventoryItem.id && (
        <FileOverview
          path={`inventory/${inventoryItem.type}$/{inventoryItem.id}/`}
          bucket={DocumentBucket.INVENTORY}
        />
      )}
    </form>
  );
};

export default InventoryCreateOrEdit;
