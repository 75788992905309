import { Box, Table, TableRow, TopBar } from "@sam/components";
import { useTranslation } from "react-i18next";
import { useData } from "shared";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { useMemo } from "react";
import { convertQualificationsIntoTableRows } from "../../utils/workQualification/WorkQualification.utils";
import { useNavigate } from "react-router-dom";
import { TableHeader } from "@sam/components/src/Table/Table.types";
export const WorkQualificationOverview: React.FC = () => {
  const { data: loadedQualifications } = useData("QUALIFICATIONS_ALL", {
    config: { fallbackData: [] },
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const rows: TableRow[] = useMemo(
    () => convertQualificationsIntoTableRows(loadedQualifications, navigate),
    [loadedQualifications, navigate]
  );

  return (
    <>
      <TopBar title={t("pages.qualifications.overview.topBarHeadline")}>
        <AddIcon width={20} onClick={() => navigate("/qualifications/edit")} />
      </TopBar>
      <Box>
        <Table
          rows={rows}
          header={
            t("pages.qualifications.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
