import { Box, Checkbox, Input, TopBar } from "@sam/components";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AbsenceReason,
  Country,
  createNewAbsenceReason,
  generateEmptyAbsenceReason,
  generateNotification,
  updateAbsenceReason,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { Right } from "shared/src/userRole/UserRole.types";
import { NotAllowed } from "../../components/NotAllowed";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

const AbsenceReasonCreate: React.FC = () => {
  const { user, axios } = useUser();
  const form = useRef<HTMLFormElement>(null);
  const button = useRef<HTMLButtonElement>(null);
  const location = useLocation<{ reason?: AbsenceReason }>();
  const [absenceReason, setAbsenceReason] = useState<AbsenceReason>(
    location.state?.reason
      ? location.state.reason
      : generateEmptyAbsenceReason()
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**
   * Submit handler that creates or updates absenceReasons
   * Without a reason in the location state the user is creating
   * with a reason in the location state, the user is editing
   * @param evt HTML FormEvent
   */
  const handleSubmit = (redirect: boolean): void => {
    if (!form.current?.checkValidity()) return;
    if (!location.state?.reason)
      //no history, so we are in create-mode
      createNewAbsenceReason(axios, absenceReason).then((createdReason) => {
        if (createdReason && redirect) navigate("/absence/reason");
        else if (createdReason) {
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
          setAbsenceReason(createdReason);
        }
      });
    else {
      updateAbsenceReason(axios, {
        ...absenceReason,
        lastUpdate: new Date(),
        updatedBy: user.id,
      }).then((updatedReason) => {
        if (updatedReason && redirect) navigate("/absence/reason");
        else if (updatedReason) {
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
          setAbsenceReason(updatedReason);
        }
      });
    }
  };

  /**
   * Helper method to handle checks for different country Checkboxes
   * @param country to add or remoce from absenceReason
   * @returns void
   */
  const handleCheckboxForCountries = (country: Country): void =>
    absenceReason.validCountries.includes(country)
      ? setAbsenceReason({
          ...absenceReason,
          validCountries: absenceReason.validCountries.filter(
            (entry) => entry !== country
          ),
        })
      : setAbsenceReason({
          ...absenceReason,
          validCountries: [...absenceReason.validCountries, country],
        });

  return isUserAllowedToDo(user.right, Right.ABSENCE_REASON_CREATE) ? (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          location.state?.reason
            ? t("pages.absenceReasons.create.editHeadline")
            : t("pages.absenceReasons.create.createHeadline")
        }
      >
        <SaveButtons buttonRef={button} handleSubmit={handleSubmit} />
      </TopBar>
      <Box>
        <>
          <Input
            type="text"
            value={absenceReason.name}
            label={t("pages.absenceReasons.create.name")}
            onChange={(name) => setAbsenceReason({ ...absenceReason, name })}
          />
          <Input
            label={t("pages.absenceReasons.create.color")}
            maxWidth={300}
            type="color"
            onChange={(color) => setAbsenceReason({ ...absenceReason, color })}
            value={absenceReason.color}
          />
          <div>
            <p>{t("pages.absenceReasons.create.validIn")}</p>
            {Object.values(Country).map((country) => {
              return (
                <Checkbox
                  isChecked={absenceReason.validCountries.includes(country)}
                  label={t(`pages.absenceReasons.create.countries.${country}`)}
                  onCheck={() => handleCheckboxForCountries(country)}
                />
              );
            })}
          </div>
          <div>
            <p>{t("pages.absenceReasons.create.settings")}</p>
            <Checkbox
              onCheck={(creatingTimeTracking) =>
                setAbsenceReason({ ...absenceReason, creatingTimeTracking })
              }
              isChecked={absenceReason.creatingTimeTracking}
              label={t("pages.absenceReasons.create.createTracking")}
            />
            <Checkbox
              label={t("pages.absenceReasons.create.disabled")}
              onCheck={(disabled) => {
                setAbsenceReason({ ...absenceReason, disabled });
              }}
              isChecked={absenceReason.disabled}
            />
          </div>
        </>
      </Box>
    </form>
  ) : (
    <NotAllowed neccessaryRight={Right.ABSENCE_REASON_CREATE} />
  );
};

export default AbsenceReasonCreate;
