import { Box, Button, Input, Popup, TextArea, TopBar } from "@sam/components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Invoice,
  InvoicePayment,
  InvoiceState,
  Order,
  createNewInvoicePayment,
  generateNotification,
  updateInvoice,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { useUser } from "../../components/UserContext";
import {
  generateEmptyInvoicePayment,
  getTotalInvoiceSum,
} from "../../utils/invoice/Invoice.utils";

export const InvoicePaymentCreate: React.FC = () => {
  const navigate = useNavigate();
  const { axios } = useUser();
  const { t } = useTranslation();
  const location = useLocation<{ invoice?: Invoice; order?: Order }>();
  const [invoiceToEdit, setInvoiceToEdit] = useState<Invoice | undefined>(
    location.state?.invoice
  );
  const [paymentToEdit, setPaymentToEdit] = useState<InvoicePayment>(
    generateEmptyInvoicePayment({
      invoiceId: location.state?.invoice?.id,
      orderId: location.state?.order?.id,
      customerId: location.state?.order?.customerId,
    })
  );

  const [showPartialPaymentPopup, toggleShowPaymentPopup] =
    useState<boolean>(false);

  /**
   * Helper method to complete a payment with the full sum of an invoice
   */
  const handlePayment = (type: "partial" | "complete"): void => {
    if (!invoiceToEdit) return;
    const updatedInvoice: Invoice =
      type === "complete"
        ? {
            ...invoiceToEdit,
            invoiceState: InvoiceState.PAID,
          }
        : invoiceToEdit;

    const newPayment: InvoicePayment =
      type === "complete"
        ? {
            ...paymentToEdit,
            completePayment: true,
            amount: getTotalInvoiceSum(invoiceToEdit),
          }
        : paymentToEdit;

    Promise.all([
      updateInvoice(axios, updatedInvoice),
      createNewInvoicePayment(axios, newPayment),
    ]).then(([updatedInvoice, createdInvoicePayment]) => {
      if (updatedInvoice && createdInvoicePayment) {
        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.createPayment"),
        });
        navigate("/accounting");
      }
    });
  };

  return (
    <>
      <TopBar
        onBackClick={() => navigate(-1)}
        title={t("pages.accounting.create.topBarHeadline")}
      >
        <Button
          type="button"
          value={t("general.buttons.completePayment")}
          onClick={() => handlePayment("complete")}
        />
        <Button
          type="button"
          onClick={() => toggleShowPaymentPopup(true)}
          value={t("general.buttons.partialPayment")}
        />
      </TopBar>
      <Popup
        isOpen={showPartialPaymentPopup}
        onClose={() => toggleShowPaymentPopup(false)}
        title={t("pages.customerUser.createOrEdit.partialPaymentPopupTitle")}
        buttons={[
          <Button
            type="button"
            value={t("general.buttons.cancel")}
            onClick={() => toggleShowPaymentPopup(false)}
          />,
          <Button
            type="button"
            value={t("general.buttons.save")}
            onClick={() => handlePayment("partial")}
          />,
        ]}
      >
        <p>{t("general.popup.partialPayment")}</p>
        <Input
          type="number"
          onChangeNumber={(amount) =>
            setPaymentToEdit({ ...paymentToEdit, amount })
          }
          value={paymentToEdit.amount}
        />
      </Popup>
      <Box title={t("pages.accounting.create.boxTitle")}>
        <div className="three-columns">
          <Input
            label={t("pages.accounting.create.paymentDate")}
            value={paymentToEdit.paymentDate}
            type="date"
            onChangeDate={(paymentDate) => {
              if (paymentDate)
                setPaymentToEdit({ ...paymentToEdit, paymentDate });
            }}
          />
          <Input
            type="number"
            value={invoiceToEdit?.dunningLevel}
            disabled
            onChangeNumber={() => {}}
            label={t("pages.accounting.create.dunningLevel")}
          />
          <TextArea
            resizable
            value={invoiceToEdit?.paymentNote || ""}
            label={t("pages.accounting.create.paymentNote")}
            onChange={(paymentNote) => {
              if (invoiceToEdit)
                setInvoiceToEdit({ ...invoiceToEdit, paymentNote });
            }}
          />
        </div>
      </Box>
    </>
  );
};
