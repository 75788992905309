import { Box, Dropdown, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Invoice, InvoiceState, getAllInvoicesByStates, useData } from "shared";
import { useUser } from "../../components/UserContext";
import { convertInvoicesIntoTableRows } from "../../utils/invoice/Invoice.utils";

export const InvoiceOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios } = useUser();
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [selectedStates, setSelectedStates] = useState<InvoiceState[]>([
    InvoiceState.PENDING,
  ]);
  const [filterValue, setFilterValue] = useState<string>("");

  const { data: allOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allProjects } = useData("PROJECT_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCustomers } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCustomerLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });
  /**
   * TableRows out of the loaded invoices and filtered by the selected state
   */
  const rows: TableRow[] = useMemo(
    () =>
      convertInvoicesIntoTableRows(
        invoices,
        allOrders,
        allProjects,
        allCustomers,
        allCustomerLocations,
        navigate
      ),
    [
      allCustomerLocations,
      allCustomers,
      allOrders,
      allProjects,
      invoices,
      navigate,
    ]
  );

  // Hook to load the invoices for the selected state
  useEffect(() => {
    getAllInvoicesByStates(axios, selectedStates).then(setInvoices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStates]);

  return (
    <>
      <TopBar
        title={t("pages.invoice.overview.topBarHeadline")}
        onSearch={setFilterValue}
      />
      <Box>
        <Dropdown
          selectedOption={InvoiceState.PENDING}
          multi
          options={Object.values(InvoiceState).map((state) => ({
            label: t(`general.invoiceState.${state}`),
            value: state,
          }))}
          onChangeMultiple={(selected) => {
            setSelectedStates(
              Object.values(InvoiceState).filter((state) =>
                selected.includes(state)
              )
            );
          }}
        />
      </Box>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.invoice.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
