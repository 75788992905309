import { Box, Table, TableRow, TopBar } from "@sam/components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useData } from "shared";
import { useMemo } from "react";
import { convertBusinessAreasIntoTableEntries } from "../../utils/businessArea/BusinessArea.utils";
import { TableHeader } from "@sam/components/src/Table/Table.types";

export const BusinessAreaOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: loadedBusinessAreas } = useData("BUSINESSAREA_ALL", {
    config: { fallbackData: [] },
  });

  const businessAreaRows: TableRow[] = useMemo(
    () => convertBusinessAreasIntoTableEntries(loadedBusinessAreas, navigate),
    [loadedBusinessAreas, navigate]
  );
  return (
    <>
      <TopBar title={t("pages.businessArea.overview.topBarHeadline")}>
        <PlusIcon width={30} onClick={() => navigate("/businessarea/edit")} />
      </TopBar>
      <Box>
        <Table
          rows={businessAreaRows}
          header={
            t("pages.businessArea.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
