import { AxiosInstance } from "axios";
import { UserRole } from "./UserRole.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";

/**
 * API method to load all UserRoles
 * @param axios instance of axios
 * @returns  Array of all UserRoles
 */
export const getAllUserRoles = async (
  axios: AxiosInstance
): Promise<UserRole[]> => {
  return axios
    .get("/roles/")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllUserRoles"),
      });
      console.error("Error while loading roles", exc);
      return [];
    });
};

/**
 * API method to create a new UserRole
 * @param axios instance of axios
 * @param userRole to create
 * @returns created UserRole or undefined
 */
export const createNewUserRole = async (
  axios: AxiosInstance,
  userRole: UserRole
): Promise<UserRole | undefined> => {
  return axios
    .post("/roles/", userRole)
    .then((res) => {
      generateNotification({
        type: NotificationType.SUCCESS,
        value: i18n.t("general.notification.success.createUserRole"),
      });
      return res.data;
    })
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createUserRole"),
      });
      console.error("Error while creating userRole", exc);
      return undefined;
    });
};

/**
 * API method to update a userRole
 * @param axios instance of axios
 * @param userRole updated UserRole object
 * @returns UserRole or undefined
 */
export const updateUserRoles = async (
  axios: AxiosInstance,
  userRole: UserRole
): Promise<UserRole | undefined> => {
  return axios
    .post("/roles/update", userRole)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateUserRoles"),
      });
      console.error("Error while updating userRole", exc);
      return undefined;
    });
};

/**
 * API method to deactivate or reactivate a role
 * @param axios instance of axios
 * @param id of the role to load the users for
 * @returns updated userRole or undefined on error
 */
export const toggleDisableUserRole = async (
  axios: AxiosInstance,
  id: string
): Promise<UserRole | undefined> => {
  return axios
    .post("/roles/deactivate", id, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then((res) => {
      generateNotification({
        type: NotificationType.SUCCESS,
        value: i18n.t("general.notification.success.toggleDisabledUserRole"),
      });
      return res.data;
    })
    .catch((exc) => {
      generateNotification({
        type: NotificationType.SUCCESS,
        value: i18n.t("general.notification.error.toggleDisabledUserRole"),
      });
      console.error("Error while activating or deactivating userRole", exc);
      return undefined;
    });
};
