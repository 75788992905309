import { Option, TableRow } from "@sam/components";
import i18n from "../../../client/src/i18n/i18n";
import {
  InventoryItem,
  InventoryType,
  ToolingGroup,
  ToolingType,
} from "./Inventory.types";
import { NavigateFunction } from "react-router-dom";
import { Office } from "../office/Office.types";

/**
 * Util to generate the dropdown options for every inventory type
 *
 * @returns The dropdown options for inventory types
 */
export const generateDropdownOptionsForInventoryTypes = (): Option[] => {
  return Object.keys(InventoryType)
    .map((invType) => ({
      label: i18n.t(`pages.inventory.types.${invType}`),
      value: invType,
    }))
    .sort((entryA, entryB) => entryA.label.localeCompare(entryB.label));
};

/**
 * Helper to create the new inventory item
 *
 * @param type The type of the new inventory object
 * @param override Optional parameters to override
 * @returns The new instance
 */
export const generateNewInventoryItem = (
  type: InventoryType,
  override?: Partial<InventoryItem>
): InventoryItem => {
  return {
    createDate: new Date(),
    createdBy: undefined!,
    id: undefined!,
    name: "",
    number: 0,
    officeId: "",
    type,
    currentUserId: "",
    userHistory: new Map<Date, string>(),
    active: false,
    ...override,
  };
};

/**
 * Helper to create dropdown options for inventory tooling groups
 *
 * @returns The dropdown options
 */
export const generateDropdownOptionsForToolingGroups = (): Option[] => {
  return Object.keys(ToolingGroup).map((group) => ({
    label: i18n.t(`pages.inventory.toolingGroup.${group}`),
    value: group,
  }));
};

/**
 * Helper to create dropdown options for inventory tooling types
 *
 * @returns The dropdown options
 */
export const generateDropdownOptionsForToolingTypes = (): Option[] => {
  return Object.keys(ToolingType).map((toolType) => ({
    label: i18n.t(`pages.inventory.toolingType.${toolType}`),
    value: toolType,
  }));
};

/**
 * Util method toconvert inventoryItems into TableEntries
 * @param items to display
 * @param navigate navigateFunction to go to edit
 * @param selectedInventoryType to show
 * @param offices list of all Offices to display the office for the items
 * @returns Array of TableRows
 */
export const convertInventoryItemsIntoTableEntries = (
  items: InventoryItem[],
  navigate: NavigateFunction,
  selectedInventoryType: InventoryType,
  offices: Office[]
): TableRow[] => {
  return items.map((entry) => ({
    id: entry.id,
    onClick: () => navigate("edit", { state: { item: entry } }),
    content: [
      entry.name,
      i18n.t(`pages.inventory.types.${selectedInventoryType}`),
      entry.number.toString().padStart(5, "0"),
      offices.find((office) => office.id === entry.officeId)?.name || "-",
    ],
  }));
};
